import React, { useEffect, useState } from "react";

import { FaSearch, FaTrash, FaPen } from "react-icons/fa";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import { Button, Modal, ModalBody, ModalTitle } from "react-bootstrap";
import swal from "sweetalert";
import { useLocation } from "react-router-dom";


function Roleassign(routes) {
  const location = useLocation();

  let role_id = location.state?.role_id;
  const Navigate = useNavigate();
  const [permissions, setPermissions] = useState({
    accessAdmin: "",
    dashboardView: "",
    OrdersView: "",
    categoriesView: { view: "", add: false, edit: false, delete: false },
    headMasterView: { view: "", add: false, edit: false, delete: false },
    subCategoriesView: { view: "", add: false, edit: false, delete: false },
    productsView: "",
    // productsView: { view: "", add: false, edit: false, delete: false },
    addProductView: { view: "", add: false, edit: false, delete: false },
    manageProductsView: {
      view: "",
      add: false,
      edit: false,
      delete: false,
      viewProduct: false,
    },
    unitsView: { view: "", add: false, edit: false, delete: false },
    taxesView: { view: "", add: false, edit: false, delete: false },
    BrandsView: { view: "", add: false, edit: false, delete: false },
    cellularmemorystoragecapacityView: {
      view: "",
      add: false,
      edit: false,
      delete: false,
    },
    processorspeedView: { view: "", add: false, edit: false, delete: false },
    MasterView: "",
    VariantMasterView: { view: "", add: false, edit: false, delete: false },
    SizeMasterView: { view: "", add: false, edit: false, delete: false },
    ColourMasterView: { view: "", add: false, edit: false, delete: false },
    BatteryView: { view: "", add: false, edit: false, delete: false },
    PurchaseView: { view: "", add: false, edit: false, delete: false },
    OfferView: { view: "", add: false, edit: false, delete: false },
    HomeView: { view: "", add: false, edit: false, delete: false },
    OfferimgView: { view: "", add: false, edit: false, delete: false },
    PromoView: { view: "", add: false, edit: false, delete: false },
    DeliveryView: { view: "", add: false, edit: false, delete: false },
    FeaturedView: { view: "", add: false, edit: false, delete: false },
    ReportsView: { view: "", add: false, edit: false, delete: false },
    ProductSalesView: { view: "", add: false, edit: false, delete: false },
    SalesView: { view: "", add: false, edit: false, delete: false },
    OrderReportView: { view: "", add: false, edit: false, delete: false },
    StockView: { view: "", add: false, edit: false, delete: false },
    NotificationsView: { view: "", add: false, edit: false, delete: false },
    SystemView: { view: "", add: false, edit: false, delete: false },
    MobileView: { view: "", delete: false },
    ReturnRequestsView: { view: "", add: false, edit: false, delete: false },
    WithdrawalRequestsView: {
      view: "",
      add: false,
      edit: false,
      delete: false,
    },
    FAQView: { view: "", add: false, edit: false, delete: false },
    PolicyView: { view: "", add: false, edit: false, delete: false },
    GPSView: { view: "", add: false, edit: false, delete: false },
    CameraView: { view: "", add: false, edit: false, delete: false },
    PhoneView: { view: "", add: false, edit: false, delete: false },
    RefreshView: { view: "", add: false, edit: false, delete: false },
    SensorView: { view: "", add: false, edit: false, delete: false },
    ShootingView: { view: "", add: false, edit: false, delete: false },
    CellularView: { view: "", add: false, edit: false, delete: false },
    SimView: { view: "", add: false, edit: false, delete: false },
    OperatingView: { view: "", add: false, edit: false, delete: false },
    MobilephoneView: { view: "", add: false, edit: false, delete: false },
    CellularPhoneView: { view: "", add: false, edit: false, delete: false },
    VideoView: { view: "", add: false, edit: false, delete: false },
    ItemView: { view: "", add: false, edit: false, delete: false },
    IncludedView: { view: "", add: false, edit: false, delete: false },
    BiometricView: { view: "", add: false, edit: false, delete: false },
    ConnectorView: { view: "", add: false, edit: false, delete: false },
    ConnectivityView: { view: "", add: false, edit: false, delete: false },
    ScreenView: { view: "", add: false, edit: false, delete: false },
    DisplayView: { view: "", add: false, edit: false, delete: false },
    DeviceView: { view: "", add: false, edit: false, delete: false },
    SlotView: { view: "", add: false, edit: false, delete: false },
    ShortView: { view: "", add: false, edit: false, delete: false },
    ChargeView: { view: "", add: false, edit: false, delete: false },
    ShopView: { view: "", add: false, edit: false, delete: false },
    AddDeliveryView: { view: "", add: false, edit: false, delete: false },
    ManageDeliveryView: { view: "", add: false, edit: false, delete: false },
    ManageFeaturedView: { view: "", add: false, edit: false, delete: false },
    ManageNotificationsView: {
      view: "",
      add: false,
      edit: false,
      delete: false,
    },
    AddSystemView: { view: "", add: false, edit: false, delete: false },
    RoleView: { view: "", add: false, edit: false, delete: false },
    PrivacyPolicyView: { view: "", add: false, edit: false, delete: false },
    AboutPolicyView: { view: "", add: false, edit: false, delete: false },
    DeliveryPolicyView: { view: "", add: false, edit: false, delete: false },
    TermsPolicyView: { view: "", add: false, edit: false, delete: false },
    RefundPolicyView: { view: "", add: false, edit: false, delete: false },
    RamView: { view: "", add: false, edit: false, delete: false },
    FreeProductView: { view: "", add: false, edit: false, delete: false },
    DisplaytvView: { view: "", add: false, edit: false, delete: false },
    SmarTvView: { view: "", add: false, edit: false, delete: false },
    SoundView: { view: "", add: false, edit: false, delete: false },
    ProdctDimensionView: { view: "", add: false, edit: false, delete: false },
    supportedInternetView: { view: "", add: false, edit: false, delete: false },
  });


  const handleRadioChange = (event) => {
    const { name, value } = event.target;
    setPermissions({
      ...permissions,
      [name]: value === "yes" ? "1" : "0", // Convert 'yes' to '1' and 'no' to '0'
    });
  };

  const handleUpdate = async () => {
    try {
      const permissionsData = {
        permission: permissions, // Replace with your permission value
      };

      const response = await fetch(
        `https://mahalakshmimobiles.com/admin/roleassign.php?id=${role_id}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(permissionsData),
        }
      );

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      swal({
        icon: "success",
        title: "Update Successful",
        text: "Permissions have been updated successfully!",
      });

      const responseData = await response.json();

      // Optionally, update local state or provide feedback to the user
    } catch (error) {
      console.error("Error updating permissions:", error.message);
      // Handle error gracefully (e.g., show an error message to the user)
    }
  };


  const handleData = async () => {
    try {
      const response = await fetch(
        `https://mahalakshmimobiles.com/admin/roleassign.php?`,

      );
      const responseData = await response.json();

      // Optionally, update local state or provide feedback to the user
    } catch (error) {
      console.error("Error updating permissions:", error.message);
      // Handle error gracefully (e.g., show an error message to the user)
    }
  };

  useEffect(() => {
    handleData();
  }, [])


  const handleCheckboxChange = (event) => {
    const { name, checked } = event.target;
    const category = name.split("-")[0]; // Extract category from input name
    const field = name.split("-")[1]; // Extract field from input name

    setPermissions({
      ...permissions,
      [category]: {
        ...permissions[category],
        [field]: checked,
      },
    });
  };

  return (
    <div className="main-container mt-3">
      <div className="flex-container pt-5">
        <h3 className="head-fon-mv">Role Permission</h3>
        <div class="col-12 col-md-6 order-md-2 order-first">
          <nav
            aria-label="breadcrumb"
            class="breadcrumb-header float-start float-lg-end"
          >
            <ol class="breadcrumb">
              <li class="breadcrumb-item">
                <a onClick={() => Navigate("/dashboard")}>Dashboard</a>
              </li>{" "}
              <li aria-current="page" class="breadcrumb-item active">
                Role Permission
              </li>
            </ol>
          </nav>
        </div>
      </div>
      <div className="card-cat">
        <div>
          <label className="bold">Can access admin panel?</label>
          <br />
          <div className="">
            <input
              type="radio"
              id="adminyes"
              name="accessAdmin"
              value="yes"
              checked={permissions.accessAdmin === "1"}
              onChange={handleRadioChange}
            />
            <label htmlFor="adminyes" className="m-1">
              Yes
            </label>
            <input
              type="radio"
              id="adminno"
              name="accessAdmin"
              value="no"
              checked={permissions.accessAdmin === "0"}
              onChange={handleRadioChange}
            />
            <label htmlFor="adminno" className="m-1">
              No
            </label>
          </div>
        </div>

        <div>
          <label className="bold">Dashboard View?</label>
          <br />
          <div className="">
            <input
              type="radio"
              id="dashboardyes"
              name="dashboardView"
              value="yes"
              checked={permissions.dashboardView === "1"}
              onChange={handleRadioChange}
            />
            <label htmlFor="dashboardyes" className="m-1">
              Yes
            </label>
            <input
              type="radio"
              id="dashboardno"
              name="dashboardView"
              value="no"
              checked={permissions.dashboardView === "0"}
              onChange={handleRadioChange}
            />
            <label htmlFor="dashboardno" className="m-1">
              No
            </label>
          </div>
        </div>

        <div>
          <label className="bold">Orders View?</label>
          <br />
          <div className="">
            <input
              type="radio"
              id="Ordersyes"
              name="OrdersViewview"
              value="yes"
              onChange={handleRadioChange}
            />
            <label htmlFor="Ordersyes" className="m-1">
              Yes
            </label>
            <input
              type="radio"
              id="Ordersno"
              name="OrdersViewview"
              value="no"
              onChange={handleRadioChange}
            />
            <label htmlFor="Ordersno" className="m-1">
              No
            </label>
            <input
              type="checkbox"
              id="Ordersadd"
              name="OrdersView-add"
              checked={permissions.OrdersView.add}
              onChange={handleCheckboxChange}
            />
            <label htmlFor="Ordersadd" className="m-1">
              Add
            </label>
            <input
              type="checkbox"
              id="Ordersedit"
              name="OrdersView-edit"
              checked={permissions.OrdersView.edit}
              onChange={handleCheckboxChange}
            />
            <label htmlFor="Ordersedit" className="m-1">
              Edit
            </label>
            <input
              type="checkbox"
              id="Ordersdelete"
              name="OrdersView-delete"
              checked={permissions.OrdersView.delete}
              onChange={handleCheckboxChange}
            />
            <label htmlFor="Ordersdelete" className="m-1">
              Delete
            </label>
            <input
              type="checkbox"
              id="OrdersViewvieworders"
              name="OrdersView-vieworders"
              checked={permissions.OrdersView.vieworders}
              onChange={handleCheckboxChange}
            />
            <label htmlFor="manageproductsviewproduct" className="m-1">
              View
            </label>
          </div>
        </div>

        <div>
          <label className="bold">Categories View?</label>
          <br />
          <div className="">
            <input
              type="radio"
              id="categoriesyes"
              name="categoriesViewview"
              value="yes"
              onChange={handleRadioChange}
            />
            <label htmlFor="categoriesyes" className="m-1">
              Yes
            </label>
            <input
              type="radio"
              id="categoriesno"
              name="categoriesViewview"
              value="no"
              onChange={handleRadioChange}
            />
            <label htmlFor="categoriesno" className="m-1">
              No
            </label>
          </div>
        </div>

        <div>
          <label className="bold">Head Master View?</label>
          <br />
          <div className="">
            <input
              type="radio"
              id="headyes"
              name="headMasterViewview"
              value="yes"
              onChange={handleRadioChange}
            />
            <label htmlFor="headyes" className="m-1">
              Yes
            </label>
            <input
              type="radio"
              id="headno"
              name="headMasterViewview"
              value="no"
              onChange={handleRadioChange}
            />
            <label htmlFor="headno" className="m-1">
              No
            </label>
            <input
              type="checkbox"
              id="headadd"
              name="headMasterView-add"
              checked={permissions.headMasterView.add}
              onChange={handleCheckboxChange}
            />
            <label htmlFor="headadd" className="m-1">
              Add
            </label>
            <input
              type="checkbox"
              id="headedit"
              name="headMasterView-edit"
              checked={permissions.headMasterView.edit}
              onChange={handleCheckboxChange}
            />
            <label htmlFor="headedit" className="m-1">
              Edit
            </label>
            <input
              type="checkbox"
              id="headdelete"
              name="headMasterView-delete"
              checked={permissions.headMasterView.delete}
              onChange={handleCheckboxChange}
            />
            <label htmlFor="headdelete" className="m-1">
              Delete
            </label>
          </div>
        </div>

        <div>
          <label className="bold">Sub Categories View?</label>
          <br />
          <div className="">
            <input
              type="radio"
              id="subyes"
              name="subCategoriesViewview"
              value="yes"
              onChange={handleRadioChange}
            />
            <label htmlFor="subyes" className="m-1">
              Yes
            </label>
            <input
              type="radio"
              id="subno"
              name="subCategoriesViewview"
              value="no"
              onChange={handleRadioChange}
            />
            <label htmlFor="subno" className="m-1">
              No
            </label>
            <input
              type="checkbox"
              id="subadd"
              name="subCategoriesView-add"
              checked={permissions.subCategoriesView.add}
              onChange={handleCheckboxChange}
            />
            <label htmlFor="subadd" className="m-1">
              Add
            </label>
            <input
              type="checkbox"
              id="subedit"
              name="subCategoriesView-edit"
              checked={permissions.subCategoriesView.edit}
              onChange={handleCheckboxChange}
            />
            <label htmlFor="subedit" className="m-1">
              Edit
            </label>
            <input
              type="checkbox"
              id="subdelete"
              name="subCategoriesView-delete"
              checked={permissions.subCategoriesView.delete}
              onChange={handleCheckboxChange}
            />
            <label htmlFor="subdelete" className="m-1">
              Delete
            </label>
          </div>
        </div>

        <div>
          <label className="bold">Products View?</label>
          <br />
          <div className="">
            <input
              type="radio"
              id="productsyes"
              name="productsViewview"
              value="yes"
              onChange={handleRadioChange}
            />
            <label htmlFor="productsyes" className="m-1">
              Yes
            </label>
            <input
              type="radio"
              id="productsno"
              name="productsViewview"
              value="no"
              onChange={handleRadioChange}
            />
            <label htmlFor="productsno" className="m-1">
              No
            </label>
            <input
              type="checkbox"
              id="productsadd"
              name="productsView-add"
              checked={permissions.productsView.add}
              onChange={handleCheckboxChange}
            />
            <label htmlFor="productsadd" className="m-1">
              Add
            </label>
            <input
              type="checkbox"
              id="productsedit"
              name="productsView-edit"
              checked={permissions.productsView.edit}
              onChange={handleCheckboxChange}
            />
            <label htmlFor="productsedit" className="m-1">
              Edit
            </label>
            <input
              type="checkbox"
              id="productsdelete"
              name="productsView-delete"
              checked={permissions.productsView.delete}
              onChange={handleCheckboxChange}
            />
            <label htmlFor="productsdelete" className="m-1">
              Delete
            </label>
          </div>
        </div>

        <div>
          <label className="bold">Add Product View?</label>
          <br />
          <div className="">
            <input
              type="radio"
              id="addproductsyes"
              name="addProductViewview"
              value="yes"
              onChange={handleRadioChange}
            />
            <label htmlFor="addproductsyes" className="m-1">
              Yes
            </label>
            <input
              type="radio"
              id="addproductsno"
              name="addProductViewview"
              value="no"
              onChange={handleRadioChange}
            />
            <label htmlFor="addproductsno" className="m-1">
              No
            </label>
            <input
              type="checkbox"
              id="addproductsadd"
              name="addProductView-add"
              checked={permissions.addProductView.add}
              onChange={handleCheckboxChange}
            />
            <label htmlFor="addproductsadd" className="m-1">
              Add
            </label>
            <input
              type="checkbox"
              id="addproductsedit"
              name="addProductView-edit"
              checked={permissions.addProductView.edit}
              onChange={handleCheckboxChange}
            />
            <label htmlFor="addproductsedit" className="m-1">
              Edit
            </label>
            <input
              type="checkbox"
              id="addproductsdelete"
              name="addProductView-delete"
              checked={permissions.addProductView.delete}
              onChange={handleCheckboxChange}
            />
            <label htmlFor="addproductsdelete" className="m-1">
              Delete
            </label>
          </div>
        </div>

        <div>
          <label className="bold">Manage Products View?</label>
          <br />
          <div className="">
            <input
              type="radio"
              id="manageproductsyes"
              name="manageProductsViewview"
              value="yes"
              onChange={handleRadioChange}
            />
            <label htmlFor="manageproductsyes" className="m-1">
              Yes
            </label>
            <input
              type="radio"
              id="manageproductsno"
              name="manageProductsViewview"
              value="no"
              onChange={handleRadioChange}
            />
            <label htmlFor="manageproductsno" className="m-1">
              No
            </label>
            <input
              type="checkbox"
              id="manageproductsadd"
              name="manageProductsView-add"
              checked={permissions.manageProductsView.add}
              onChange={handleCheckboxChange}
            />
            <label htmlFor="manageproductsadd" className="m-1">
              Add
            </label>
            <input
              type="checkbox"
              id="manageproductsedit"
              name="manageProductsView-edit"
              checked={permissions.manageProductsView.edit}
              onChange={handleCheckboxChange}
            />
            <label htmlFor="manageproductsedit" className="m-1">
              Edit
            </label>
            <input
              type="checkbox"
              id="manageproductsdelete"
              name="manageProductsView-delete"
              checked={permissions.manageProductsView.delete}
              onChange={handleCheckboxChange}
            />
            <label htmlFor="manageproductsdelete" className="m-1">
              Delete
            </label>
            <input
              type="checkbox"
              id="manageproductsviewproduct"
              name="manageProductsView-viewProduct"
              checked={permissions.manageProductsView.viewProduct}
              onChange={handleCheckboxChange}
            />
            <label htmlFor="manageproductsviewproduct" className="m-1">
              View
            </label>
          </div>
        </div>

        <div>
          <label className="bold">Units View?</label>
          <br />
          <div className="">
            <input
              type="radio"
              id="unitsyes"
              name="unitsViewview"
              value="yes"
              onChange={handleRadioChange}
            />
            <label htmlFor="unitsyes" className="m-1">
              Yes
            </label>
            <input
              type="radio"
              id="unitsno"
              name="unitsViewview"
              value="no"
              onChange={handleRadioChange}
            />
            <label htmlFor="unitsno" className="m-1">
              No
            </label>
            <input
              type="checkbox"
              id="unitsadd"
              name="unitsView-add"
              checked={permissions.unitsView.add}
              onChange={handleCheckboxChange}
            />
            <label htmlFor="unitsadd" className="m-1">
              Add
            </label>
            <input
              type="checkbox"
              id="unitsedit"
              name="unitsView-edit"
              checked={permissions.unitsView.edit}
              onChange={handleCheckboxChange}
            />
            <label htmlFor="unitsedit" className="m-1">
              Edit
            </label>
            <input
              type="checkbox"
              id="unitsdelete"
              name="unitsView-delete"
              checked={permissions.unitsView.delete}
              onChange={handleCheckboxChange}
            />
            <label htmlFor="unitsdelete" className="m-1">
              Delete
            </label>
          </div>
        </div>

        <div>
          <label className="bold">Taxes View?</label>
          <br />
          <div className="">
            <input
              type="radio"
              id="taxesyes"
              name="taxesViewview"
              value="yes"
              onChange={handleRadioChange}
            />
            <label htmlFor="taxesyes" className="m-1">
              Yes
            </label>
            <input
              type="radio"
              id="taxesno"
              name="taxesViewview"
              value="no"
              onChange={handleRadioChange}
            />
            <label htmlFor="taxesno" className="m-1">
              No
            </label>
            <input
              type="checkbox"
              id="taxesadd"
              name="taxesView-add"
              checked={permissions.taxesView.add}
              onChange={handleCheckboxChange}
            />
            <label htmlFor="taxesadd" className="m-1">
              Add
            </label>
            <input
              type="checkbox"
              id="taxesedit"
              name="taxesView-edit"
              checked={permissions.taxesView.edit}
              onChange={handleCheckboxChange}
            />
            <label htmlFor="taxesedit" className="m-1">
              Edit
            </label>
            <input
              type="checkbox"
              id="taxesdelete"
              name="taxesView-delete"
              checked={permissions.taxesView.delete}
              onChange={handleCheckboxChange}
            />
            <label htmlFor="taxesdelete" className="m-1">
              Delete
            </label>
          </div>
        </div>

        <div>
          <label className="bold">Brands View?</label>
          <br />
          <div className="">
            <input
              type="radio"
              id="Brandsyes"
              name="BrandsViewview"
              value="yes"
              onChange={handleRadioChange}
            />
            <label htmlFor="Brandsyes" className="m-1">
              Yes
            </label>
            <input
              type="radio"
              id="Brandsno"
              name="BrandsViewview"
              value="no"
              onChange={handleRadioChange}
            />
            <label htmlFor="Brandsno" className="m-1">
              No
            </label>
            <input
              type="checkbox"
              id="Brandsadd"
              name="BrandsView-add"
              checked={permissions.BrandsView.add}
              onChange={handleCheckboxChange}
            />
            <label htmlFor="Brandsadd" className="m-1">
              Add
            </label>
            <input
              type="checkbox"
              id="Brandsedit"
              name="BrandsView-edit"
              checked={permissions.BrandsView.edit}
              onChange={handleCheckboxChange}
            />
            <label htmlFor="Brandsedit" className="m-1">
              Edit
            </label>
            <input
              type="checkbox"
              id="Brandsdelete"
              name="BrandsView-delete"
              checked={permissions.BrandsView.delete}
              onChange={handleCheckboxChange}
            />
            <label htmlFor="Brandsdelete" className="m-1">
              Delete
            </label>
          </div>
        </div>

        <div>
          <label className="bold">Master View?</label>
          <br />
          <div className="">
            <input
              type="radio"
              id="Masteryes"
              name="MasterViewview"
              value="yes"
              onChange={handleRadioChange}
            />
            <label htmlFor="Masteryes" className="m-1">
              Yes
            </label>
            <input
              type="radio"
              id="Masterno"
              name="MasterViewview"
              value="no"
              onChange={handleRadioChange}
            />
            <label htmlFor="Masterno" className="m-1">
              No
            </label>
            <input
              type="checkbox"
              id="Masteradd"
              name="MasterView-add"
              checked={permissions.MasterView.add}
              onChange={handleCheckboxChange}
            />
            <label htmlFor="Masteradd" className="m-1">
              Add
            </label>
            <input
              type="checkbox"
              id="Masteredit"
              name="MasterView-edit"
              checked={permissions.MasterView.edit}
              onChange={handleCheckboxChange}
            />
            <label htmlFor="Masteredit" className="m-1">
              Edit
            </label>
            <input
              type="checkbox"
              id="Masterdelete"
              name="MasterView-delete"
              checked={permissions.MasterView.delete}
              onChange={handleCheckboxChange}
            />
            <label htmlFor="Masterdelete" className="m-1">
              Delete
            </label>
          </div>
        </div>

        <div>
          <label className="bold">Variant Master View?</label>
          <br />
          <div className="">
            <input
              type="radio"
              id="VariantMasteryes"
              name="VariantMasterViewview"
              value="yes"
              onChange={handleRadioChange}
            />
            <label htmlFor="VariantMasteryes" className="m-1">
              Yes
            </label>
            <input
              type="radio"
              id="VariantMasterno"
              name="VariantMasterViewview"
              value="no"
              onChange={handleRadioChange}
            />
            <label htmlFor="VariantMasterno" className="m-1">
              No
            </label>
            <input
              type="checkbox"
              id="VariantMasteradd"
              name="VariantMasterView-add"
              checked={permissions.VariantMasterView.add}
              onChange={handleCheckboxChange}
            />
            <label htmlFor="VariantMasteradd" className="m-1">
              Add
            </label>
            <input
              type="checkbox"
              id="VariantMasteredit"
              name="VariantMasterView-edit"
              checked={permissions.VariantMasterView.edit}
              onChange={handleCheckboxChange}
            />
            <label htmlFor="VariantMasteredit" className="m-1">
              Edit
            </label>
            <input
              type="checkbox"
              id="VariantMasterdelete"
              name="VariantMasterView-delete"
              checked={permissions.VariantMasterView.delete}
              onChange={handleCheckboxChange}
            />
            <label htmlFor="VariantMasterdelete" className="m-1">
              Delete
            </label>
          </div>
        </div>

        <div>
          <label className="bold">Size Master View?</label>
          <br />
          <div className="">
            <input
              type="radio"
              id="SizeMasteryes"
              name="SizeMasterViewview"
              value="yes"
              onChange={handleRadioChange}
            />
            <label htmlFor="SizeMasteryes" className="m-1">
              Yes
            </label>
            <input
              type="radio"
              id="SizeMasterno"
              name="SizeMasterViewview"
              value="no"
              onChange={handleRadioChange}
            />
            <label htmlFor="SizeMasterno" className="m-1">
              No
            </label>
            <input
              type="checkbox"
              id="SizeMasteradd"
              name="SizeMasterView-add"
              checked={permissions.SizeMasterView.add}
              onChange={handleCheckboxChange}
            />
            <label htmlFor="SizeMasteradd" className="m-1">
              Add
            </label>
            <input
              type="checkbox"
              id="SizeMasteredit"
              name="SizeMasterView-edit"
              checked={permissions.SizeMasterView.edit}
              onChange={handleCheckboxChange}
            />
            <label htmlFor="SizeMasteredit" className="m-1">
              Edit
            </label>
            <input
              type="checkbox"
              id="SizeMasterdelete"
              name="SizeMasterView-delete"
              checked={permissions.SizeMasterView.delete}
              onChange={handleCheckboxChange}
            />
            <label htmlFor="SizeMasterdelete" className="m-1">
              Delete
            </label>
          </div>
        </div>

        <div>
          <label className="bold">Colour Master View?</label>
          <br />
          <div className="">
            <input
              type="radio"
              id="ColourMasteryes"
              name="ColourMasterViewview"
              value="yes"
              onChange={handleRadioChange}
            />
            <label htmlFor="ColourMasteryes" className="m-1">
              Yes
            </label>
            <input
              type="radio"
              id="ColourMasterno"
              name="ColourMasterViewview"
              value="no"
              onChange={handleRadioChange}
            />
            <label htmlFor="ColourMasterno" className="m-1">
              No
            </label>
            <input
              type="checkbox"
              id="ColourMasteradd"
              name="ColourMasterView-add"
              checked={permissions.ColourMasterView.add}
              onChange={handleCheckboxChange}
            />
            <label htmlFor="ColourMasteradd" className="m-1">
              Add
            </label>
            <input
              type="checkbox"
              id="ColourMasteredit"
              name="ColourMasterView-edit"
              checked={permissions.ColourMasterView.edit}
              onChange={handleCheckboxChange}
            />
            <label htmlFor="ColourMasteredit" className="m-1">
              Edit
            </label>
            <input
              type="checkbox"
              id="ColourMasterdelete"
              name="ColourMasterView-delete"
              checked={permissions.ColourMasterView.delete}
              onChange={handleCheckboxChange}
            />
            <label htmlFor="ColourMasterdelete" className="m-1">
              Delete
            </label>
          </div>
        </div>

        <div>
          <label className="bold">
            Cellular Phone Memory Storage Capacity View?
          </label>
          <br />
          <div className="">
            <input
              type="radio"
              id="cellularmemorystoragecapacityyes"
              name="cellularmemorystoragecapacityViewview"
              value="yes"
              onChange={handleRadioChange}
            />
            <label htmlFor="cellularmemorystoragecapacityyes" className="m-1">
              Yes
            </label>
            <input
              type="radio"
              id="cellularmemorystoragecapacityno"
              name="cellularmemorystoragecapacityViewview"
              value="no"
              onChange={handleRadioChange}
            />
            <label htmlFor="cellularmemorystoragecapacityno" className="m-1">
              No
            </label>
            <input
              type="checkbox"
              id="cellularmemorystoragecapacityadd"
              name="cellularmemorystoragecapacityView-add"
              checked={permissions.cellularmemorystoragecapacityView.add}
              onChange={handleCheckboxChange}
            />
            <label htmlFor="cellularmemorystoragecapacityadd" className="m-1">
              Add
            </label>
            <input
              type="checkbox"
              id="cellularmemorystoragecapacityedit"
              name="cellularmemorystoragecapacityView-edit"
              checked={permissions.cellularmemorystoragecapacityView.edit}
              onChange={handleCheckboxChange}
            />
            <label htmlFor="cellularmemorystoragecapacityedit" className="m-1">
              Edit
            </label>
            <input
              type="checkbox"
              id="cellularmemorystoragecapacitydelete"
              name="cellularmemorystoragecapacityView-delete"
              checked={permissions.cellularmemorystoragecapacityView.delete}
              onChange={handleCheckboxChange}
            />
            <label
              htmlFor="cellularmemorystoragecapacitydelete"
              className="m-1"
            >
              Delete
            </label>
          </div>
        </div>

        <div>
          <label className="bold">Processor Speed View?</label>
          <br />
          <div className="">
            <input
              type="radio"
              id="processorspeedyes"
              name="processorspeedViewview"
              value="yes"
              onChange={handleRadioChange}
            />
            <label htmlFor="processorspeedyes" className="m-1">
              Yes
            </label>
            <input
              type="radio"
              id="processorspeedno"
              name="processorspeedViewview"
              value="no"
              onChange={handleRadioChange}
            />
            <label htmlFor="processorspeedno" className="m-1">
              No
            </label>
            <input
              type="checkbox"
              id="processorspeedadd"
              name="processorspeedView-add"
              checked={permissions.processorspeedView.add}
              onChange={handleCheckboxChange}
            />
            <label htmlFor="processorspeedadd" className="m-1">
              Add
            </label>
            <input
              type="checkbox"
              id="processorspeededit"
              name="processorspeedView-edit"
              checked={permissions.processorspeedView.edit}
              onChange={handleCheckboxChange}
            />
            <label htmlFor="processorspeededit" className="m-1">
              Edit
            </label>
            <input
              type="checkbox"
              id="processorspeeddelete"
              name="processorspeedView-delete"
              checked={permissions.processorspeedView.delete}
              onChange={handleCheckboxChange}
            />
            <label htmlFor="processorspeeddelete" className="m-1">
              Delete
            </label>
          </div>
        </div>

        <div>
          <label className="bold">Battery Capacity View?</label>
          <br />
          <div className="">
            <input
              type="radio"
              id="Batteryyes"
              name="BatteryViewview"
              value="yes"
              onChange={handleRadioChange}
            />
            <label htmlFor="Batteryyes" className="m-1">
              Yes
            </label>
            <input
              type="radio"
              id="Batteryno"
              name="BatteryViewview"
              value="no"
              onChange={handleRadioChange}
            />
            <label htmlFor="Batteryno" className="m-1">
              No
            </label>
            <input
              type="checkbox"
              id="Batteryadd"
              name="BatteryView-add"
              checked={permissions.BatteryView.add}
              onChange={handleCheckboxChange}
            />
            <label htmlFor="Batteryadd" className="m-1">
              Add
            </label>
            <input
              type="checkbox"
              id="Batteryedit"
              name="BatteryView-edit"
              checked={permissions.BatteryView.edit}
              onChange={handleCheckboxChange}
            />
            <label htmlFor="Batteryedit" className="m-1">
              Edit
            </label>
            <input
              type="checkbox"
              id="Batterydelete"
              name="BatteryView-delete"
              checked={permissions.BatteryView.delete}
              onChange={handleCheckboxChange}
            />
            <label htmlFor="Batterydelete" className="m-1">
              Delete
            </label>
          </div>
        </div>

        <div>
          <label className="bold">GPS View?</label>
          <br />
          <div className="">
            <input
              type="radio"
              id="GPSyes"
              name="GPSViewview"
              value="yes"
              onChange={handleRadioChange}
            />
            <label htmlFor="GPSyes" className="m-1">
              Yes
            </label>
            <input
              type="radio"
              id="GPSno"
              name="GPSViewview"
              value="no"
              onChange={handleRadioChange}
            />
            <label htmlFor="GPSno" className="m-1">
              No
            </label>
            <input
              type="checkbox"
              id="GPSadd"
              name="GPSView-add"
              checked={permissions.GPSView.add}
              onChange={handleCheckboxChange}
            />
            <label htmlFor="GPSadd" className="m-1">
              Add
            </label>
            <input
              type="checkbox"
              id="GPSedit"
              name="GPSView-edit"
              checked={permissions.GPSView.edit}
              onChange={handleCheckboxChange}
            />
            <label htmlFor="GPSedit" className="m-1">
              Edit
            </label>
            <input
              type="checkbox"
              id="GPSdelete"
              name="GPSView-delete"
              checked={permissions.GPSView.delete}
              onChange={handleCheckboxChange}
            />
            <label htmlFor="GPSdelete" className="m-1">
              Delete
            </label>
          </div>
        </div>

        <div>
          <label className="bold">Camera Resolution View?</label>
          <br />
          <div className="">
            <input
              type="radio"
              id="Camerayes"
              name="CameraViewview"
              value="yes"
              onChange={handleRadioChange}
            />
            <label htmlFor="Camerayes" className="m-1">
              Yes
            </label>
            <input
              type="radio"
              id="Camerano"
              name="CameraViewview"
              value="no"
              onChange={handleRadioChange}
            />
            <label htmlFor="Camerano" className="m-1">
              No
            </label>
            <input
              type="checkbox"
              id="Cameraadd"
              name="CameraView-add"
              checked={permissions.CameraView.add}
              onChange={handleCheckboxChange}
            />
            <label htmlFor="Cameraadd" className="m-1">
              Add
            </label>
            <input
              type="checkbox"
              id="Cameraedit"
              name="CameraView-edit"
              checked={permissions.CameraView.edit}
              onChange={handleCheckboxChange}
            />
            <label htmlFor="Cameraedit" className="m-1">
              Edit
            </label>
            <input
              type="checkbox"
              id="Cameradelete"
              name="CameraView-delete"
              checked={permissions.CameraView.delete}
              onChange={handleCheckboxChange}
            />
            <label htmlFor="Cameradelete" className="m-1">
              Delete
            </label>
          </div>
        </div>

        <div>
          <label className="bold">Phone Resolution View?</label>
          <br />
          <div className="">
            <input
              type="radio"
              id="Phoneyes"
              name="PhoneViewview"
              value="yes"
              onChange={handleRadioChange}
            />
            <label htmlFor="Phoneyes" className="m-1">
              Yes
            </label>
            <input
              type="radio"
              id="Phoneno"
              name="PhoneViewview"
              value="no"
              onChange={handleRadioChange}
            />
            <label htmlFor="Phoneno" className="m-1">
              No
            </label>
            <input
              type="checkbox"
              id="Phoneadd"
              name="PhoneView-add"
              checked={permissions.PhoneView.add}
              onChange={handleCheckboxChange}
            />
            <label htmlFor="Phoneadd" className="m-1">
              Add
            </label>
            <input
              type="checkbox"
              id="Phoneedit"
              name="PhoneView-edit"
              checked={permissions.PhoneView.edit}
              onChange={handleCheckboxChange}
            />
            <label htmlFor="Phoneedit" className="m-1">
              Edit
            </label>
            <input
              type="checkbox"
              id="Phonedelete"
              name="PhoneView-delete"
              checked={permissions.PhoneView.delete}
              onChange={handleCheckboxChange}
            />
            <label htmlFor="Phonedelete" className="m-1">
              Delete
            </label>
          </div>
        </div>

        <div>
          <label className="bold">Refresh Rate View?</label>
          <br />
          <div className="">
            <input
              type="radio"
              id="Refreshyes"
              name="RefreshViewview"
              value="yes"
              onChange={handleRadioChange}
            />
            <label htmlFor="Refreshyes" className="m-1">
              Yes
            </label>
            <input
              type="radio"
              id="Refresheno"
              name="RefreshViewview"
              value="no"
              onChange={handleRadioChange}
            />
            <label htmlFor="Refreshno" className="m-1">
              No
            </label>
            <input
              type="checkbox"
              id="Refreshadd"
              name="RefreshView-add"
              checked={permissions.RefreshView.add}
              onChange={handleCheckboxChange}
            />
            <label htmlFor="Refreshadd" className="m-1">
              Add
            </label>
            <input
              type="checkbox"
              id="Refreshedit"
              name="RefreshView-edit"
              checked={permissions.RefreshView.edit}
              onChange={handleCheckboxChange}
            />
            <label htmlFor="Refreshedit" className="m-1">
              Edit
            </label>
            <input
              type="checkbox"
              id="Refreshdelete"
              name="RefreshView-delete"
              checked={permissions.RefreshView.delete}
              onChange={handleCheckboxChange}
            />
            <label htmlFor="Refreshdelete" className="m-1">
              Delete
            </label>
          </div>
        </div>

        <div>
          <label className="bold">Ram Size View?</label>
          <br />
          <div className="">
            <input
              type="radio"
              id="Ramyes"
              name="RamViewview"
              value="yes"
              onChange={handleRadioChange}
            />
            <label htmlFor="Ramyes" className="m-1">
              Yes
            </label>
            <input
              type="radio"
              id="Ramno"
              name="RamViewview"
              value="no"
              onChange={handleRadioChange}
            />
            <label htmlFor="Ramno" className="m-1">
              No
            </label>
            <input
              type="checkbox"
              id="Ramadd"
              name="RamView-add"
              checked={permissions.RamView.add}
              onChange={handleCheckboxChange}
            />
            <label htmlFor="Ramadd" className="m-1">
              Add
            </label>
            <input
              type="checkbox"
              id="Ramedit"
              name="RamView-edit"
              checked={permissions.RamView.edit}
              onChange={handleCheckboxChange}
            />
            <label htmlFor="Ramedit" className="m-1">
              Edit
            </label>
            <input
              type="checkbox"
              id="Ramdelete"
              name="RamView-delete"
              checked={permissions.RamView.delete}
              onChange={handleCheckboxChange}
            />
            <label htmlFor="Ramdelete" className="m-1">
              Delete
            </label>
          </div>
        </div>

        <div>
          <label className="bold">Sensor Resolution View?</label>
          <br />
          <div className="">
            <input
              type="radio"
              id="Sensoryes"
              name="SensorViewview"
              value="yes"
              onChange={handleRadioChange}
            />
            <label htmlFor="Sensoryes" className="m-1">
              Yes
            </label>
            <input
              type="radio"
              id="Sensorno"
              name="SensorViewview"
              value="no"
              onChange={handleRadioChange}
            />
            <label htmlFor="Sensorno" className="m-1">
              No
            </label>
            <input
              type="checkbox"
              id="Sensoradd"
              name="SensorView-add"
              checked={permissions.SensorView.add}
              onChange={handleCheckboxChange}
            />
            <label htmlFor="Sensoradd" className="m-1">
              Add
            </label>
            <input
              type="checkbox"
              id="Sensoredit"
              name="SensorView-edit"
              checked={permissions.SensorView.edit}
              onChange={handleCheckboxChange}
            />
            <label htmlFor="Sensoredit" className="m-1">
              Edit
            </label>
            <input
              type="checkbox"
              id="Sensordelete"
              name="SensorView-delete"
              checked={permissions.SensorView.delete}
              onChange={handleCheckboxChange}
            />
            <label htmlFor="Sensordelete" className="m-1">
              Delete
            </label>
          </div>
        </div>

        <div>
          <label className="bold">Shooting Modes View?</label>
          <br />
          <div className="">
            <input
              type="radio"
              id="Shootingyes"
              name="ShootingViewview"
              value="yes"
              onChange={handleRadioChange}
            />
            <label htmlFor="Shootingyes" className="m-1">
              Yes
            </label>
            <input
              type="radio"
              id="Shootingno"
              name="ShootingViewview"
              value="no"
              onChange={handleRadioChange}
            />
            <label htmlFor="Shootingno" className="m-1">
              No
            </label>
            <input
              type="checkbox"
              id="Shootingadd"
              name="ShootingView-add"
              checked={permissions.ShootingView.add}
              onChange={handleCheckboxChange}
            />
            <label htmlFor="Shootingadd" className="m-1">
              Add
            </label>
            <input
              type="checkbox"
              id="Shootingedit"
              name="ShootingView-edit"
              checked={permissions.ShootingView.edit}
              onChange={handleCheckboxChange}
            />
            <label htmlFor="Shootingedit" className="m-1">
              Edit
            </label>
            <input
              type="checkbox"
              id="Shootingdelete"
              name="ShootingView-delete"
              checked={permissions.ShootingView.delete}
              onChange={handleCheckboxChange}
            />
            <label htmlFor="Shootingdelete" className="m-1">
              Delete
            </label>
          </div>
        </div>

        <div>
          <label className="bold">Cellular Technology View?</label>
          <br />
          <div className="">
            <input
              type="radio"
              id="Cellularyes"
              name="CellularViewview"
              value="yes"
              onChange={handleRadioChange}
            />
            <label htmlFor="Cellularyes" className="m-1">
              Yes
            </label>
            <input
              type="radio"
              id="Cellularno"
              name="CellularViewview"
              value="no"
              onChange={handleRadioChange}
            />
            <label htmlFor="Cellularno" className="m-1">
              No
            </label>
            <input
              type="checkbox"
              id="Cellularadd"
              name="CellularView-add"
              checked={permissions.CellularView.add}
              onChange={handleCheckboxChange}
            />
            <label htmlFor="Cellularadd" className="m-1">
              Add
            </label>
            <input
              type="checkbox"
              id="Cellularedit"
              name="CellularView-edit"
              checked={permissions.CellularView.edit}
              onChange={handleCheckboxChange}
            />
            <label htmlFor="Cellularedit" className="m-1">
              Edit
            </label>
            <input
              type="checkbox"
              id="Cellulardelete"
              name="CellularView-delete"
              checked={permissions.CellularView.delete}
              onChange={handleCheckboxChange}
            />
            <label htmlFor="Cellulardelete" className="m-1">
              Delete
            </label>
          </div>
        </div>

        <div>
          <label className="bold">Sim Card Size?</label>
          <br />
          <div className="">
            <input
              type="radio"
              id="Simyes"
              name="SimViewview"
              value="yes"
              onChange={handleRadioChange}
            />
            <label htmlFor="Simyes" className="m-1">
              Yes
            </label>
            <input
              type="radio"
              id="Simno"
              name="SimViewview"
              value="no"
              onChange={handleRadioChange}
            />
            <label htmlFor="Simno" className="m-1">
              No
            </label>
            <input
              type="checkbox"
              id="Simadd"
              name="SimView-add"
              checked={permissions.SimView.add}
              onChange={handleCheckboxChange}
            />
            <label htmlFor="Simadd" className="m-1">
              Add
            </label>
            <input
              type="checkbox"
              id="Simedit"
              name="SimView-edit"
              checked={permissions.SimView.edit}
              onChange={handleCheckboxChange}
            />
            <label htmlFor="Simedit" className="m-1">
              Edit
            </label>
            <input
              type="checkbox"
              id="Simdelete"
              name="SimView-delete"
              checked={permissions.SimView.delete}
              onChange={handleCheckboxChange}
            />
            <label htmlFor="Simdelete" className="m-1">
              Delete
            </label>
          </div>
        </div>

        <div>
          <label className="bold">Operating System?</label>
          <br />
          <div className="">
            <input
              type="radio"
              id="Operatingyes"
              name="OperatingViewview"
              value="yes"
              onChange={handleRadioChange}
            />
            <label htmlFor="Operatingyes" className="m-1">
              Yes
            </label>
            <input
              type="radio"
              id="Operatingno"
              name="OperatingViewview"
              value="no"
              onChange={handleRadioChange}
            />
            <label htmlFor="Operatingno" className="m-1">
              No
            </label>
            <input
              type="checkbox"
              id="Operatingadd"
              name="OperatingView-add"
              checked={permissions.OperatingView.add}
              onChange={handleCheckboxChange}
            />
            <label htmlFor="Operatingadd" className="m-1">
              Add
            </label>
            <input
              type="checkbox"
              id="Operatingedit"
              name="OperatingView-edit"
              checked={permissions.OperatingView.edit}
              onChange={handleCheckboxChange}
            />
            <label htmlFor="Operatingedit" className="m-1">
              Edit
            </label>
            <input
              type="checkbox"
              id="Operatingdelete"
              name="OperatingView-delete"
              checked={permissions.OperatingView.delete}
              onChange={handleCheckboxChange}
            />
            <label htmlFor="Operatingdelete" className="m-1">
              Delete
            </label>
          </div>
        </div>

        <div>
          <label className="bold">Mobile Phone Human Interface Input?</label>
          <br />
          <div className="">
            <input
              type="radio"
              id="MobilephoneViewyes"
              name="MobilephoneViewViewview"
              value="yes"
              onChange={handleRadioChange}
            />
            <label htmlFor="MobilephoneViewyes" className="m-1">
              Yes
            </label>
            <input
              type="radio"
              id="MobilephoneViewno"
              name="MobilephoneViewViewview"
              value="no"
              onChange={handleRadioChange}
            />
            <label htmlFor="MobilephoneViewno" className="m-1">
              No
            </label>
            <input
              type="checkbox"
              id="MobilephoneViewadd"
              name="MobilephoneView-add"
              checked={permissions.MobilephoneView.add}
              onChange={handleCheckboxChange}
            />
            <label htmlFor="MobilephoneViewadd" className="m-1">
              Add
            </label>
            <input
              type="checkbox"
              id="MobilephoneViewedit"
              name="MobilephoneView-edit"
              checked={permissions.MobilephoneView.edit}
              onChange={handleCheckboxChange}
            />
            <label htmlFor="MobilephoneViewedit" className="m-1">
              Edit
            </label>
            <input
              type="checkbox"
              id="MobilephoneViewdelete"
              name="MobilephoneView-delete"
              checked={permissions.MobilephoneView.delete}
              onChange={handleCheckboxChange}
            />
            <label htmlFor="MobilephoneViewdelete" className="m-1">
              Delete
            </label>
          </div>
        </div>

        <div>
          <label className="bold">
            Cellular Phone Photo Sensor Resolution?
          </label>
          <br />
          <div className="">
            <input
              type="radio"
              id="CellularPhoneyes"
              name="CellularPhoneview"
              value="yes"
              onChange={handleRadioChange}
            />
            <label htmlFor="CellularPhoneyes" className="m-1">
              Yes
            </label>
            <input
              type="radio"
              id="CellularPhoneno"
              name="CellularPhoneview"
              value="no"
              onChange={handleRadioChange}
            />
            <label htmlFor="CellularPhoneno" className="m-1">
              No
            </label>
            <input
              type="checkbox"
              id="CellularPhoneadd"
              name="CellularPhoneView-add"
              checked={permissions.CellularPhoneView.add}
              onChange={handleCheckboxChange}
            />
            <label htmlFor="CellularPhoneadd" className="m-1">
              Add
            </label>
            <input
              type="checkbox"
              id="CellularPhoneedit"
              name="CellularPhoneView-edit"
              checked={permissions.CellularPhoneView.edit}
              onChange={handleCheckboxChange}
            />
            <label htmlFor="CellularPhoneViewedit" className="m-1">
              Edit
            </label>
            <input
              type="checkbox"
              id="CellularPhonedelete"
              name="CellularPhoneView-delete"
              checked={permissions.CellularPhoneView.delete}
              onChange={handleCheckboxChange}
            />
            <label htmlFor="CellularPhoneViewdelete" className="m-1">
              Delete
            </label>
          </div>
        </div>

        <div>
          <label className="bold">Video Capture Resolution?</label>
          <br />
          <div className="">
            <input
              type="radio"
              id="Videoyes"
              name="Videoview"
              value="yes"
              onChange={handleRadioChange}
            />
            <label htmlFor="Videoyes" className="m-1">
              Yes
            </label>
            <input
              type="radio"
              id="Videono"
              name="Videoview"
              value="no"
              onChange={handleRadioChange}
            />
            <label htmlFor="Videono" className="m-1">
              No
            </label>
            <input
              type="checkbox"
              id="Videoadd"
              name="VideoView-add"
              checked={permissions.VideoView.add}
              onChange={handleCheckboxChange}
            />
            <label htmlFor="Videoadd" className="m-1">
              Add
            </label>
            <input
              type="checkbox"
              id="Videoedit"
              name="VideoView-edit"
              checked={permissions.VideoView.edit}
              onChange={handleCheckboxChange}
            />
            <label htmlFor="Videoedit" className="m-1">
              Edit
            </label>
            <input
              type="checkbox"
              id="Videodelete"
              name="VideoView-delete"
              checked={permissions.VideoView.delete}
              onChange={handleCheckboxChange}
            />
            <label htmlFor="Videodelete" className="m-1">
              Delete
            </label>
          </div>
        </div>

        <div>
          <label className="bold">Item Weight?</label>
          <br />
          <div className="">
            <input
              type="radio"
              id="Itemyes"
              name="Itemview"
              value="yes"
              onChange={handleRadioChange}
            />
            <label htmlFor="Itemyes" className="m-1">
              Yes
            </label>
            <input
              type="radio"
              id="Itemno"
              name="Itemview"
              value="no"
              onChange={handleRadioChange}
            />
            <label htmlFor="Itemno" className="m-1">
              No
            </label>
            <input
              type="checkbox"
              id="Itemadd"
              name="ItemView-add"
              checked={permissions.ItemView.add}
              onChange={handleCheckboxChange}
            />
            <label htmlFor="Itemadd" className="m-1">
              Add
            </label>
            <input
              type="checkbox"
              id="Itemedit"
              name="ItemView-edit"
              checked={permissions.ItemView.edit}
              onChange={handleCheckboxChange}
            />
            <label htmlFor="Itemedit" className="m-1">
              Edit
            </label>
            <input
              type="checkbox"
              id="Itemdelete"
              name="ItemView-delete"
              checked={permissions.ItemView.delete}
              onChange={handleCheckboxChange}
            />
            <label htmlFor="Itemdelete" className="m-1">
              Delete
            </label>
          </div>
        </div>

        <div>
          <label className="bold">Included Components?</label>
          <br />
          <div className="">
            <input
              type="radio"
              id="Includedyes"
              name="Includedview"
              value="yes"
              onChange={handleRadioChange}
            />
            <label htmlFor="Includedyes" className="m-1">
              Yes
            </label>
            <input
              type="radio"
              id="Includedno"
              name="Includedview"
              value="no"
              onChange={handleRadioChange}
            />
            <label htmlFor="Includedno" className="m-1">
              No
            </label>
            <input
              type="checkbox"
              id="Includedadd"
              name="IncludedView-add"
              checked={permissions.IncludedView.add}
              onChange={handleCheckboxChange}
            />
            <label htmlFor="Includedadd" className="m-1">
              Add
            </label>
            <input
              type="checkbox"
              id="Includededit"
              name="IncludedView-edit"
              checked={permissions.IncludedView.edit}
              onChange={handleCheckboxChange}
            />
            <label htmlFor="Includededit" className="m-1">
              Edit
            </label>
            <input
              type="checkbox"
              id="Includeddelete"
              name="IncludedView-delete"
              checked={permissions.IncludedView.delete}
              onChange={handleCheckboxChange}
            />
            <label htmlFor="Includedelete" className="m-1">
              Delete
            </label>
          </div>
        </div>

        <div>
          <label className="bold">
            Mobile Phone Biometric Security Feature?
          </label>
          <br />
          <div className="">
            <input
              type="radio"
              id="Biometricyyes"
              name="Biometricview"
              value="yes"
              onChange={handleRadioChange}
            />
            <label htmlFor="Biometricyes" className="m-1">
              Yes
            </label>
            <input
              type="radio"
              id="Biometricno"
              name="Biometricview"
              value="no"
              onChange={handleRadioChange}
            />
            <label htmlFor="Biometricno" className="m-1">
              No
            </label>
            <input
              type="checkbox"
              id="Biometricadd"
              name="BiometricView-add"
              checked={permissions.BiometricView.add}
              onChange={handleCheckboxChange}
            />
            <label htmlFor="Biometricadd" className="m-1">
              Add
            </label>
            <input
              type="checkbox"
              id="Biometricedit"
              name="BiometricView-edit"
              checked={permissions.BiometricView.edit}
              onChange={handleCheckboxChange}
            />
            <label htmlFor="Biometricedit" className="m-1">
              Edit
            </label>
            <input
              type="checkbox"
              id="Biometricdelete"
              name="BiometricView-delete"
              checked={permissions.BiometricView.delete}
              onChange={handleCheckboxChange}
            />
            <label htmlFor="Biometricdelete" className="m-1">
              Delete
            </label>
          </div>
        </div>


        <div>
          <label className="bold">
            Cell Phone Connector Type?
          </label>
          <br />
          <div className="">
            <input
              type="radio"
              id="Connectoryes"
              name="Connectorview"
              value="yes"
              onChange={handleRadioChange}
            />
            <label htmlFor="Connectoryes" className="m-1">
              Yes
            </label>
            <input
              type="radio"
              id="Connectorno"
              name="Connectorview"
              value="no"
              onChange={handleRadioChange}
            />
            <label htmlFor="Connectorno" className="m-1">
              No
            </label>
            <input
              type="checkbox"
              id="Connectoradd"
              name="ConnectorView-add"
              checked={permissions.ConnectorView.add}
              onChange={handleCheckboxChange}
            />
            <label htmlFor="Connectoradd" className="m-1">
              Add
            </label>
            <input
              type="checkbox"
              id="Connectoredit"
              name="ConnectorView-edit"
              checked={permissions.ConnectorView.edit}
              onChange={handleCheckboxChange}
            />
            <label htmlFor="Connectoredit" className="m-1">
              Edit
            </label>
            <input
              type="checkbox"
              id="Connectordelete"
              name="ConnectorView-delete"
              checked={permissions.ConnectorView.delete}
              onChange={handleCheckboxChange}
            />
            <label htmlFor="BConnectordelete" className="m-1">
              Delete
            </label>
          </div>
        </div>
        <div>
          <label className="bold">Mobile Phone Connectivity Technology?</label>
          <br />
          <div className="">
            <input
              type="radio"
              id="Connectivityyes"
              name="Connectivityview"
              value="yes"
              onChange={handleRadioChange}
            />
            <label htmlFor="Connectivityyes" className="m-1">
              Yes
            </label>
            <input
              type="radio"
              id="Connectivityno"
              name="Connectivityview"
              value="no"
              onChange={handleRadioChange}
            />
            <label htmlFor="Connectivityno" className="m-1">
              No
            </label>
            <input
              type="checkbox"
              id="Connectivityadd"
              name="ConnectivityView-add"
              checked={permissions.ConnectivityView.add}
              onChange={handleCheckboxChange}
            />
            <label htmlFor="Connectivityadd" className="m-1">
              Add
            </label>
            <input
              type="checkbox"
              id="Connectivityedit"
              name="ConnectivityView-edit"
              checked={permissions.ConnectivityView.edit}
              onChange={handleCheckboxChange}
            />
            <label htmlFor="Connectivityedit" className="m-1">
              Edit
            </label>
            <input
              type="checkbox"
              id="Connectivitydelete"
              name="ConnectivityView-delete"
              checked={permissions.ConnectivityView.delete}
              onChange={handleCheckboxChange}
            />
            <label htmlFor="Connectivitydelete" className="m-1">
              Delete
            </label>
          </div>
        </div>

        <div>
          <label className="bold">Screen Size?</label>
          <br />
          <div className="">
            <input
              type="radio"
              id="Screenyes"
              name="Screenview"
              value="yes"
              onChange={handleRadioChange}
            />
            <label htmlFor="Screenyes" className="m-1">
              Yes
            </label>
            <input
              type="radio"
              id="Screenno"
              name="Screenview"
              value="no"
              onChange={handleRadioChange}
            />
            <label htmlFor="Screenno" className="m-1">
              No
            </label>
            <input
              type="checkbox"
              id="Screenadd"
              name="ScreenView-add"
              checked={permissions.ScreenView.add}
              onChange={handleCheckboxChange}
            />
            <label htmlFor="Screenadd" className="m-1">
              Add
            </label>
            <input
              type="checkbox"
              id="Screenedit"
              name="ScreenView-edit"
              checked={permissions.ScreenView.edit}
              onChange={handleCheckboxChange}
            />
            <label htmlFor="Screenedit" className="m-1">
              Edit
            </label>
            <input
              type="checkbox"
              id="Screendelete"
              name="ScreenView-delete"
              checked={permissions.ScreenView.delete}
              onChange={handleCheckboxChange}
            />
            <label htmlFor="Screendelete" className="m-1">
              Delete
            </label>
          </div>
        </div>

        <div>
          <label className="bold">Display Type?</label>
          <br />
          <div className="">
            <input
              type="radio"
              id="Displayyes"
              name="Displayview"
              value="yes"
              onChange={handleRadioChange}
            />
            <label htmlFor="Displayyes" className="m-1">
              Yes
            </label>
            <input
              type="radio"
              id="Displayno"
              name="Displayview"
              value="no"
              onChange={handleRadioChange}
            />
            <label htmlFor="Displayno" className="m-1">
              No
            </label>
            <input
              type="checkbox"
              id="Displayadd"
              name="DisplayView-add"
              checked={permissions.DisplayView.add}
              onChange={handleCheckboxChange}
            />
            <label htmlFor="Displayadd" className="m-1">
              Add
            </label>
            <input
              type="checkbox"
              id="Displayedit"
              name="DisplayView-edit"
              checked={permissions.DisplayView.edit}
              onChange={handleCheckboxChange}
            />
            <label htmlFor="Displayedit" className="m-1">
              Edit
            </label>
            <input
              type="checkbox"
              id="Displaydelete"
              name="DisplayView-delete"
              checked={permissions.DisplayView.delete}
              onChange={handleCheckboxChange}
            />
            <label htmlFor="Displaydelete" className="m-1">
              Delete
            </label>
          </div>
        </div>

        <div>
          <label className="bold">Device Features?</label>
          <br />
          <div className="">
            <input
              type="radio"
              id="Deviceyes"
              name="Deviceview"
              value="yes"
              onChange={handleRadioChange}
            />
            <label htmlFor="Deviceyes" className="m-1">
              Yes
            </label>
            <input
              type="radio"
              id="Deviceno"
              name="Deviceview"
              value="no"
              onChange={handleRadioChange}
            />
            <label htmlFor="Deviceno" className="m-1">
              No
            </label>
            <input
              type="checkbox"
              id="Deviceadd"
              name="DeviceView-add"
              checked={permissions.DeviceView.add}
              onChange={handleCheckboxChange}
            />
            <label htmlFor="Deviceadd" className="m-1">
              Add
            </label>
            <input
              type="checkbox"
              id="Deviceedit"
              name="DeviceView-edit"
              checked={permissions.DeviceView.edit}
              onChange={handleCheckboxChange}
            />
            <label htmlFor="Deviceedit" className="m-1">
              Edit
            </label>
            <input
              type="checkbox"
              id="Devicedelete"
              name="DeviceView-delete"
              checked={permissions.DeviceView.delete}
              onChange={handleCheckboxChange}
            />
            <label htmlFor="Devicedelete" className="m-1">
              Delete
            </label>
          </div>
        </div>

        <div>
          <label className="bold">Sim Card Slot Count?</label>
          <br />
          <div className="">
            <input
              type="radio"
              id="Slotyes"
              name="Slotview"
              value="yes"
              onChange={handleRadioChange}
            />
            <label htmlFor="Slotyes" className="m-1">
              Yes
            </label>
            <input
              type="radio"
              id="Slotno"
              name="Slotview"
              value="no"
              onChange={handleRadioChange}
            />
            <label htmlFor="Slotno" className="m-1">
              No
            </label>
            <input
              type="checkbox"
              id="Slotadd"
              name="SlotView-add"
              checked={permissions.SlotView.add}
              onChange={handleCheckboxChange}
            />
            <label htmlFor="Slotadd" className="m-1">
              Add
            </label>
            <input
              type="checkbox"
              id="Slotedit"
              name="SlotView-edit"
              checked={permissions.SlotView.edit}
              onChange={handleCheckboxChange}
            />
            <label htmlFor="Slotedit" className="m-1">
              Edit
            </label>
            <input
              type="checkbox"
              id="Slotdelete"
              name="SlotView-delete"
              checked={permissions.SlotView.delete}
              onChange={handleCheckboxChange}
            />
            <label htmlFor="Slotdelete" className="m-1">
              Delete
            </label>
          </div>
        </div>

        <div>
          <label className="bold">Short By?</label>
          <br />
          <div className="">
            <input
              type="radio"
              id="Shortyes"
              name="Shortview"
              value="yes"
              onChange={handleRadioChange}
            />
            <label htmlFor="Shortyes" className="m-1">
              Yes
            </label>
            <input
              type="radio"
              id="Shortno"
              name="Shortview"
              value="no"
              onChange={handleRadioChange}
            />
            <label htmlFor="Shortno" className="m-1">
              No
            </label>
            <input
              type="checkbox"
              id="Shortadd"
              name="ShortView-add"
              checked={permissions.ShortView.add}
              onChange={handleCheckboxChange}
            />
            <label htmlFor="Shortadd" className="m-1">
              Add
            </label>
            <input
              type="checkbox"
              id="Shortedit"
              name="ShortView-edit"
              checked={permissions.ShortView.edit}
              onChange={handleCheckboxChange}
            />
            <label htmlFor="Shortedit" className="m-1">
              Edit
            </label>
            <input
              type="checkbox"
              id="Shortdelete"
              name="ShortView-delete"
              checked={permissions.ShortView.delete}
              onChange={handleCheckboxChange}
            />
            <label htmlFor="Shortdelete" className="m-1">
              Delete
            </label>
          </div>
        </div>

        <div>
          <label className="bold">Delivery Charge?</label>
          <br />
          <div className="">
            <input
              type="radio"
              id="Chargeyes"
              name="Chargeview"
              value="yes"
              onChange={handleRadioChange}
            />
            <label htmlFor="Chargeyes" className="m-1">
              Yes
            </label>
            <input
              type="radio"
              id="Chargeno"
              name="Chargeview"
              value="no"
              onChange={handleRadioChange}
            />
            <label htmlFor="Chargeno" className="m-1">
              No
            </label>
            <input
              type="checkbox"
              id="Chargeadd"
              name="ChargeView-add"
              checked={permissions.ChargeView.add}
              onChange={handleCheckboxChange}
            />
            <label htmlFor="Chargeadd" className="m-1">
              Add
            </label>
            <input
              type="checkbox"
              id="Chargeedit"
              name="ChargeView-edit"
              checked={permissions.ChargeView.edit}
              onChange={handleCheckboxChange}
            />
            <label htmlFor="Chargeedit" className="m-1">
              Edit
            </label>
            <input
              type="checkbox"
              id="Chargedelete"
              name="ChargeView-delete"
              checked={permissions.ChargeView.delete}
              onChange={handleCheckboxChange}
            />
            <label htmlFor="Chargedelete" className="m-1">
              Delete
            </label>
          </div>
        </div>

        <div>
          <label className="bold">Shop Master?</label>
          <br />
          <div className="">
            <input
              type="radio"
              id="Shopyes"
              name="Shopview"
              value="yes"
              onChange={handleRadioChange}
            />
            <label htmlFor="Shopyes" className="m-1">
              Yes
            </label>
            <input
              type="radio"
              id="Shopno"
              name="Shopview"
              value="no"
              onChange={handleRadioChange}
            />
            <label htmlFor="Shopno" className="m-1">
              No
            </label>
            <input
              type="checkbox"
              id="Shopadd"
              name="ShopView-add"
              checked={permissions.ShopView.add}
              onChange={handleCheckboxChange}
            />
            <label htmlFor="Shopadd" className="m-1">
              Add
            </label>
            <input
              type="checkbox"
              id="Shopedit"
              name="ShopView-edit"
              checked={permissions.ShopView.edit}
              onChange={handleCheckboxChange}
            />
            <label htmlFor="Shopedit" className="m-1">
              Edit
            </label>
            <input
              type="checkbox"
              id="Shopdelete"
              name="ShopView-delete"
              checked={permissions.ShopView.delete}
              onChange={handleCheckboxChange}
            />
            <label htmlFor="Shopdelete" className="m-1">
              Delete
            </label>
          </div>
        </div>

        <div>
          <label className="bold">Purchase View?</label>
          <br />
          <div className="">
            <input
              type="radio"
              id="Purchaseyes"
              name="PurchaseViewview"
              value="yes"
              onChange={handleRadioChange}
            />
            <label htmlFor="Purchaseyes" className="m-1">
              Yes
            </label>
            <input
              type="radio"
              id="Purchaseno"
              name="PurchaseViewview"
              value="no"
              onChange={handleRadioChange}
            />
            <label htmlFor="Purchaseno" className="m-1">
              No
            </label>
            <input
              type="checkbox"
              id="Purchaseadd"
              name="PurchaseView-add"
              checked={permissions.PurchaseView.add}
              onChange={handleCheckboxChange}
            />
            <label htmlFor="Purchaseadd" className="m-1">
              Add
            </label>
            <input
              type="checkbox"
              id="Purchaseedit"
              name="PurchaseView-edit"
              checked={permissions.PurchaseView.edit}
              onChange={handleCheckboxChange}
            />
            <label htmlFor="Purchaseedit" className="m-1">
              Edit
            </label>
            <input
              type="checkbox"
              id="Purchasedelete"
              name="PurchaseView-delete"
              checked={permissions.PurchaseView.delete}
              onChange={handleCheckboxChange}
            />
            <label htmlFor="Purchasedelete" className="m-1">
              Delete
            </label>
          </div>
        </div>

        <div>
          <label className="bold">Offer Page View?</label>
          <br />
          <div className="">
            <input
              type="radio"
              id="Offeryes"
              name="OfferViewview"
              value="yes"
              onChange={handleRadioChange}
            />
            <label htmlFor="Offeryes" className="m-1">
              Yes
            </label>
            <input
              type="radio"
              id="Offerno"
              name="OfferViewview"
              value="no"
              onChange={handleRadioChange}
            />
            <label htmlFor="Offerno" className="m-1">
              No
            </label>
            <input
              type="checkbox"
              id="Offeradd"
              name="OfferView-add"
              checked={permissions.OfferView.add}
              onChange={handleCheckboxChange}
            />
            <label htmlFor="Offeradd" className="m-1">
              Add
            </label>
            <input
              type="checkbox"
              id="Offeredit"
              name="OfferView-edit"
              checked={permissions.OfferView.edit}
              onChange={handleCheckboxChange}
            />
            <label htmlFor="Offeredit" className="m-1">
              Edit
            </label>
            <input
              type="checkbox"
              id="Offerdelete"
              name="OfferView-delete"
              checked={permissions.OfferView.delete}
              onChange={handleCheckboxChange}
            />
            <label htmlFor="Offerdelete" className="m-1">
              Delete
            </label>
          </div>
        </div>

        <div>
          <label className="bold">Home Sliders View?</label>
          <br />
          <div className="">
            <input
              type="radio"
              id="Homeyes"
              name="HomeViewview"
              value="yes"
              onChange={handleRadioChange}
            />
            <label htmlFor="Homeyes" className="m-1">
              Yes
            </label>
            <input
              type="radio"
              id="Homeno"
              name="HomeViewview"
              value="no"
              onChange={handleRadioChange}
            />
            <label htmlFor="Homeno" className="m-1">
              No
            </label>
            <input
              type="checkbox"
              id="Homeadd"
              name="HomeView-add"
              checked={permissions.HomeView.add}
              onChange={handleCheckboxChange}
            />
            <label htmlFor="Homeadd" className="m-1">
              Add
            </label>
            <input
              type="checkbox"
              id="Homeedit"
              name="HomeView-edit"
              checked={permissions.HomeView.edit}
              onChange={handleCheckboxChange}
            />
            <label htmlFor="Homeedit" className="m-1">
              Edit
            </label>
            <input
              type="checkbox"
              id="Homedelete"
              name="HomeView-delete"
              checked={permissions.HomeView.delete}
              onChange={handleCheckboxChange}
            />
            <label htmlFor="Homedelete" className="m-1">
              Delete
            </label>
          </div>
        </div>

        <div>
          <label className="bold">Offer Images View?</label>
          <br />
          <div className="">
            <input
              type="radio"
              id="Offerimgyes"
              name="OfferimgViewview"
              value="yes"
              onChange={handleRadioChange}
            />
            <label htmlFor="Offerimgyes" className="m-1">
              Yes
            </label>
            <input
              type="radio"
              id="Offerimgno"
              name="OfferimgViewview"
              value="no"
              onChange={handleRadioChange}
            />
            <label htmlFor="Offerimgno" className="m-1">
              No
            </label>
            <input
              type="checkbox"
              id="Offerimgadd"
              name="OfferimgView-add"
              checked={permissions.OfferimgView.add}
              onChange={handleCheckboxChange}
            />
            <label htmlFor="Offerimgadd" className="m-1">
              Add
            </label>
            <input
              type="checkbox"
              id="Offerimgedit"
              name="OfferimgView-edit"
              checked={permissions.OfferimgView.edit}
              onChange={handleCheckboxChange}
            />
            <label htmlFor="Offerimgedit" className="m-1">
              Edit
            </label>
            <input
              type="checkbox"
              id="Offerimgdelete"
              name="OfferimgView-delete"
              checked={permissions.OfferimgView.delete}
              onChange={handleCheckboxChange}
            />
            <label htmlFor="Offerimgdelete" className="m-1">
              Delete
            </label>
          </div>
        </div>

        <div>
          <label className="bold">Promo Code View?</label>
          <br />
          <div className="">
            <input
              type="radio"
              id="Promoyes"
              name="PromoViewview"
              value="yes"
              onChange={handleRadioChange}
            />
            <label htmlFor="Promoyes" className="m-1">
              Yes
            </label>
            <input
              type="radio"
              id="Promono"
              name="PromoViewview"
              value="no"
              onChange={handleRadioChange}
            />
            <label htmlFor="Promono" className="m-1">
              No
            </label>
            <input
              type="checkbox"
              id="Promoadd"
              name="PromoView-add"
              checked={permissions.PromoView.add}
              onChange={handleCheckboxChange}
            />
            <label htmlFor="Promoadd" className="m-1">
              Add
            </label>
            <input
              type="checkbox"
              id="Promoedit"
              name="PromoView-edit"
              checked={permissions.PromoView.edit}
              onChange={handleCheckboxChange}
            />
            <label htmlFor="Promoedit" className="m-1">
              Edit
            </label>
            <input
              type="checkbox"
              id="Promodelete"
              name="PromoView-delete"
              checked={permissions.PromoView.delete}
              onChange={handleCheckboxChange}
            />
            <label htmlFor="Promodelete" className="m-1">
              Delete
            </label>
          </div>
        </div>

        <div>
          <label className="bold">Delivery Boy View?</label>
          <br />
          <div className="">
            <input
              type="radio"
              id="Deliveryyes"
              name="DeliveryViewview"
              value="yes"
              onChange={handleRadioChange}
            />
            <label htmlFor="Deliveryyes" className="m-1">
              Yes
            </label>
            <input
              type="radio"
              id="Deliveryno"
              name="DeliveryViewview"
              value="no"
              onChange={handleRadioChange}
            />
            <label htmlFor="Deliveryno" className="m-1">
              No
            </label>
            <input
              type="checkbox"
              id="Deliveryadd"
              name="DeliveryView-add"
              checked={permissions.DeliveryView.add}
              onChange={handleCheckboxChange}
            />
            <label htmlFor="Deliveryadd" className="m-1">
              Add
            </label>
            <input
              type="checkbox"
              id="Deliveryedit"
              name="DeliveryView-edit"
              checked={permissions.DeliveryView.edit}
              onChange={handleCheckboxChange}
            />
            <label htmlFor="Deliveryedit" className="m-1">
              Edit
            </label>
            <input
              type="checkbox"
              id="Deliverydelete"
              name="DeliveryView-delete"
              checked={permissions.DeliveryView.delete}
              onChange={handleCheckboxChange}
            />
            <label htmlFor="Deliverydelete" className="m-1">
              Delete
            </label>
          </div>
        </div>

        <div>
          <label className="bold">Add Delivery Boy View?</label>
          <br />
          <div className="">
            <input
              type="radio"
              id="AddDeliveryyes"
              name="AddDeliveryViewview"
              value="yes"
              onChange={handleRadioChange}
            />
            <label htmlFor="AddDeliveryyes" className="m-1">
              Yes
            </label>
            <input
              type="radio"
              id="AddDeliveryno"
              name="AddDeliveryViewview"
              value="no"
              onChange={handleRadioChange}
            />
            <label htmlFor="AddDeliveryno" className="m-1">
              No
            </label>
            <input
              type="checkbox"
              id="AddDeliveryadd"
              name="AddDeliveryView-add"
              checked={permissions.AddDeliveryView.add}
              onChange={handleCheckboxChange}
            />
            <label htmlFor="AddDeliveryadd" className="m-1">
              Add
            </label>
            <input
              type="checkbox"
              id="AddDeliveryedit"
              name="AddDeliveryView-edit"
              checked={permissions.AddDeliveryView.edit}
              onChange={handleCheckboxChange}
            />
            <label htmlFor="AddDeliveryedit" className="m-1">
              Edit
            </label>
            <input
              type="checkbox"
              id="AddDeliverydelete"
              name="AddDeliveryView-delete"
              checked={permissions.AddDeliveryView.delete}
              onChange={handleCheckboxChange}
            />
            <label htmlFor="AddDeliverydelete" className="m-1">
              Delete
            </label>
          </div>
        </div>

        <div>
          <label className="bold">Manage Delivery Boy View?</label>
          <br />
          <div className="">
            <input
              type="radio"
              id="ManageDeliveryyes"
              name="ManageDeliveryViewview"
              value="yes"
              onChange={handleRadioChange}
            />
            <label htmlFor="ManageDeliveryyes" className="m-1">
              Yes
            </label>
            <input
              type="radio"
              id="ManageDeliveryno"
              name="ManageDeliveryViewview"
              value="no"
              onChange={handleRadioChange}
            />
            <label htmlFor="ManageDeliveryno" className="m-1">
              No
            </label>
            <input
              type="checkbox"
              id="ManageDeliveryadd"
              name="ManageDeliveryView-add"
              checked={permissions.ManageDeliveryView.add}
              onChange={handleCheckboxChange}
            />
            <label htmlFor="ManageDeliveryadd" className="m-1">
              Add
            </label>
            <input
              type="checkbox"
              id="ManageDeliveryedit"
              name="ManageDeliveryView-edit"
              checked={permissions.ManageDeliveryView.edit}
              onChange={handleCheckboxChange}
            />
            <label htmlFor="ManageDeliveryedit" className="m-1">
              Edit
            </label>
            <input
              type="checkbox"
              id="ManageDeliverydelete"
              name="ManageDeliveryView-delete"
              checked={permissions.ManageDeliveryView.delete}
              onChange={handleCheckboxChange}
            />
            <label htmlFor="ManageDeliverydelete" className="m-1">
              Delete
            </label>
          </div>
        </div>

        <div>
          <label className="bold">Featured Sections View?</label>
          <br />
          <div className="">
            <input
              type="radio"
              id="Featuredyes"
              name="FeaturedViewview"
              value="yes"
              onChange={handleRadioChange}
            />
            <label htmlFor="Featuredyes" className="m-1">
              Yes
            </label>
            <input
              type="radio"
              id="Featuredno"
              name="FeaturedViewview"
              value="no"
              onChange={handleRadioChange}
            />
            <label htmlFor="Featuredno" className="m-1">
              No
            </label>
            <input
              type="checkbox"
              id="Featuredadd"
              name="FeaturedView-add"
              checked={permissions.FeaturedView.add}
              onChange={handleCheckboxChange}
            />
            <label htmlFor="Featuredadd" className="m-1">
              Add
            </label>
            <input
              type="checkbox"
              id="Featurededit"
              name="FeaturedView-edit"
              checked={permissions.FeaturedView.edit}
              onChange={handleCheckboxChange}
            />
            <label htmlFor="Featurededit" className="m-1">
              Edit
            </label>
            <input
              type="checkbox"
              id="Featureddelete"
              name="FeaturedView-delete"
              checked={permissions.FeaturedView.delete}
              onChange={handleCheckboxChange}
            />
            <label htmlFor="Featureddelete" className="m-1">
              Delete
            </label>
          </div>
        </div>

        <div>
          <label className="bold">Manage Featured Sections View?</label>
          <br />
          <div className="">
            <input
              type="radio"
              id="ManageFeaturedyes"
              name="ManageFeaturedViewview"
              value="yes"
              onChange={handleRadioChange}
            />
            <label htmlFor="ManageFeaturedyes" className="m-1">
              Yes
            </label>
            <input
              type="radio"
              id="ManageFeaturedno"
              name="ManageFeaturedViewview"
              value="no"
              onChange={handleRadioChange}
            />
            <label htmlFor="ManageFeaturedno" className="m-1">
              No
            </label>
            <input
              type="checkbox"
              id="ManageFeaturedadd"
              name="ManageFeaturedView-add"
              checked={permissions.ManageFeaturedView.add}
              onChange={handleCheckboxChange}
            />
            <label htmlFor="ManageFeaturedadd" className="m-1">
              Add
            </label>
            <input
              type="checkbox"
              id="ManageFeaturededit"
              name="ManageFeaturedView-edit"
              checked={permissions.ManageFeaturedView.edit}
              onChange={handleCheckboxChange}
            />
            <label htmlFor="ManageFeaturededit" className="m-1">
              Edit
            </label>
            <input
              type="checkbox"
              id="ManageFeatureddelete"
              name="ManageFeaturedView-delete"
              checked={permissions.ManageFeaturedView.delete}
              onChange={handleCheckboxChange}
            />
            <label htmlFor="ManageFeatureddelete" className="m-1">
              Delete
            </label>
          </div>
        </div>

        <div>
          <label className="bold">Reports View?</label>
          <br />
          <div className="">
            <input
              type="radio"
              id="Reportsyes"
              name="ReportsViewview"
              value="yes"
              onChange={handleRadioChange}
            />
            <label htmlFor="Reportsyes" className="m-1">
              Yes
            </label>
            <input
              type="radio"
              id="Reportsdno"
              name="ReportsViewview"
              value="no"
              onChange={handleRadioChange}
            />
            <label htmlFor="Reportsno" className="m-1">
              No
            </label>
            <input
              type="checkbox"
              id="Reportsadd"
              name="ReportsView-add"
              checked={permissions.ReportsView.add}
              onChange={handleCheckboxChange}
            />
            <label htmlFor="Reportsadd" className="m-1">
              Add
            </label>
            <input
              type="checkbox"
              id="Reportsedit"
              name="ReportsView-edit"
              checked={permissions.ReportsView.edit}
              onChange={handleCheckboxChange}
            />
            <label htmlFor="Reportsedit" className="m-1">
              Edit
            </label>
            <input
              type="checkbox"
              id="Reportsdelete"
              name="ReportsView-delete"
              checked={permissions.ReportsView.delete}
              onChange={handleCheckboxChange}
            />
            <label htmlFor="Reportsdelete" className="m-1">
              Delete
            </label>
          </div>
        </div>

        <div>
          <label className="bold">Product Sales Report View?</label>
          <br />
          <div className="">
            <input
              type="radio"
              id="ProductSalesyes"
              name="ProductSalesViewview"
              value="yes"
              onChange={handleRadioChange}
            />
            <label htmlFor="ProductSalesyes" className="m-1">
              Yes
            </label>
            <input
              type="radio"
              id="ProductSalesno"
              name="ProductSalesViewview"
              value="no"
              onChange={handleRadioChange}
            />
            <label htmlFor="ProductSalesno" className="m-1">
              No
            </label>
            <input
              type="checkbox"
              id="ProductSalesadd"
              name="ProductSalesView-add"
              checked={permissions.ProductSalesView.add}
              onChange={handleCheckboxChange}
            />
            <label htmlFor="ProductSalesadd" className="m-1">
              Add
            </label>
            <input
              type="checkbox"
              id="ProductSalesedit"
              name="ProductSalesView-edit"
              checked={permissions.ProductSalesView.edit}
              onChange={handleCheckboxChange}
            />
            <label htmlFor="ProductSalesedit" className="m-1">
              Edit
            </label>
            <input
              type="checkbox"
              id="ProductSalesdelete"
              name="ProductSalesView-delete"
              checked={permissions.ProductSalesView.delete}
              onChange={handleCheckboxChange}
            />
            <label htmlFor="ProductSalesdelete" className="m-1">
              Delete
            </label>
          </div>
        </div>

        <div>
          <label className="bold">Sales Report View?</label>
          <br />
          <div className="">
            <input
              type="radio"
              id="Salesyes"
              name="SalesViewview"
              value="yes"
              onChange={handleRadioChange}
            />
            <label htmlFor="Salesyes" className="m-1">
              Yes
            </label>
            <input
              type="radio"
              id="Salesno"
              name="SalesViewview"
              value="no"
              onChange={handleRadioChange}
            />
            <label htmlFor="Salesno" className="m-1">
              No
            </label>
            <input
              type="checkbox"
              id="Salesadd"
              name="SalesView-add"
              checked={permissions.SalesView.add}
              onChange={handleCheckboxChange}
            />
            <label htmlFor="Salesadd" className="m-1">
              Add
            </label>
            <input
              type="checkbox"
              id="Salesedit"
              name="SalesView-edit"
              checked={permissions.SalesView.edit}
              onChange={handleCheckboxChange}
            />
            <label htmlFor="Salesedit" className="m-1">
              Edit
            </label>
            <input
              type="checkbox"
              id="Salesdelete"
              name="SalesView-delete"
              checked={permissions.SalesView.delete}
              onChange={handleCheckboxChange}
            />
            <label htmlFor="Salesdelete" className="m-1">
              Delete
            </label>
          </div>
        </div>

        <div>
          <label className="bold">Order Report View?</label>
          <br />
          <div className="">
            <input
              type="radio"
              id="OrderReportyes"
              name="OrderReportViewview"
              value="yes"
              onChange={handleRadioChange}
            />
            <label htmlFor="OrderReportyes" className="m-1">
              Yes
            </label>
            <input
              type="radio"
              id="OrderReportno"
              name="OrderReportViewview"
              value="no"
              onChange={handleRadioChange}
            />
            <label htmlFor="OrderReportno" className="m-1">
              No
            </label>
            <input
              type="checkbox"
              id="OrderReportadd"
              name="OrderReportView-add"
              checked={permissions.OrderReportView.add}
              onChange={handleCheckboxChange}
            />
            <label htmlFor="OrderReportadd" className="m-1">
              Add
            </label>
            <input
              type="checkbox"
              id="OrderReportedit"
              name="OrderReportView-edit"
              checked={permissions.OrderReportView.edit}
              onChange={handleCheckboxChange}
            />
            <label htmlFor="OrderReportedit" className="m-1">
              Edit
            </label>
            <input
              type="checkbox"
              id="OrderReportdelete"
              name="OrderReportView-delete"
              checked={permissions.OrderReportView.delete}
              onChange={handleCheckboxChange}
            />
            <label htmlFor="Salesdelete" className="m-1">
              Delete
            </label>
          </div>
        </div>

        <div>
          <label className="bold">Stock View?</label>
          <br />
          <div className="">
            <input
              type="radio"
              id="Stockyes"
              name="StockViewview"
              value="yes"
              onChange={handleRadioChange}
            />
            <label htmlFor="Stockyes" className="m-1">
              Yes
            </label>
            <input
              type="radio"
              id="Stockno"
              name="StockViewview"
              value="no"
              onChange={handleRadioChange}
            />
            <label htmlFor="Stockno" className="m-1">
              No
            </label>
            <input
              type="checkbox"
              id="Stockadd"
              name="StockView-add"
              checked={permissions.StockView.add}
              onChange={handleCheckboxChange}
            />
            <label htmlFor="Stockadd" className="m-1">
              Add
            </label>
            <input
              type="checkbox"
              id="Stockedit"
              name="StockView-edit"
              checked={permissions.StockView.edit}
              onChange={handleCheckboxChange}
            />
            <label htmlFor="Stockedit" className="m-1">
              Edit
            </label>
            <input
              type="checkbox"
              id="Stockdelete"
              name="StockView-delete"
              checked={permissions.StockView.delete}
              onChange={handleCheckboxChange}
            />
            <label htmlFor="Stockdelete" className="m-1">
              Delete
            </label>
          </div>
        </div>

        <div>
          <label className="bold">Notifications View?</label>
          <br />
          <div className="">
            <input
              type="radio"
              id="Notificationsyes"
              name="NotificationsViewview"
              value="yes"
              onChange={handleRadioChange}
            />
            <label htmlFor="Notificationsyes" className="m-1">
              Yes
            </label>
            <input
              type="radio"
              id="Notificationsno"
              name="NotificationsViewview"
              value="no"
              onChange={handleRadioChange}
            />
            <label htmlFor="Notificationsno" className="m-1">
              No
            </label>
            <input
              type="checkbox"
              id="Notificationsadd"
              name="NotificationsView-add"
              checked={permissions.NotificationsView.add}
              onChange={handleCheckboxChange}
            />
            <label htmlFor="Notificationsadd" className="m-1">
              Add
            </label>
            <input
              type="checkbox"
              id="Notificationsedit"
              name="NotificationsView-edit"
              checked={permissions.NotificationsView.edit}
              onChange={handleCheckboxChange}
            />
            <label htmlFor="Notificationsedit" className="m-1">
              Edit
            </label>
            <input
              type="checkbox"
              id="Notificationsdelete"
              name="NotificationsView-delete"
              checked={permissions.NotificationsView.delete}
              onChange={handleCheckboxChange}
            />
            <label htmlFor="Notificationsdelete" className="m-1">
              Delete
            </label>
          </div>
        </div>

        <div>
          <label className="bold">Manage Notifications View?</label>
          <br />
          <div className="">
            <input
              type="radio"
              id="ManageNotificationsyes"
              name="ManageNotificationsViewview"
              value="yes"
              onChange={handleRadioChange}
            />
            <label htmlFor="ManageNotificationsyes" className="m-1">
              Yes
            </label>
            <input
              type="radio"
              id="ManageNotificationsno"
              name="ManageNotificationsViewview"
              value="no"
              onChange={handleRadioChange}
            />
            <label htmlFor="ManageNotificationsno" className="m-1">
              No
            </label>
            <input
              type="checkbox"
              id="ManageNotificationsadd"
              name="ManageNotificationsView-add"
              checked={permissions.ManageNotificationsView.add}
              onChange={handleCheckboxChange}
            />
            <label htmlFor="ManageNotificationsadd" className="m-1">
              Add
            </label>
            <input
              type="checkbox"
              id="ManageNotificationsedit"
              name="ManageNotificationsView-edit"
              checked={permissions.ManageNotificationsView.edit}
              onChange={handleCheckboxChange}
            />
            <label htmlFor="ManageNotificationsedit" className="m-1">
              Edit
            </label>
            <input
              type="checkbox"
              id="ManageNotificationsdelete"
              name="ManageNotificationsView-delete"
              checked={permissions.ManageNotificationsView.delete}
              onChange={handleCheckboxChange}
            />
            <label htmlFor="ManageNotificationsdelete" className="m-1">
              Delete
            </label>
          </div>
        </div>

        <div>
          <label className="bold">System Users View?</label>
          <br />
          <div className="">
            <input
              type="radio"
              id="Systemyes"
              name="SystemViewview"
              value="yes"
              onChange={handleRadioChange}
            />
            <label htmlFor="Systemyes" className="m-1">
              Yes
            </label>
            <input
              type="radio"
              id="Systemno"
              name="SystemViewview"
              value="no"
              onChange={handleRadioChange}
            />
            <label htmlFor="Systemsno" className="m-1">
              No
            </label>
            <input
              type="checkbox"
              id="Systemadd"
              name="SystemView-add"
              checked={permissions.SystemView.add}
              onChange={handleCheckboxChange}
            />
            <label htmlFor="Systemadd" className="m-1">
              Add
            </label>
            <input
              type="checkbox"
              id="Systemedit"
              name="SystemView-edit"
              checked={permissions.SystemView.edit}
              onChange={handleCheckboxChange}
            />
            <label htmlFor="Systemedit" className="m-1">
              Edit
            </label>
            <input
              type="checkbox"
              id="Systemdelete"
              name="SystemView-delete"
              checked={permissions.SystemView.delete}
              onChange={handleCheckboxChange}
            />
            <label htmlFor="Systemdelete" className="m-1">
              Delete
            </label>
          </div>
        </div>

        <div>
          <label className="bold">Add System Users View?</label>
          <br />
          <div className="">
            <input
              type="radio"
              id="AddSystemyes"
              name="AddSystemViewview"
              value="yes"
              onChange={handleRadioChange}
            />
            <label htmlFor="AddSystemyes" className="m-1">
              Yes
            </label>
            <input
              type="radio"
              id="AddSystemno"
              name="AddSystemViewview"
              value="no"
              onChange={handleRadioChange}
            />
            <label htmlFor="AddSystemsno" className="m-1">
              No
            </label>
            <input
              type="checkbox"
              id="AddSystemadd"
              name="AddSystemView-add"
              checked={permissions.AddSystemView.add}
              onChange={handleCheckboxChange}
            />
            <label htmlFor="AddSystemadd" className="m-1">
              Add
            </label>
            <input
              type="checkbox"
              id="AddSystemedit"
              name="AddSystemView-edit"
              checked={permissions.AddSystemView.edit}
              onChange={handleCheckboxChange}
            />
            <label htmlFor="AddSystemedit" className="m-1">
              Edit
            </label>
            <input
              type="checkbox"
              id="AddSystemdelete"
              name="AddSystemView-delete"
              checked={permissions.AddSystemView.delete}
              onChange={handleCheckboxChange}
            />
            <label htmlFor="AddSystemdelete" className="m-1">
              Delete
            </label>
          </div>
        </div>

        <div>
          <label className="bold">Add Role?</label>
          <br />
          <div className="">
            <input
              type="radio"
              id="Roleyes"
              name="RoleViewview"
              value="yes"
              onChange={handleRadioChange}
            />
            <label htmlFor="Roleyes" className="m-1">
              Yes
            </label>
            <input
              type="radio"
              id="Roleno"
              name="RoleViewview"
              value="no"
              onChange={handleRadioChange}
            />
            <label htmlFor="Roleno" className="m-1">
              No
            </label>
            <input
              type="checkbox"
              id="Roleadd"
              name="RoleView-add"
              checked={permissions.RoleView.add}
              onChange={handleCheckboxChange}
            />
            <label htmlFor="Roleadd" className="m-1">
              Add
            </label>
            <input
              type="checkbox"
              id="Roleedit"
              name="RoleView-edit"
              checked={permissions.RoleView.edit}
              onChange={handleCheckboxChange}
            />
            <label htmlFor="Roleedit" className="m-1">
              Edit
            </label>
            <input
              type="checkbox"
              id="Roledelete"
              name="RoleView-delete"
              checked={permissions.RoleView.delete}
              onChange={handleCheckboxChange}
            />
            <label htmlFor="Roledelete" className="m-1">
              Delete
            </label>
          </div>
        </div>

        <div>
          <label className="bold">Mobile Users View?</label>
          <br />
          <div className="">
            <input
              type="radio"
              id="Mobileyes"
              name="MobileViewview"
              value="yes"
              onChange={handleRadioChange}
            />
            <label htmlFor="Mobileyes" className="m-1">
              Yes
            </label>
            <input
              type="radio"
              id="Mobileno"
              name="MobileViewview"
              value="no"
              onChange={handleRadioChange}
            />
            <label htmlFor="Mobileno" className="m-1">
              No
            </label>

            <input
              type="checkbox"
              id="Mobiledelete"
              name="MobileView-delete"
              checked={permissions.MobileView.delete}
              onChange={handleCheckboxChange}
            />
            <label htmlFor="Mobiledelete" className="m-1">
              Delete
            </label>
          </div>
        </div>

        <div>
          <label className="bold">Return Requests View?</label>
          <br />
          <div className="">
            <input
              type="radio"
              id="ReturnRequestsyes"
              name="ReturnRequestsViewview"
              value="yes"
              onChange={handleRadioChange}
            />
            <label htmlFor="ReturnRequestsyes" className="m-1">
              Yes
            </label>
            <input
              type="radio"
              id="ReturnRequestsno"
              name="ReturnRequestsViewview"
              value="no"
              onChange={handleRadioChange}
            />
            <label htmlFor="ReturnRequestsno" className="m-1">
              No
            </label>
            <input
              type="checkbox"
              id="ReturnRequestsadd"
              name="ReturnRequestsView-add"
              checked={permissions.ReturnRequestsView.add}
              onChange={handleCheckboxChange}
            />
            <label htmlFor="ReturnRequestsadd" className="m-1">
              Add
            </label>
            <input
              type="checkbox"
              id="ReturnRequestsedit"
              name="ReturnRequestsView-edit"
              checked={permissions.ReturnRequestsView.edit}
              onChange={handleCheckboxChange}
            />
            <label htmlFor="ReturnRequestsedit" className="m-1">
              Edit
            </label>
            <input
              type="checkbox"
              id="ReturnRequestmdelete"
              name="ReturnRequestsView-delete"
              checked={permissions.ReturnRequestsView.delete}
              onChange={handleCheckboxChange}
            />
            <label htmlFor="ReturnRequestsdelete" className="m-1">
              Delete
            </label>
          </div>
        </div>

        <div>
          <label className="bold">Withdrawal Requests View?</label>
          <br />
          <div className="">
            <input
              type="radio"
              id="WithdrawalRequestsyes"
              name="WithdrawalRequestsViewview"
              value="yes"
              onChange={handleRadioChange}
            />
            <label htmlFor="WithdrawalRequestsyes" className="m-1">
              Yes
            </label>
            <input
              type="radio"
              id="WithdrawalRequestsno"
              name="WithdrawalRequestsViewview"
              value="no"
              onChange={handleRadioChange}
            />
            <label htmlFor="WithdrawalRequestsno" className="m-1">
              No
            </label>
            <input
              type="checkbox"
              id="WithdrawalRequestsadd"
              name="WithdrawalRequestsView-add"
              checked={permissions.WithdrawalRequestsView.add}
              onChange={handleCheckboxChange}
            />
            <label htmlFor="WithdrawalRequestsadd" className="m-1">
              Add
            </label>
            <input
              type="checkbox"
              id="WithdrawalRequestsedit"
              name="WithdrawalRequestsView-edit"
              checked={permissions.WithdrawalRequestsView.edit}
              onChange={handleCheckboxChange}
            />
            <label htmlFor="WithdrawalRequestsedit" className="m-1">
              Edit
            </label>
            <input
              type="checkbox"
              id="WithdrawalRequestmdelete"
              name="WithdrawalRequestsView-delete"
              checked={permissions.WithdrawalRequestsView.delete}
              onChange={handleCheckboxChange}
            />
            <label htmlFor="WithdrawalRequestsdelete" className="m-1">
              Delete
            </label>
          </div>
        </div>

        <div>
          <label className="bold">FAQ's View?</label>
          <br />
          <div className="">
            <input
              type="radio"
              id="FAQyes"
              name="FAQViewview"
              value="yes"
              onChange={handleRadioChange}
            />
            <label htmlFor="FAQyes" className="m-1">
              Yes
            </label>
            <input
              type="radio"
              id="FAQno"
              name="FAQViewview"
              value="no"
              onChange={handleRadioChange}
            />
            <label htmlFor="FAQno" className="m-1">
              No
            </label>
            <input
              type="checkbox"
              id="FAQsadd"
              name="FAQView-add"
              checked={permissions.FAQView.add}
              onChange={handleCheckboxChange}
            />
            <label htmlFor="FAQadd" className="m-1">
              Add
            </label>
            <input
              type="checkbox"
              id="FAQedit"
              name="FAQView-edit"
              checked={permissions.FAQView.edit}
              onChange={handleCheckboxChange}
            />
            <label htmlFor="FAQedit" className="m-1">
              Edit
            </label>
            <input
              type="checkbox"
              id="FAQdelete"
              name="FAQView-delete"
              checked={permissions.FAQView.delete}
              onChange={handleCheckboxChange}
            />
            <label htmlFor="FAQdelete" className="m-1">
              Delete
            </label>
          </div>
        </div>

        <div>
          <label className="bold">Policy View?</label>
          <br />
          <div className="">
            <input
              type="radio"
              id="Policyyes"
              name="PolicyViewview"
              value="yes"
              onChange={handleRadioChange}
            />
            <label htmlFor="Policyyes" className="m-1">
              Yes
            </label>
            <input
              type="radio"
              id="Policyno"
              name="PolicyViewview"
              value="no"
              onChange={handleRadioChange}
            />
            <label htmlFor="Policyno" className="m-1">
              No
            </label>
            <input
              type="checkbox"
              id="Policyadd"
              name="PolicyView-add"
              checked={permissions.PolicyView.add}
              onChange={handleCheckboxChange}
            />
            <label htmlFor="Policyadd" className="m-1">
              Add
            </label>
            <input
              type="checkbox"
              id="Policyedit"
              name="PolicyView-edit"
              checked={permissions.PolicyView.edit}
              onChange={handleCheckboxChange}
            />
            <label htmlFor="Policyedit" className="m-1">
              Edit
            </label>
            <input
              type="checkbox"
              id="Policydelete"
              name="PolicyView-delete"
              checked={permissions.PolicyView.delete}
              onChange={handleCheckboxChange}
            />
            <label htmlFor="Policydelete" className="m-1">
              Delete
            </label>
          </div>
        </div>

        <div>
          <label className="bold">Privacy Policy View?</label>
          <br />
          <div className="">
            <input
              type="radio"
              id="PrivacyPolicyyes"
              name="PrivacyPolicyViewview"
              value="yes"
              onChange={handleRadioChange}
            />
            <label htmlFor="PrivacyPolicyyes" className="m-1">
              Yes
            </label>
            <input
              type="radio"
              id="PrivacyPolicyno"
              name="PrivacyPolicyViewview"
              value="no"
              onChange={handleRadioChange}
            />
            <label htmlFor="PrivacyPolicyno" className="m-1">
              No
            </label>
            <input
              type="checkbox"
              id="PrivacyPolicyadd"
              name="PrivacyPolicyView-add"
              checked={permissions.PrivacyPolicyView.add}
              onChange={handleCheckboxChange}
            />
            <label htmlFor="PrivacyPolicyadd" className="m-1">
              Add
            </label>
            <input
              type="checkbox"
              id="PrivacyPolicyedit"
              name="PrivacyPolicyView-edit"
              checked={permissions.PrivacyPolicyView.edit}
              onChange={handleCheckboxChange}
            />
            <label htmlFor="PrivacyPolicyedit" className="m-1">
              Edit
            </label>
            <input
              type="checkbox"
              id="PrivacyPolicydelete"
              name="PrivacyPolicyView-delete"
              checked={permissions.PrivacyPolicyView.delete}
              onChange={handleCheckboxChange}
            />
            <label htmlFor="PrivacyPolicydelete" className="m-1">
              Delete
            </label>
          </div>
        </div>

        <div>
          <label className="bold">About Us View?</label>
          <br />
          <div className="">
            <input
              type="radio"
              id="AboutPolicyyes"
              name="AboutPolicyViewview"
              value="yes"
              onChange={handleRadioChange}
            />
            <label htmlFor="AboutPolicyyes" className="m-1">
              Yes
            </label>
            <input
              type="radio"
              id="AboutPolicyno"
              name="AboutPolicyViewview"
              value="no"
              onChange={handleRadioChange}
            />
            <label htmlFor="AboutPolicyno" className="m-1">
              No
            </label>
            <input
              type="checkbox"
              id="AboutPolicyadd"
              name="AboutPolicyView-add"
              checked={permissions.AboutPolicyView.add}
              onChange={handleCheckboxChange}
            />
            <label htmlFor="AboutPolicyadd" className="m-1">
              Add
            </label>
            <input
              type="checkbox"
              id="AboutPolicyedit"
              name="AboutPolicyView-edit"
              checked={permissions.AboutPolicyView.edit}
              onChange={handleCheckboxChange}
            />
            <label htmlFor="AboutPolicyedit" className="m-1">
              Edit
            </label>
            <input
              type="checkbox"
              id="AboutPolicydelete"
              name="AboutPolicyView-delete"
              checked={permissions.AboutPolicyView.delete}
              onChange={handleCheckboxChange}
            />
            <label htmlFor="AboutPolicydelete" className="m-1">
              Delete
            </label>
          </div>
        </div>

        <div>
          <label className="bold">Delivery Policy View?</label>
          <br />
          <div className="">
            <input
              type="radio"
              id="DeliveryPolicyyes"
              name="DeliveryPolicyViewview"
              value="yes"
              onChange={handleRadioChange}
            />
            <label htmlFor="DeliveryPolicyyes" className="m-1">
              Yes
            </label>
            <input
              type="radio"
              id="DeliveryPolicyno"
              name="DeliveryPolicyViewview"
              value="no"
              onChange={handleRadioChange}
            />
            <label htmlFor="DeliveryPolicyno" className="m-1">
              No
            </label>
            <input
              type="checkbox"
              id="DeliveryPolicyadd"
              name="DeliveryPolicyView-add"
              checked={permissions.DeliveryPolicyView.add}
              onChange={handleCheckboxChange}
            />
            <label htmlFor="DeliveryPolicyadd" className="m-1">
              Add
            </label>
            <input
              type="checkbox"
              id="DeliveryPolicyedit"
              name="DeliveryPolicyView-edit"
              checked={permissions.DeliveryPolicyView.edit}
              onChange={handleCheckboxChange}
            />
            <label htmlFor="DeliveryPolicyedit" className="m-1">
              Edit
            </label>
            <input
              type="checkbox"
              id="DeliveryPolicydelete"
              name="DeliveryPolicyView-delete"
              checked={permissions.DeliveryPolicyView.delete}
              onChange={handleCheckboxChange}
            />
            <label htmlFor="DeliveryPolicydelete" className="m-1">
              Delete
            </label>
          </div>
        </div>

        <div>
          <label className="bold">Terms and Condition View?</label>
          <br />
          <div className="">
            <input
              type="radio"
              id="TermsPolicyyes"
              name="TermsPolicyViewview"
              value="yes"
              onChange={handleRadioChange}
            />
            <label htmlFor="TermsPolicyyes" className="m-1">
              Yes
            </label>
            <input
              type="radio"
              id="TermsPolicyno"
              name="TermsPolicyViewview"
              value="no"
              onChange={handleRadioChange}
            />
            <label htmlFor="TermsPolicyno" className="m-1">
              No
            </label>
            <input
              type="checkbox"
              id="TermsPolicyadd"
              name="TermsPolicyView-add"
              checked={permissions.TermsPolicyView.add}
              onChange={handleCheckboxChange}
            />
            <label htmlFor="TermsPolicyadd" className="m-1">
              Add
            </label>
            <input
              type="checkbox"
              id="TermsPolicyedit"
              name="TermsPolicyView-edit"
              checked={permissions.TermsPolicyView.edit}
              onChange={handleCheckboxChange}
            />
            <label htmlFor="TermsPolicyedit" className="m-1">
              Edit
            </label>
            <input
              type="checkbox"
              id="TermsPolicydelete"
              name="TermsPolicyView-delete"
              checked={permissions.TermsPolicyView.delete}
              onChange={handleCheckboxChange}
            />
            <label htmlFor="TermsPolicydelete" className="m-1">
              Delete
            </label>
          </div>
        </div>
        <div>
          <label className="bold">Refund Policy View?</label>
          <br />
          <div className="">
            <input
              type="radio"
              id="RefundPolicyyes"
              name="RefundPolicyViewview"
              value="yes"
              onChange={handleRadioChange}
            />
            <label htmlFor="RefundPolicyyes" className="m-1">
              Yes
            </label>
            <input
              type="radio"
              id="RefundPolicyno"
              name="RefundPolicyViewview"
              value="no"
              onChange={handleRadioChange}
            />
            <label htmlFor="RefundPolicyno" className="m-1">
              No
            </label>
            <input
              type="checkbox"
              id="RefundPolicyadd"
              name="RefundPolicyView-add"
              checked={permissions.RefundPolicyView.add}
              onChange={handleCheckboxChange}
            />
            <label htmlFor="RefundPolicyadd" className="m-1">
              Add
            </label>
            <input
              type="checkbox"
              id="RefundPolicyedit"
              name="RefundPolicyView-edit"
              checked={permissions.RefundPolicyView.edit}
              onChange={handleCheckboxChange}
            />
            <label htmlFor="RefundPolicyedit" className="m-1">
              Edit
            </label>
            <input
              type="checkbox"
              id="RefundPolicydelete"
              name="RefundPolicyView-delete"
              checked={permissions.RefundPolicyView.delete}
              onChange={handleCheckboxChange}
            />
            <label htmlFor="RefundPolicydelete" className="m-1">
              Delete
            </label>
          </div>
        </div>

        <div>
          <label className="bold"> FreeProduct View?</label>
          <br />
          <div className="">
            <input
              type="radio"
              id="FreeProductyes"
              name="FreeProductview"
              value="yes"
              onChange={handleRadioChange}
            />
            <label htmlFor="FreeProductyes" className="m-1">
              Yes
            </label>
            <input
              type="radio"
              id="FreeProductno"
              name="FreeProductViewview"
              value="no"
              onChange={handleRadioChange}
            />
            <label htmlFor="FreeProductno" className="m-1">
              No
            </label>
            <input
              type="checkbox"
              id="FreeProductadd"
              name="FreeProductView-add"
              checked={permissions.FreeProductView.add}
              onChange={handleCheckboxChange}
            />
            <label htmlFor="FreeProductadd" className="m-1">
              Add
            </label>
            <input
              type="checkbox"
              id="FreeProductedit"
              name="FreeProductView-edit"
              checked={permissions.FreeProductView.edit}
              onChange={handleCheckboxChange}
            />
            <label htmlFor="FreeProductedit" className="m-1">
              Edit
            </label>
            <input
              type="checkbox"
              id="FreeProductdelete"
              name="FreeProductView-delete"
              checked={permissions.FreeProductView.delete}
              onChange={handleCheckboxChange}
            />
            <label htmlFor="FreeProductdelete" className="m-1">
              Delete
            </label>
          </div>
        </div>
        <div>
          <label className="bold">Display View?</label>
          <br />
          <div className="">
            <input
              type="radio"
              id="Displaytvyes"
              name="DisplaytvViewview"
              value="yes"
              onChange={handleRadioChange}
            />
            <label htmlFor="Displaytvyes" className="m-1">
              Yes
            </label>
            <input
              type="radio"
              id="Displaytvno"
              name="DisplaytvViewview"
              value="no"
              onChange={handleRadioChange}
            />
            <label htmlFor="Displaytvno" className="m-1">
              No
            </label>
            <input
              type="checkbox"
              id="Displaytvadd"
              name="DisplaytvView-add"
              checked={permissions.DisplaytvView.add}
              onChange={handleCheckboxChange}
            />
            <label htmlFor="Displaytvadd" className="m-1">
              Add
            </label>
            <input
              type="checkbox"
              id="Displaytvedit"
              name="DisplaytvView-edit"
              checked={permissions.DisplaytvView.edit}
              onChange={handleCheckboxChange}
            />
            <label htmlFor="Displaytvedit" className="m-1">
              Edit
            </label>
            <input
              type="checkbox"
              id="Displaytvdelete"
              name="DisplaytvView-delete"
              checked={permissions.DisplaytvView.delete}
              onChange={handleCheckboxChange}
            />
            <label htmlFor="Displaytvdelete" className="m-1">
              Delete
            </label>
          </div>
        </div>
        <div>
          <label className="bold">SmartTVFeatures View?</label>
          <br />
          <div className="">
            <input
              type="radio"
              id="SamrtTvyes"
              name="SmarTvViewview"
              value="yes"
              onChange={handleRadioChange}
            />
            <label htmlFor="SamrtTvyes" className="m-1">
              Yes
            </label>
            <input
              type="radio"
              id="SamrtTvno"
              name="SmarTvViewview"
              value="no"
              onChange={handleRadioChange}
            />
            <label htmlFor="SamrtTvyno" className="m-1">
              No
            </label>
            <input
              type="checkbox"
              id="SamrtTvadd"
              name="SmarTvView-add"
              checked={permissions.SmarTvView.add}
              onChange={handleCheckboxChange}
            />
            <label htmlFor="SmarTvadd" className="m-1">
              Add
            </label>
            <input
              type="checkbox"
              id="SamrtTvdit"
              name="SmarTvView-edit"
              checked={permissions.SmarTvView.edit}
              onChange={handleCheckboxChange}
            />
            <label htmlFor="SamrtTvyedit" className="m-1">
              Edit
            </label>
            <input
              type="checkbox"
              id="SamrtTvdelete"
              name="SmarTvView-delete"
              checked={permissions.SmarTvView.delete}
              onChange={handleCheckboxChange}
            />
            <label htmlFor="SamrtTvdelete" className="m-1">
              Delete
            </label>
          </div>
        </div>
        <div>
          <label className="bold">Sound View?</label>
          <br />
          <div className="">
            <input
              type="radio"
              id="Soundyes"
              name="SoundViewview"
              value="yes"
              onChange={handleRadioChange}
            />
            <label htmlFor="Soundyes" className="m-1">
              Yes
            </label>
            <input
              type="radio"
              id="Soundno"
              name="SoundViewview"
              value="no"
              onChange={handleRadioChange}
            />
            <label htmlFor="Soundno" className="m-1">
              No
            </label>
            <input
              type="checkbox"
              id="Soundyadd"
              name="SoundView-add"
              checked={permissions.SoundView.add}
              onChange={handleCheckboxChange}
            />
            <label htmlFor="Soundadd" className="m-1">
              Add
            </label>
            <input
              type="checkbox"
              id="Soundedit"
              name="SoundView-edit"
              checked={permissions.SoundView.edit}
              onChange={handleCheckboxChange}
            />
            <label htmlFor="Soundedit" className="m-1">
              Edit
            </label>
            <input
              type="checkbox"
              id="Sounddelete"
              name="SoundView-delete"
              checked={permissions.SoundView.delete}
              onChange={handleCheckboxChange}
            />
            <label htmlFor="Sounddelete" className="m-1">
              Delete
            </label>
          </div>
        </div>
        <div>
          <label className="bold">SupportedInternet View?</label>
          <br />
          <div className="">
            <input
              type="radio"
              id="supportedInternetyes"
              name="supportedInternetViewview"
              value="yes"
              onChange={handleRadioChange}
            />
            <label htmlFor="supportedInternetyes" className="m-1">
              Yes
            </label>
            <input
              type="radio"
              id="supportedInternetno"
              name="supportedInternetViewview"
              value="no"
              onChange={handleRadioChange}
            />
            <label htmlFor="supportedInternetno" className="m-1">
              No
            </label>
            <input
              type="checkbox"
              id="supportedInternetadd"
              name="supportedInternetView-add"
              checked={permissions.supportedInternetView.add}
              onChange={handleCheckboxChange}
            />
            <label htmlFor="supportedInternetadd" className="m-1">
              Add
            </label>
            <input
              type="checkbox"
              id="supportedInternetedit"
              name="supportedInternetView-edit"
              checked={permissions.supportedInternetView.edit}
              onChange={handleCheckboxChange}
            />
            <label htmlFor="supportedInternetedit" className="m-1">
              Edit
            </label>
            <input
              type="checkbox"
              id="supportedInternetdelete"
              name="supportedInternetView-delete"
              checked={permissions.supportedInternetView.delete}
              onChange={handleCheckboxChange}
            />
            <label htmlFor="supportedInternetdelete" className="m-1">
              Delete
            </label>
          </div>
        </div>
        <div>
          <label className="bold">ProdctDimension View?</label>
          <br />
          <div className="">
            <input
              type="radio"
              id="ProdctDimensionyes"
              name="ProdctDimensionViewview"
              value="yes"
              onChange={handleRadioChange}
            />
            <label htmlFor="ProdctDimensionyes" className="m-1">
              Yes
            </label>
            <input
              type="radio"
              id="ProdctDimensionno"
              name="ProdctDimensionViewview"
              value="no"
              onChange={handleRadioChange}
            />
            <label htmlFor="ProdctDimensionno" className="m-1">
              No
            </label>
            <input
              type="checkbox"
              id="ProdctDimensionadd"
              name="ProdctDimensionView-add"
              checked={permissions.ProdctDimensionView.add}
              onChange={handleCheckboxChange}
            />
            <label htmlFor="ProdctDimensionadd" className="m-1">
              Add
            </label>
            <input
              type="checkbox"
              id="ProdctDimensionedit"
              name="ProdctDimensionView-edit"
              checked={permissions.ProdctDimensionView.edit}
              onChange={handleCheckboxChange}
            />
            <label htmlFor="ProdctDimensionedit" className="m-1">
              Edit
            </label>
            <input
              type="checkbox"
              id="ProdctDimensiondelete"
              name="ProdctDimensionView-delete"
              checked={permissions.ProdctDimensionView.delete}
              onChange={handleCheckboxChange}
            />
            <label htmlFor="ProdctDimensiondelete" className="m-1">
              Delete
            </label>
          </div>
        </div>
      </div>
      <div className="d-flex justify-content-end m-4">
        <Button variant="success" onClick={handleUpdate}>
          Update
        </Button>
      </div>
    </div>
  );
}

export default Roleassign;
