import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { useNavigate } from "react-router-dom";
import Lottie from "react-lottie";
import swal from "sweetalert";
import { FaSearch, FaTrash, FaPen, FaCalendar } from "react-icons/fa";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faPenToSquare } from "@fortawesome/free-solid-svg-icons";
import { faSortUp, faSortDown } from "@fortawesome/free-solid-svg-icons";
import Select from "react-select";
// import swal from "sweetalert";
// import Swal from "sweetalert2";
function FreeProduct() {
  const navigate = useNavigate();
  const [itemsPerPage, setItemsPerPage] = useState(20);
  const [totalItems, setTotalItems] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredCapacitie, setFilteredCapacities] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [sortColumn, setSortColumn] = useState("");
  const [parentcategory, setParentcategory] = useState("");
  const [selectedProduct, setSelectedProduct] = useState("");
  const [selectedCategory, setSelectedCategory] = useState("");
  const [subCategories, setSubCategories] = useState([]);
  console.log({ subCategories: subCategories });
  const [categoriesload, setCategoriesload] = useState([]);
  const [status, setStatus] = useState("1");
  const [selectedSubCategory, setSelectedSubCategory] = useState("");
  const [selectedProductName, setSelectedProductName] = useState("");
  const [freeproduct, setFreeProduct] = useState([]);
  const [EditshowModal, setEditShowModal] = useState(false);
  const [editFreeproduct, setEditFreeproduct] = useState({
    id: "",
    category_name: "",
    // Sub_Category: "",
    product_id: "",
    subcategory_name: "",
    free_pro: "",
    cat_id: "",
    sub_id: "",
    status: "",
  });

  const [freepro, setFreepro] = useState("");
  console.log({ editFreeproduct: editFreeproduct });
  const [products, setProducts] = useState([]);
  // const handlePerPageChange = (e) => {
  //   const newItemsPerPage = parseInt(e.target.value);
  //   setItemsPerPage(newItemsPerPage);
  //   setCurrentPage(1); // Reset to first page
  // };
  const [selectedcategoryFree, setSelectedcategoryFree] = useState([]);
  const handleCloseModal = () => {
    setShowModal(false);
    setSelectedSubCategory("");
    setParentcategory("");
    setSelectedProduct("");
    setSelectedcategoryFree("");
    setEditShowModal(false);
  };
  const [categoryid1, setCategoryid1] = useState("");
  console.log({ productIds: categoryid1 });
  const Dataget = async () => {
    try {
      // Fetch categories

      const data = await fetch(
        "https://mahalakshmimobiles.com/admin/categoriesmaster.php"
      );
      const response = await data.json();
      setCategoriesload(response);

      const data1 = await fetch(
        "https://mahalakshmimobiles.com/admin/free_product.php"
      );

      const data2 = await fetch("https://mahalakshmimobiles.com/admin/product.php");
      const res2 = await data2.json();
      const res = await data1.json();
      setFreeProduct(res);
      setFreepro(res2);
    } catch (error) {
      console.error("Error in Dataget:", error);
    }
  };

  useEffect(() => {
    setLoading(true);
    Dataget()
      .then(() => setLoading(false))
      .catch(() => setLoading(false));
  }, []);

  const handleCategoryChange = (e) => {
    const selectedCategory = e.target.value || editFreeproduct?.cat_id || "";
    setParentcategory(selectedCategory);
    setSelectedCategory(selectedCategory);

    if (selectedCategory) {
      fetch(
        `https://mahalakshmimobiles.com/admin/categories.php?parent_category=${selectedCategory}`
      )
        .then((response) => response.json())
        .then((data) => {
          setSubCategories(data);
        })
        .catch((error) => {
          console.error("Error fetching sub-categories:", error);
        });
    } else {
      console.error("Selected category not found.");
    }
  };
  useEffect(() => {
    if (editFreeproduct?.cat_id) {
      handleCategoryChangeupdate(editFreeproduct.cat_id);
    }
    if (editFreeproduct?.sub_id) {
      handleSubCategoryChangeupdate(editFreeproduct.sub_id);
    }
  }, [editFreeproduct?.cat_id, editFreeproduct?.sub_id]);

  const handleCategoryChangeupdate = (selectedCategory) => {
    setEditFreeproduct((prevProduct) => ({
      ...prevProduct,
      cat_id: selectedCategory,
    }));

    setParentcategory(selectedCategory); // Assuming setParentcategory stores the parent category

    fetch(
      `https://mahalakshmimobiles.com/admin/categories.php?parent_category=${selectedCategory}`
    )
      .then((response) => response.json())
      .then((data) => {
        setSubCategories(data);
      })
      .catch((error) => {
        console.error("Error fetching sub-categories:", error);
      });
  };

  useEffect(() => {
    const updatedFilteredCapacities = freeproduct.filter((capacity) => {
      const catName = (capacity.subcategory_name || "").toLowerCase();
      const proName = (capacity.product_name || "").toLowerCase();
      const colour = (capacity.free_product || "").toLowerCase();
      const storage = (capacity.category_name || "").toLowerCase();
      // const variantSize = (capacity.variant_size || "").toLowerCase();
      // const promoCode = (capacity.product_name || "").toLowerCase();

      // Combine fields into a single string for searching
      const combinedFields = `${catName} ${proName} ${colour} ${storage} `;

      // Return whether the search query is found in the combined fields
      return combinedFields.includes(searchQuery.toLowerCase());
    });

    setFilteredCapacities(updatedFilteredCapacities);
  }, [products, searchQuery]);

  const handleSubCategoryChange = (e) => {
    const subCategoryId = e.target.value;
    setSelectedSubCategory(subCategoryId);

    console.log({
      first: `https://mahalakshmimobiles.com/admin/add_variant.php?cat_id=${parentcategory}&&sub_id=${subCategoryId}`,
    });
    fetch(
      `https://mahalakshmimobiles.com/admin/add_variant.php?cat_id=${parentcategory}&&sub_id=${subCategoryId}`
    )
      .then((response) => response.json())
      .then((data) => {
        if (data.success === 0) {
          setProducts([]);
        } else {
          setProducts(data);
        }
      })
      .catch((error) => console.error("Error fetching products:", error));
  };

  const handleSubCategoryChangeupdate = (subCategoryId) => {
    setSelectedSubCategory(subCategoryId);

    // Update the editFreeproduct state correctly
    setEditFreeproduct((prevProduct) => ({
      ...prevProduct,
      sub_id: subCategoryId, // Use subCategoryId instead of e
    }));

    console.log({
      first: `https://mahalakshmimobiles.com/admin/add_variant.php?cat_id=${editFreeproduct?.cat_id}&sub_id=${subCategoryId}`, // Changed '&&' to '&'
    });

    fetch(
      `https://mahalakshmimobiles.com/admin/add_variant.php?cat_id=${editFreeproduct?.cat_id}&sub_id=${subCategoryId}` // Changed '&&' to '&'
    )
      .then((response) => response.json())
      .then((data) => {
        if (data.success === 0) {
          setProducts([]);
        } else {
          setProducts(data);
        }
      })
      .catch((error) => console.error("Error fetching products:", error));
  };
  const handleFreeProductChange = (selectedFreeProductId) => {
    setEditFreeproduct((prevProduct) => ({
      ...prevProduct,
      free_pro: selectedFreeProductId, // Consistently using free_product
    }));
  };

  const handleSelectChange = (event) => {
    const selectedProductId = event.target.value;
    setSelectedProduct(selectedProductId);

    // Find the product object with the selected pro_id
    const selectedProductDetails = products.find(
      (product) => product.pro_id === selectedProductId
    );

    // Set the product name based on the selected product

    setSelectedProductName(selectedProductDetails.product_name);
  };

  const handleSelectChangeupdate = (event) => {
    const selectedProductId = event;
    setEditFreeproduct((prevProduct) => ({
      ...prevProduct,
      product_id: selectedProductId,
    }));

    const selectedProductDetails = products.find(
      (product) => product.pro_id === selectedProductId
    );

    if (selectedProductDetails) {
      setSelectedProductName(selectedProductDetails.product_name);
    }
  };

  const handelEdit = async (id) => {
    try {
      const response = await fetch(
        `https://mahalakshmimobiles.com/admin/free_product.php?id=${id}`
      );

      if (!response.ok) {
        throw new Error("Failed to fetch category");
      }

      const categories = await response.json();
      const categoryDetails = categories.find((item) => item.id === id);

      if (categoryDetails) {
        console.log({ categoryDetails }); // Add this line to check if data is correct
        setEditFreeproduct(categoryDetails);
        setEditShowModal(true);
      } else {
        console.error("Category not found");
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleDelete = async (user_id) => {
    try {
      const shouldUpdate = await swal({
        title: "Are you sure?",
        text: "Once updated, this Free product will be deactivated!",
        icon: "warning",
        buttons: ["Cancel", "Delete"],
        dangerMode: true,
      });

      if (shouldUpdate) {
        const response = await fetch(
          `https://mahalakshmimobiles.com/admin/free_product.php?id=${user_id}&is_deleted=1`,
          {
            method: "PUT",
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
        console.log(
          "first",
          `https://mahalakshmimobiles.com/admin/free_product.php?id=${user_id}&is_deleted=1`
        );

        if (response.ok) {
          swal("Updated!", "User has been deactivated.", "success");
          Dataget(); // Refresh the data after the update
        } else {
          console.error("Failed to update user");
        }
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };
  const handleUpdate = () => {
    // Validate form inputs before submitting
    if (
      !editFreeproduct?.cat_id ||
      !editFreeproduct?.sub_id ||
      !editFreeproduct?.product_id ||
      !editFreeproduct?.free_pro
    ) {
      alert("Please fill out all required fields.");
      return;
    }

    // Create a FormData object for the payload
    const formData = new FormData();
    formData.append("cat_id", editFreeproduct.cat_id);
    formData.append("sub_id", editFreeproduct.sub_id);
    formData.append("product_id", editFreeproduct.product_id);
    formData.append("free_pro", editFreeproduct.free_pro);
    formData.append("status", editFreeproduct.status || "0");
    formData.append("id", editFreeproduct.id || ""); // Default empty if no ID

    // Debugging: Log the FormData content
    for (const [key, value] of formData.entries()) {
      console.log(`${key}: ${value}`);
    }

    const url = "https://mahalakshmimobiles.com/admin/free_product_update.php";

    // Send the FormData using fetch
    fetch(url, {
      method: "POST",
      body: formData, // Directly send the FormData object
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok: " + response.statusText);
        }
        return response.json();
      })
      .then((data) => {
        if (data.success === "1") {
          swal({
            title: editFreeproduct.id
              ? "Product updated successfully!"
              : "Product added successfully!",
            icon: "success",
          });
          handleCloseModal();
          Dataget(); // Refresh data or update the UI
        } else {
          alert(data.message || "Something went wrong.");
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        alert("Failed to save the product. Please try again later.");
      });
  };



  const handleVariantInsert = async () => {
    try {
      if (!selectedProduct || !selectedSubCategory) {
        swal("Warning", "Please enter required fields", "warning");
        return;
      }

      const formData = new FormData();
      formData.append("cat_id", parentcategory);
      formData.append("sub_id", selectedSubCategory);
      formData.append("product_id", selectedProduct);
      formData.append("free_pro", selectedcategoryFree);
      formData.append("status", status);
      // if (selectedcategoryFree && selectedcategoryFree.length > 0) {
      //   const freeProducts = selectedcategoryFree.map(
      //     (product) => product.value
      //   );
      //   formData.append("free_pro", freeProducts.join(",")); // Send as comma-separated string
      // }

      const response = await fetch(
        "https://mahalakshmimobiles.com/admin/free_product.php",
        {
          method: "POST",
          body: formData,
        }
      );

      const responseData = await response.text();
      console.log("Response Data:", responseData); // Log the response data
      Dataget();
      setShowModal(false);
      setSelectedSubCategory("");
      setParentcategory("");
      setSelectedProduct("");
      setSelectedcategoryFree("");
      const data = JSON.parse(responseData);
      if (!response.ok) {
        throw new Error(
          `Failed to insert variant: ${response.status} - ${response.statusText}`
        );
      }
      // Handle success (e.g., show a success message or reset form)
    } catch (error) {
      console.error("Error:", error);
      // Handle error (e.g., show an error message)
    }
  };
  const [variant, setVariant] = useState([]);

  const [sortOrder, setSortOrder] = useState("asc");

  useEffect(() => {
    setVariant(freeproduct);
  }, [freeproduct]);
  const sortedProducts = [...filteredCapacitie].sort((a, b) => b.id - a.id);

  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  // Data for current page
  const currentPageData = filteredCapacitie.slice(startIndex, endIndex);
  const totalPages = Math.ceil(filteredCapacitie.length / itemsPerPage);
  const generatePaginationNumbers = () => {
    const paginationNumbers = [];
    const visiblePages = 3;
    const totalPages = Math.ceil(filteredCapacitie.length / itemsPerPage);

    // Adjust startPage and endPage based on currentPage
    let startPage = Math.max(currentPage - 1, 1);
    let endPage = Math.min(startPage + visiblePages - 1, totalPages);

    // Add leading "..." if startPage is greater than 2
    if (startPage > 2) {
      paginationNumbers.push(1);
      paginationNumbers.push("...");
    }

    // Push the range of page numbers
    for (let i = startPage; i <= endPage; i++) {
      paginationNumbers.push(i);
    }

    // Add trailing "..." if endPage is less than totalPages - 1
    if (endPage < totalPages) {
      paginationNumbers.push("...");
      paginationNumbers.push(totalPages);
    }

    return paginationNumbers;
  };
  const handlePerPageChange = (e) => {
    const newItemsPerPage = parseInt(e.target.value);
    const totalPages = Math.ceil(sortedProducts.length / newItemsPerPage);
    const newCurrentPage = Math.min(currentPage, totalPages);

    setItemsPerPage(newItemsPerPage);
    setCurrentPage(newCurrentPage);
  };

  const handleSortByColumn = (column) => {
    const sortedBrands = [...currentPageData].sort((a, b) => {
      const valueA = a[column] ? a[column].toString() : ""; // Convert to string if not undefined
      const valueB = b[column] ? b[column].toString() : ""; // Convert to string if not undefined

      if (sortOrder === "asc") {
        return valueA.localeCompare(valueB);
      } else {
        return valueB.localeCompare(valueA);
      }
    });

    setVariant(sortedBrands);
    setSortOrder(sortOrder === "asc" ? "desc" : "asc");
    setSortColumn(column);
  };
  useEffect(() => {
    const updatedFilteredCapacities = variant.filter((capacity) => {
      // Convert product_id to a string safely
      const promoCode = String(capacity.product_id || ""); // Convert undefined/null to an empty string
      return promoCode.toLowerCase().includes(searchQuery.toLowerCase());
    });
    setFilteredCapacities(updatedFilteredCapacities);
  }, [variant, searchQuery]);

  const handleProductSelect1 = (event) => {
    const selectedProductId = event.target.value;
    setSelectedcategoryFree(selectedProductId);

    // Find the product object with the selected pro_id
    const selectedProductDetails = products.find(
      (product) => product.pro_id === selectedProductId
    );

    if (selectedProductDetails) {
      // Set the product name based on the selected product
      setSelectedProductName(selectedProductDetails.product_name);
    } else {
      console.error("Product not found");
    }

    setSelectedcategoryFree(selectedProductId); // Setting the selected options
  };

  // const handleProductSelect = (selectedOptions) => {
  //   const productIds = selectedOptions.map((item) => item.value);
  //   const productNames = selectedOptions.map((item) => item.label);
  //   console.log({ selectedOptions: selectedOptions });
  //   console.log({ productNames: productNames }); // Logging product names
  //   setSelectedcategoryFree(selectedOptions); // Setting the selected options
  //   setCategoryid1(productIds); // Setting product IDs

  //   console.log({ productIds: productIds });
  // };
  return (
    <div className="main-container mt-3">
      <div className=" ">
        <div className="flex-container pt-5">
          <h3 className="head-fon-mv">Free Product</h3>
          <div className="col-12 col-md-6 order-md-2 order-first">
            <nav
              aria-label="breadcrumb"
              className="breadcrumb-header float-start float-lg-end"
            >
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <a onClick={() => navigate("/dashboard")}>Dashboard</a>
                </li>{" "}
                <li aria-current="page" className="breadcrumb-item active">
                  Free Product
                </li>
              </ol>
            </nav>
          </div>
        </div>

        <div className="card-cat ">
          <div className="cat-left"></div>
          <div className="d-flex justify-content-between align-items-center">
            <div className="row">
              <div className="d-flex align-items-center">
                <label className="me-2">Show</label>
                <select
                  style={{ height: "35px" }}
                  className="form-select m-0 "
                  value={itemsPerPage}
                  onChange={handlePerPageChange}
                >
                  <option value="20">20</option>
                  <option value="40">40</option>
                  <option value="60">60</option>
                  <option value="80">80</option>
                </select>
              </div>
            </div>
            <div
              className="d-flex align-items-center ms-auto"
              style={{ padding: "10px" }}
            >
              <div className="searchbar me-3">
                <input
                  type="text"
                  className="search"
                  placeholder="Search"
                  onChange={(e) => setSearchQuery(e.target.value)}
                />
                <FaSearch className="search-icon" />
              </div>
              <div style={{ marginLeft: "10px" }}>
                <button
                  className="add-category-button"
                  onClick={() => setShowModal(true)}
                >
                  Add Free Product
                </button>
              </div>
            </div>
          </div>
          <Modal show={showModal} onHide={handleCloseModal}>
            <header class="modal-header">
              <h5 class="modal-title">View Free Product</h5>
              <button
                type="button"
                aria-label="Close"
                class="close"
                onClick={handleCloseModal}
              >
                ×
              </button>
            </header>

            <Modal.Body>
              <label className="bold">
                Category <i className="text-danger ">*</i>
              </label>
              <select
                className="form-select"
                placeholder="Select Head Category"
                value={parentcategory}
                onChange={(e) => handleCategoryChange(e)}
              >
                <option value="">Select Category</option>
                {categoriesload.map((category) => (
                  <option key={category.id} value={category.id}>
                    {category.category_name}
                  </option>
                ))}
              </select>
              <label className="bold">
                Sub Category <i className="text-danger ">*</i>
              </label>
              <select
                className="form-select"
                onChange={handleSubCategoryChange}
              >
                <option value="">Select Sub Category</option>
                {subCategories &&
                  subCategories.map((subCategory) => (
                    <option key={subCategory.id} value={subCategory.id}>
                      {subCategory.category_name}
                    </option>
                  ))}
              </select>
              <label className="bold">
                Product <i className="text-danger ">*</i>
              </label>
              <select
                className="form-select"
                value={selectedProduct}
                onChange={handleSelectChange}
              >
                <option value="">Select Product</option>
                {products &&
                  products.map((product) => (
                    product.pro_name && product.pro_name.trim() !== "" && (
                      <option key={product.pro_id} value={product.pro_id}>
                        {product.pro_name}
                      </option>
                    )
                  ))}

              </select>
              <label className="bold">
                Free Product <i className="text-danger ">*</i>
              </label>
              <select
                className="form-select"
                value={selectedcategoryFree}
                // onChange={handleProductSelect}
                onChange={handleProductSelect1}
              >
                <option value="">Select Product</option>
                {freepro &&
                  freepro.map((product) => (
                    <option key={product.id} value={product.id}>
                      {product.product_name}
                    </option>
                  ))}
              </select>

              {/* <Select
                id="categorySelect"
                className="form-control"
                placeholder="Select Free Product"
                isMulti
                options={products.map((option) => ({
                  value: option.pro_id, // Ensure option.id is valid
                  label: option.pro_name,
                }))}
                value={selectedcategoryFree}
                onChange={handleProductSelect}
              /> */}

              <label className="bold">
                Status <i className="text-danger ">*</i>
              </label>
              <div className="form-switch formswitch d-flex align-items-center">
                <input
                  className={`form-check-input custom-switch w-100 ${status === "1" ? "bg-success" : "bg-danger"
                    }`}
                  type="checkbox"
                  id="flexSwitchCheckDefault"
                  checked={status === "1"}
                  onChange={(e) => {
                    const newValue = e.target.checked ? "1" : "0";
                    setStatus(newValue);
                    console.log(`Checkbox value: ${newValue}`);
                  }}
                />
                <label
                  htmlFor="flexSwitchCheckDefault"
                  className="status-label ms-2 mb-0"
                >
                  {status === "1" ? (
                    <span className="text-success w-20">Active</span>
                  ) : (
                    <span className="text-danger ">In-Active</span>
                  )}
                </label>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="success" onClick={handleVariantInsert}>
                Save{" "}
              </Button>
              <Button variant="secondary" onClick={handleCloseModal}>
                Cancel
              </Button>
            </Modal.Footer>
          </Modal>
          <Modal show={EditshowModal} onHide={handleCloseModal}>
            <header className="modal-header">
              <h5 className="modal-title">
                {editFreeproduct ? "Edit Free Product" : "Add Free Product"}
              </h5>
              <button
                type="button"
                aria-label="Close"
                className="close"
                onClick={handleCloseModal}
              >
                ×
              </button>
            </header>

            <Modal.Body>
              <label className="bold">
                Category <i className="text-danger">*</i>
              </label>
              <select
                className="form-select"
                value={editFreeproduct?.cat_id || ""}
                onChange={(e) => handleCategoryChangeupdate(e.target.value)}
              >
                <option value="">Select Category</option>
                {categoriesload.map((category) => (
                  <option key={category.id} value={category.id}>
                    {category.category_name}
                  </option>
                ))}
              </select>

              <label className="bold">
                Sub Category <i className="text-danger">*</i>
              </label>
              <select
                className="form-select"
                value={editFreeproduct?.sub_id || ""}
                onChange={(e) => handleSubCategoryChangeupdate(e.target.value)}
              >
                <option value="">Select Sub Category</option>
                {subCategories &&
                  subCategories.map((subCategory) => (
                    <option key={subCategory.id} value={subCategory.id}>
                      {subCategory.category_name}
                    </option>
                  ))}
              </select>

              <label className="bold">
                Product <i className="text-danger">*</i>
              </label>
              <select
                className="form-select"
                value={editFreeproduct?.product_id || ""}
                onChange={(e) => handleSelectChangeupdate(e.target.value)}
              >
                <option value="">Select Product</option>
                {products &&
                  products.map((product) => (
                    <option key={product.pro_id} value={product.pro_id}>
                      {product.pro_name}
                    </option>
                  ))}
              </select>

              <label className="bold">
                Free Product <i className="text-danger">*</i>
              </label>
              <select
                className="form-select"
                value={editFreeproduct.free_pro || ""} // Ensure the same property is used
                onChange={(e) => handleFreeProductChange(e.target.value)}
              >
                <option value="">Select Product</option>
                {freepro &&
                  freepro.map((product) => (
                    <option key={product.id} value={product.id}>
                      {product.product_name}
                    </option>
                  ))}
              </select>

              <label className="bold">
                Status <i className="text-danger">*</i>
              </label>
              <div className="form-switch formswitch d-flex align-items-center">
                <input
                  className={`form-check-input custom-switch w-100 ${editFreeproduct?.status === "1" ? "bg-success" : "bg-danger"
                    }`}
                  type="checkbox"
                  id="flexSwitchCheckDefault"
                  checked={editFreeproduct?.status === "1"}
                  onChange={(e) => {
                    const newValue = e.target.checked ? "1" : "0";
                    setEditFreeproduct((prevProduct) => ({
                      ...prevProduct,
                      status: newValue,
                    }));
                    setStatus(newValue);
                  }}
                />
                <label
                  htmlFor="flexSwitchCheckDefault"
                  className="status-label ms-2 mb-0"
                >
                  {editFreeproduct?.status === "1" ? (
                    <span className="text-success w-20">Active</span>
                  ) : (
                    <span className="text-danger">In-Active</span>
                  )}
                </label>
              </div>
            </Modal.Body>

            <Modal.Footer>
              <Button variant="success" onClick={handleUpdate}>
                {editFreeproduct ? "Update" : "Save"}
              </Button>
              <Button variant="secondary" onClick={handleCloseModal}>
                Cancel
              </Button>
            </Modal.Footer>
          </Modal>

          <div className="table-container text-end ">
            {loading ? (
              <div>
                <Lottie
                  height={250}
                  width={250}
                  options={{
                    loop: true,
                    animationData: require("../Assets/Animation - 1716197548567.json"),
                    autoplay: true,
                  }}
                />
              </div>
            ) : (
              <table id="table-to-xls" className="text-center">
                <thead>
                  <tr>
                    <th style={{ width: "4%" }}>S.No</th>
                    {/* <th style={{ cursor: "pointer", textAlign: "center" }}>
                      Category
                    </th> */}
                    <th
                      onClick={() => handleSortByColumn("cat_id")}
                      style={{ cursor: "pointer", textAlign: "center" }}
                    >
                      Category
                      {sortColumn === "cat_id" &&
                        (sortOrder === "asc" ? (
                          <FontAwesomeIcon
                            icon={faSortUp}
                            style={{ marginLeft: "8px" }}
                          />
                        ) : (
                          <FontAwesomeIcon
                            icon={faSortDown}
                            style={{ marginLeft: "8px" }}
                          />
                        ))}
                    </th>
                    {/* <th style={{ cursor: "pointer", textAlign: "center" }}>
                      Sub Category
                    </th> */}
                    <th
                      onClick={() => handleSortByColumn("sub_id")}
                      style={{ cursor: "pointer", textAlign: "center" }}
                    >
                      Sub Category
                      {sortColumn === "sub_id" &&
                        (sortOrder === "asc" ? (
                          <FontAwesomeIcon
                            icon={faSortUp}
                            style={{ marginLeft: "8px" }}
                          />
                        ) : (
                          <FontAwesomeIcon
                            icon={faSortDown}
                            style={{ marginLeft: "8px" }}
                          />
                        ))}
                    </th>
                    {/* <th style={{ cursor: "pointer", textAlign: "center" }}>
                      Product
                    </th> */}
                    <th
                      onClick={() => handleSortByColumn("product_id")}
                      style={{ cursor: "pointer", textAlign: "center" }}
                    >
                      Product
                      {sortColumn === "product_id" &&
                        (sortOrder === "asc" ? (
                          <FontAwesomeIcon
                            icon={faSortUp}
                            style={{ marginLeft: "8px" }}
                          />
                        ) : (
                          <FontAwesomeIcon
                            icon={faSortDown}
                            style={{ marginLeft: "8px" }}
                          />
                        ))}
                    </th>
                    {/* <th style={{ cursor: "pointer", textAlign: "center" }}>
                      Free Product
                    </th> */}{" "}
                    <th
                      onClick={() => handleSortByColumn("free_pro")}
                      style={{ cursor: "pointer", textAlign: "center" }}
                    >
                      Free Product
                      {sortColumn === "free_pro" &&
                        (sortOrder === "asc" ? (
                          <FontAwesomeIcon
                            icon={faSortUp}
                            style={{ marginLeft: "8px" }}
                          />
                        ) : (
                          <FontAwesomeIcon
                            icon={faSortDown}
                            style={{ marginLeft: "8px" }}
                          />
                        ))}
                    </th>
                    <th style={{ textAlign: "center" }}>Status</th>
                    <th style={{ width: "4%" }}>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {currentPageData.length > 0 ? (
                    currentPageData.map((item, index) => (
                      <tr key={index}>
                        <td style={{ textAlign: "center" }}>{index + 1}</td>
                        <td style={{ textAlign: "center" }}>
                          {item.category_name}
                        </td>
                        <td style={{ textAlign: "center" }}>
                          {item.subcategory_name}
                        </td>
                        <td style={{ textAlign: "center" }}>
                          {item.product_name}
                        </td>
                        {/* <td style={{ textAlign: "center" }}>
                          {" "}
                          <div className="variant-images">
                            {variant.variant_image &&
                              JSON.parse(variant.variant_image).map(
                                (image, imgIndex) => (
                                  <img
                                    key={imgIndex}
                                    src={`https://mahalakshmimobiles.com/admin/image/variant/${image}`}
                                    alt={`Variant ${imgIndex + 1}`}
                                    className="variant-image"
                                  />
                                )
                              )}
                          </div>
                        </td> */}

                        <td style={{ textAlign: "center" }}>
                          {item.free_product}
                        </td>
                        <td style={{ textAlign: "center" }}>
                          {item.status === "1" ? (
                            <span className="active-item">Active</span>
                          ) : (
                            <span className="inactive-item">In-Active</span>
                          )}
                        </td>
                        <td style={{ textAlign: "center" }}>
                          <button className="btn-edit">
                            <FontAwesomeIcon
                              icon={faPenToSquare}
                              onClick={() => handelEdit(item.id)}
                            />
                          </button>

                          <button
                            className="btn-delete"
                            onClick={() => handleDelete(item.id)}
                          >
                            <FaTrash />
                          </button>
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan="5" style={{ textAlign: "center" }}>
                        No records found
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            )}
          </div>
        </div>
        <div
          className="dataTables_paginate paging_simple_numbers p-2"
          id="dtBasicExample_paginate"
        >
          <ul className="pagination">
            <li
              className={`paginate_button page-item ${currentPage === 1 ? "disabled" : ""
                }`}
            >
              <button
                onClick={() =>
                  setCurrentPage((prevPage) =>
                    prevPage > 1 ? prevPage - 1 : prevPage
                  )
                }
                className="page-link"
              >
                Previous
              </button>
            </li>

            {generatePaginationNumbers().map((page, index) => (
              <li
                key={index}
                className={`paginate_button page-item ${page === currentPage ? "active" : ""
                  }`}
              >
                {typeof page === "number" ? (
                  <button
                    onClick={() => setCurrentPage(page)}
                    className="page-link"
                  >
                    {page}
                  </button>
                ) : (
                  <span className="page-link">...</span>
                )}
              </li>
            ))}

            <li
              className={`paginate_button page-item ${currentPage === totalPages ? "disabled" : ""
                }`}
            >
              <button
                onClick={() =>
                  setCurrentPage((prevPage) =>
                    prevPage < totalPages ? prevPage + 1 : prevPage
                  )
                }
                className="page-link"
              >
                Next
              </button>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}

export default FreeProduct;
