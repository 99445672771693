import React, { useState, useRef, useEffect } from "react";
import Lottie from "react-lottie";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { FaSearch, FaTrash } from "react-icons/fa";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPenToSquare } from "@fortawesome/free-regular-svg-icons";
import { faSortUp, faSortDown } from "@fortawesome/free-solid-svg-icons";
import swal from "sweetalert";
import Select from "react-select";
function SupportedInternet() {
    const [showModal, setShowModal] = useState(false);
    const [searchQuery, setSearchQuery] = useState("");
    const [shortbyView, setShortbyView] = useState("");
    const [loading, setLoading] = useState(false);
    const [shortby, setSimcardSlotcount] = useState([]);
    const [newShortby, setNewShortby] = useState("");
    const [itemsPerPage, setItemsPerPage] = useState(20);
    const [currentPage, setCurrentPage] = useState(1);
    const [editShortby, setEditShortby] = useState({
        id: "",
        name: "",
        category_id: "",
    });
    const [categoryid1, setCategoryid1] = useState("");
    const [selectedcategory, setSelectedcategory] = useState([]);
    console.log({ selectedcategory: selectedcategory });
    const [categoryid, setCategoryid] = useState("");
    const [category, setCategory] = useState([]);
    console.log({ category: category });
    console.log({ editShortby: editShortby.short_by });
    const [editShowModal, setEditShowModal] = useState(false);
    const shortbyNameInputRef = useRef(null);
    const handleShowModal = () => setShowModal(true);
    const handleCloseModal = () => {
        setShowModal(false);
        setEditShowModal(false);
        setNewShortby("");
        setCategoryid([]); // Clear category id field
        setSelectedcategory([]); // Clear selected category field

    };

    const getData = async () => {
        try {
            const response = await fetch(
                "https://mahalakshmimobiles.com/admin/internet_support.php"
            );
            const data = await response.json();
            setSimcardSlotcount(data);
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };

    useEffect(() => {
        setLoading(true);
        getData()
            .then(() => setLoading(false))
            .catch(() => setLoading(false));
    }, []);

    useEffect(() => {
        if (showModal) {
            shortbyNameInputRef.current.focus();
        }
        fetchCategories();
    }, [showModal]);

    const handleProductSelect = (selectedOptions) => {
        const productIds = selectedOptions.map((item) => item.value);
        const productvalue = selectedOptions.map((item) => item.label);
        console.log({ productIds: productvalue });
        setSelectedcategory(selectedOptions);
        setCategoryid(productvalue);
        setCategoryid1(productIds);

        console.log({ object: productIds });
    };

    const getCategoryLabels = (category_id) => {
        const categoryIds =
            typeof category_id === "string" ? category_id.split(",") : [category_id];
        return category
            .filter((item) => categoryIds.includes(item.value.toString()))
            .map((filteredItem) => filteredItem.label)
            .join(", ");
    };

    const getCategoryLabels1 = (categoryValue) => {
        if (!categoryValue) return [];

        const categoryIds =
            typeof categoryValue === "string"
                ? categoryValue.split(",")
                : categoryValue;
        return category.filter((item) =>
            categoryIds.includes(item.value.toString())
        );
    };
    const handleProductSelect1 = (selectedOptions) => {
        setEditShortby((prev) => ({
            ...prev,
            category_id: selectedOptions.map((option) => option.value).join(","),
        }));
    };

    const fetchCategories = () => {
        return fetch("https://mahalakshmimobiles.com/admin/categoriesmaster.php")
            .then((response) => response.json())
            .then((data) => {
                const options = data.map((category) => ({
                    value: category.id,
                    label: category.category_name,
                }));
                setCategory(options);
            })
            .catch((error) => console.error("Error fetching categories:", error));
    };
    const handleInsert = async () => {
        if (!newShortby.trim()) {
            swal("warning", "Please enter Short By", "warning");
            return;
        }
        if (categoryid1.length === 0) {
            swal("Warning", "Please select at least one category", "warning");
            return;
        }
        const formData = new FormData();
        formData.append("name", newShortby);
        formData.append("category_id", categoryid1);
        try {
            const response = await fetch(
                "https://mahalakshmimobiles.com/admin/internet_support.php",
                {
                    method: "POST",
                    body: formData,
                }
            );

            if (response.ok) {
                getData(); // Refresh the data
                handleCloseModal(); // Close the modal
                setNewShortby("");
                setCategoryid1([]); // Clear category id field
                setSelectedcategory([]); // Clear selected category field
            } else {
                console.error("Failed to insert data");
            }
        } catch (error) {
            console.error("Error inserting data:", error);
        }
    };

    useEffect(() => {
        const roleget = async () => {
            const role = await localStorage.getItem("role");
            console.log({ role111: role });
            await fetchPermissions(role);
        };
        roleget();
    }, []);

    const fetchPermissions = async (role) => {
        console.log({
            role: `https://mahalakshmimobiles.com/admin/roleassign.php?id=${role}`,
        });
        try {
            const response = await fetch(
                `https://mahalakshmimobiles.com/admin/roleassign.php?id=${role}`,
                {
                    method: "GET",
                    headers: {
                        "Content-Type": "application/json",
                    },
                }
            );

            if (!response.ok) {
                throw new Error("Network response was not ok");
            }

            const data = await response.json();
            setShortbyView(data.permission.ShortView);
            // Process the fetched data
        } catch (error) {
            console.error("Error fetching permissions:", error.message);
        }
    };

    const handleEdit = (id) => {
        const componentToEdit = shortby.find((item) => item.id === id);
        setEditShortby(componentToEdit);
        setEditShowModal(true);
    };
    useEffect(() => {
        setCurrentPage(1);
    }, [searchQuery]);

    const handleUpdate = async () => {
        try {
            if (!editShortby.name.trim()) {
                swal("warning", "Please enter Short By", "warning");
                return;
            }
            if (editShortby.category_id.length === 0) {
                swal("Warning", "Please select at least one category", "warning");
                return;
            }
            const response = await fetch(
                `https://mahalakshmimobiles.com/admin/internet_support.php?id=${editShortby.id}&&name=${editShortby.name}&&category_id=${editShortby.category_id}&&type=UPDATE`,
                {
                    headers: {
                        "Content-Type": "application/json",
                    }
                }
            );

            if (response.ok) {
                getData(); // Refresh the data after updating
                setEditShowModal(false); // Close the edit modal
            } else {
                console.error("Failed to update short_by");
            }
        } catch (error) {
            console.error("Error updating data:", error);
        }
    };

    const handleDelete = async (id) => {
        try {
            const shouldDelete = await swal({
                title: "Are you sure?",
                text: "Once deleted, you will not be able to recover this shortby!",
                icon: "warning",
                buttons: ["Cancel", "Delete"],
                dangerMode: true,
            });

            if (shouldDelete) {
                const response = await fetch(
                    `https://mahalakshmimobiles.com/admin/internet_support.php?id=${id}&&type=DELETE`,
                    {
                        method: "DELETE",
                    }
                );

                if (response.status === 200) {
                    getData(); // Refresh the data after deleting
                } else {
                    console.error("Failed to delete variant");
                }
            }
        } catch (error) {
            console.error("Error deleting data:", error);
        }
    };

    const handlePerPageChange = (e) => {
        const newItemsPerPage = parseInt(e.target.value);
        const totalPages = Math.ceil(sortedFilter.length / newItemsPerPage);

        const newCurrentPage = Math.min(currentPage, totalPages);

        setItemsPerPage(newItemsPerPage);
        setCurrentPage(newCurrentPage);
    };

    const filteredCapacities = shortby.filter((capacity) =>
        capacity.name.toLowerCase().includes(searchQuery.toLowerCase())
    );

    const sortedFilter = [...filteredCapacities].sort((a, b) => b.id - a.id);

    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;

    const currentPageData = sortedFilter.slice(startIndex, endIndex);

    const totalPages = Math.ceil(sortedFilter.length / itemsPerPage);

    const visiblePages = 3;
    const generatePaginationNumbers = () => {
        const paginationNumbers = [];
        let startPage = Math.max(currentPage - 1, 1);
        let endPage = Math.min(startPage + visiblePages - 1, totalPages);

        if (currentPage > Math.ceil(visiblePages / 2)) {
            paginationNumbers.push("...");
        }

        for (let i = startPage; i <= endPage; i++) {
            paginationNumbers.push(i);
        }

        if (endPage < totalPages - 1) {
            paginationNumbers.push("...");
            paginationNumbers.push(totalPages);
        }

        return paginationNumbers;
    };
    const [sortColumn, setSortColumn] = useState("");
    const [variant, setVariant] = useState([]);

    const handleSortByColumn = (column) => {
        const sortedBrands = [...currentPageData].sort((a, b) => {
            const valueA = a[column] ? a[column].toString() : ""; // Convert to string if not undefined
            const valueB = b[column] ? b[column].toString() : ""; // Convert to string if not undefined

            if (sortOrder === "asc") {
                return valueA.localeCompare(valueB);
            } else {
                return valueB.localeCompare(valueA);
            }
        });

        setVariant(sortedBrands);
        setSortOrder(sortOrder === "asc" ? "desc" : "asc");
        setSortColumn(column);
    };

    const [sortOrder, setSortOrder] = useState("asc");

    useEffect(() => {
        setVariant(shortby);
    }, [shortby]);

    const [filteredCapacitie, setFilteredCapacities] = useState([]);
    useEffect(() => {
        const updatedFilteredCapacities = variant.filter((capacity) =>
            capacity.name.toLowerCase().includes(searchQuery.toLowerCase())
        );
        setFilteredCapacities(updatedFilteredCapacities);
    }, [variant, searchQuery]);
    return (
        <div className="main-container mt-3">
            <div className="flex-container pt-5">
                <h3 className="head-fon-mv"> Supported Internet By</h3>
                <div className="col-12 col-md-6 order-md-2 order-first">
                    <nav
                        aria-label="breadcrumb"
                        className="breadcrumb-header float-start float-lg-end"
                    >
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item">
                                <a href="/dashboard">Dashboard</a>
                            </li>{" "}
                            <li aria-current="page" className="breadcrumb-item active">
                                Supported Internet By
                            </li>
                        </ol>
                    </nav>
                </div>
            </div>

            <div className="card-cat">
                <div className="cat-left"></div>

                <div className="d-flex justify-content-between align-items-center">
                    <div className="row">
                        <div className="d-flex align-items-center">
                            <label className="me-2">Show</label>
                            <select
                                style={{ height: "35px" }}
                                className="form-select m-0 "
                                value={itemsPerPage}
                                onChange={handlePerPageChange}
                            >
                                <option value="20">20</option>
                                <option value="40">40</option>
                                <option value="60">60</option>
                                <option value="80">80</option>
                            </select>
                            {/* <label>Entries</label> */}
                        </div>
                    </div>
                    {/* <div className="search-left">
            <div className="searchbar">
              <input
                type="text"
                className="search"
                placeholder="Search"
                onChange={(e) => setSearchQuery(e.target.value)}
              />
              <FaSearch className="search-icon" />
            </div>
          </div>
          {shortbyView.add === true ? (
            <button className="add-category-button" onClick={handleShowModal}>
              Add Short By
            </button>
          ) : (
            ""
          )} */}

                    <div
                        className="d-flex align-items-center ms-auto"
                        style={{ padding: "10px" }}
                    >
                        <div className="searchbar me-3">
                            <input
                                type="text"
                                className="search"
                                placeholder="Search"
                                onChange={(e) => setSearchQuery(e.target.value)}
                            />
                            <FaSearch className="search-icon" />
                        </div>

                        <div style={{ padding: "10px", marginBottom: "6px" }}>
                            {shortbyView.add === true ? (
                                <button
                                    className="add-category-button"
                                    onClick={handleShowModal}
                                >
                                    Add Supported Internet
                                </button>
                            ) : (
                                ""
                            )}
                        </div>
                    </div>
                </div>

                <div className="table-container text-end mt-3">
                    {loading ? (
                        <div>
                            <Lottie
                                height={250}
                                width={250}
                                options={{
                                    loop: true,
                                    animationData: require("../Assets/Animation - 1716197548567.json"),
                                    autoplay: true,
                                }}
                            />
                        </div>
                    ) : (
                        <div className="table-container text-end mt-3">
                            <table id="table-to-xls" className="text-center">
                                <thead>
                                    <tr>
                                        <th style={{ textAlign: "center" }}>S.NO</th>
                                        {/* <th style={{ textAlign: "center" }}>Short By</th>
                    <th style={{ textAlign: "center" }}>Category Name</th> */}
                                        <th
                                            onClick={() => handleSortByColumn("name")}
                                            style={{ cursor: "pointer", textAlign: "center" }}
                                        >
                                            Internet Name
                                            {sortColumn === "name" &&
                                                (sortOrder === "asc" ? (
                                                    <FontAwesomeIcon
                                                        icon={faSortUp}
                                                        style={{ marginLeft: "8px" }}
                                                    />
                                                ) : (
                                                    <FontAwesomeIcon
                                                        icon={faSortDown}
                                                        style={{ marginLeft: "8px" }}
                                                    />
                                                ))}
                                        </th>
                                        <th
                                            onClick={() => handleSortByColumn("category_id")}
                                            style={{ cursor: "pointer", textAlign: "center" }}
                                        >
                                            Category Name
                                            {sortColumn === "category_id" &&
                                                (sortOrder === "asc" ? (
                                                    <FontAwesomeIcon
                                                        icon={faSortUp}
                                                        style={{ marginLeft: "8px" }}
                                                    />
                                                ) : (
                                                    <FontAwesomeIcon
                                                        icon={faSortDown}
                                                        style={{ marginLeft: "8px" }}
                                                    />
                                                ))}
                                        </th>
                                        {shortbyView.edit === true ||
                                            shortbyView.delete === true ? (
                                            <th style={{ textAlign: "center" }}>Action</th>
                                        ) : (
                                            ""
                                        )}
                                    </tr>
                                </thead>
                                <tbody>
                                    {filteredCapacitie.length > 0 ? (
                                        filteredCapacitie.map((capacity, index) => (
                                            <tr key={capacity.id}>
                                                <td style={{ textAlign: "center" }}>
                                                    {startIndex + index + 1}
                                                </td>
                                                <td style={{ textAlign: "center" }}>
                                                    {capacity.name}
                                                </td>
                                                <td style={{ textAlign: "center" }}>
                                                    {getCategoryLabels(capacity.category_id)}
                                                </td>
                                                {(shortbyView.edit || shortbyView.delete) && (
                                                    <td style={{ textAlign: "center" }}>
                                                        {shortbyView.edit && (
                                                            <button
                                                                className="btn-edit"
                                                                onClick={() => handleEdit(capacity.id)}
                                                            >
                                                                <FontAwesomeIcon icon={faPenToSquare} />
                                                            </button>
                                                        )}
                                                        {shortbyView.delete && (
                                                            <button
                                                                className="btn-delete"
                                                                onClick={() => handleDelete(capacity.id)}
                                                            >
                                                                <FaTrash />
                                                            </button>
                                                        )}
                                                    </td>
                                                )}
                                            </tr>
                                        ))
                                    ) : (
                                        <tr>
                                            <td colSpan="4" style={{ textAlign: "center" }}>
                                                No records found
                                            </td>
                                        </tr>
                                    )}
                                </tbody>
                            </table>
                        </div>
                    )}
                </div>

                <Modal show={showModal} onHide={handleCloseModal}>
                    <header className="modal-header">
                        <h5 className="modal-title">Add Supported Internet By</h5>
                        <button
                            type="button"
                            aria-label="Close"
                            className="close"
                            onClick={handleCloseModal}
                        >
                            <span aria-hidden="true">×</span>
                        </button>
                    </header>
                    <Modal.Body>
                        <div className="row">
                            <div className="col-12">
                                <label className="bold">
                                    Internet Name  <i className="text-danger">*</i>
                                </label>
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Enter Short By"
                                    value={newShortby}
                                    ref={shortbyNameInputRef}
                                    onChange={(e) => setNewShortby(e.target.value)}
                                />
                                <label className="bold">
                                    Category <i className="text-danger">*</i>
                                </label>
                                <Select
                                    id="categorySelect"
                                    className="form-control"
                                    placeholder="Select Category "
                                    options={category}
                                    value={selectedcategory}
                                    isMulti
                                    onChange={handleProductSelect}
                                />
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="success" onClick={handleInsert}>
                            Save
                        </Button>
                        <Button variant="secondary" onClick={handleCloseModal}>
                            Cancel
                        </Button>
                    </Modal.Footer>
                </Modal>

                <Modal show={editShowModal} onHide={handleCloseModal}>
                    <header className="modal-header">
                        <h5 className="modal-title">Edit Supported Internet By</h5>
                        <button
                            type="button"
                            aria-label="Close"
                            className="close"
                            onClick={handleCloseModal}
                        >
                            <span aria-hidden="true">×</span>
                        </button>
                    </header>
                    <Modal.Body>
                        <div className="row">
                            <div className="col-12">
                                <label>
                                    Supported Internet <i className="text-danger">*</i>
                                </label>
                                <input
                                    type="text"
                                    className="form-control"
                                    value={editShortby.name}
                                    onChange={(e) =>
                                        setEditShortby({
                                            ...editShortby,
                                            name: e.target.value, // Corrected variable name here
                                        })
                                    }
                                />
                                <label className="bold">
                                    Category <i className="text-danger">*</i>
                                </label>
                                <Select
                                    id="categorySelect"
                                    className="form-control"
                                    placeholder="Select Products"
                                    options={category}
                                    value={getCategoryLabels1(editShortby.category_id)}
                                    isMulti
                                    onChange={handleProductSelect1}
                                />
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="success" onClick={handleUpdate}>
                            Update
                        </Button>
                        <Button variant="secondary" onClick={() => setEditShowModal(false)}>
                            Cancel
                        </Button>
                    </Modal.Footer>
                </Modal>
            </div>
            <div
                className="dataTables_paginate paging_simple_numbers p-2"
                id="dtBasicExample_paginate"
            >
                <ul className="pagination">
                    <li
                        className={`paginate_button page-item ${currentPage === 1 ? "disabled" : ""
                            }`}
                    >
                        <button
                            onClick={() =>
                                setCurrentPage((prevPage) =>
                                    prevPage > 1 ? prevPage - 1 : prevPage
                                )
                            }
                            className="page-link"
                        >
                            Previous
                        </button>
                    </li>
                    {generatePaginationNumbers().map((page, index) => (
                        <li
                            key={index}
                            className={`paginate_button page-item ${page === currentPage ? "active" : ""
                                }`}
                        >
                            <button
                                onClick={() => setCurrentPage(page)}
                                className="page-link"
                            >
                                {page}
                            </button>
                        </li>
                    ))}
                    <li
                        className={`paginate_button page-item ${currentPage === totalPages ? "disabled" : ""
                            }`}
                    >
                        <button
                            onClick={() =>
                                setCurrentPage((prevPage) =>
                                    prevPage < totalPages ? prevPage + 1 : prevPage
                                )
                            }
                            className="page-link"
                        >
                            Next
                        </button>
                    </li>
                </ul>
            </div>
        </div>
    );
}

export default SupportedInternet;

