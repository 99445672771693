// import axios from "axios";
// import { FaEdit, FaEye } from "react-icons/fa";
// import React, { useEffect, useState } from "react";
// import "../Service/ServicePage.css"
// import { Form } from 'react-bootstrap';
// import swal from "sweetalert";
// function ServicePage() {
//   const [data, setData] = useState([]);
//   const [showTable, setShowTable] = useState(false);
//   const [dataView, setDataView] = useState({});
//   const [isModalOpen, setIsModalOpen] = useState(false);

//   const getService = async () => {
//     try {
//       const response = await axios.get(
//         "https://mahalakshmimobiles.com/admin/admin_get_service.php"
//       );
//       setData(response.data.service);
//     } catch (error) {
//       console.error("Error fetching service data:", error);
//     }
//   };

//   useEffect(() => {
//     getService();
//   }, []);

//   const handleUpdateServiceStatus = async (id, status) => {
//     try {
//       const response = await axios.post(
//         `https://mahalakshmimobiles.com/admin/admin_update_service.php?id=${id}&&status=${status}`

//       );
//       if (response.data.success) {
//         swal({
//           title: "Success!",
//           text: response.data.message,
//           icon: "success",
//           button: {
//             text: "OK",
//             className: "swal-button--success", // Optional custom class for the button
//           },
//           timer: 3000, // Auto-close the alert after 3 seconds
//         });

//         getService(); // Refresh the service data
//         closeModal();
//       } else {
//         swal({
//           title: "Failed!",
//           text: response.data.message || "Failed to update service status.",
//           icon: "warning",
//           button: {
//             text: "OK",
//             className: "swal-button--warning",
//           },
//         });
//       }
//     } catch (error) {
//       swal({
//         title: "Error!",
//         text: error | "An unexpected error occurred.",
//         icon: "error",
//         button: {
//           text: "OK",
//           className: "swal-button--error",
//         },
//       });
//     }
//   };


//   const handleView = async (id) => {
//     try {
//       const response = await axios.get(
//         `https://mahalakshmimobiles.com/admin/admin_get_service.php?id=${id}`
//       );
//       const datares = response.data;
//       setDataView(datares.service);
//       setIsModalOpen(true);
//     } catch (error) {
//       console.error("Error fetching service details:", error);
//     }
//   };

//   const closeModal = () => {
//     setIsModalOpen(false);
//     setDataView({});
//   };


//   return (
//     <div className="main-container mt-3">
//       <div className="flex-container pt-5">
//         <div className="col-12 col-md-6 order-md-2 order-first">
//           <nav
//             aria-label="breadcrumb"
//             className="breadcrumb-header float-start float-lg-end"
//           >
//             <ol className="breadcrumb">
//               <li className="breadcrumb-item">
//                 <a href="/dashboard">Dashboard</a>
//               </li>
//               <li aria-current="page" className="breadcrumb-item active">
//                 Service Page
//               </li>
//             </ol>
//           </nav>
//         </div>
       
//       </div>
//       <div className="table-container mt-4">
//         <h4>Service Details</h4>
//         <table className="table table-bordered">
//           <thead>
//             <tr>
//               <th>ID</th>
//               <th>Complaint</th>
//               <th>IMEI Number</th>
//               <th>Mobile Number</th>
//               <th>Model Name</th>
//               <th>Description</th>
//               <th>Action</th>
//             </tr>
//           </thead>
//           <tbody>
//             {data.map((service, index) => (
//               <tr key={index}>
//                 <td>{service.id}</td>
//                 <td>{service.complaint}</td>
//                 <td>{service.imei_number}</td>
//                 <td>{service.mobile_number}</td>
//                 <td>{service.modal_name}</td>
//                 {service.description ? <td>{service.description}</td>:<td>-</td>}
               
//                 <td>
//                   <button
//                     className="btn-delete"
//                     onClick={() => handleView(service.id)}
//                   >
//                     <FaEye />
//                   </button>
//                 </td>
//               </tr>
//             ))}
//           </tbody>
//         </table>
//       </div>


//       {/* Modal */}
//       {isModalOpen && (
//         <div className="modal-container">
//           <div className="modal-content">
//             <header className="modal-header">
//               <h2 style={{ color: 'black', fontSize: 30, textAlign: 'center' }} >Service Details</h2>
//               <button
//                 type="button"
//                 aria-label="Close"
//                 className="close"
//                 onClick={closeModal} 
//               >
//                 &times;
//               </button>
//             </header>
//             <div className="modal-body">
//               <p>
//                 <strong>Complaint:</strong>
//                 <span className="value">{dataView.complaint}</span>
//               </p>
//               <p>
//                 <strong>IMEI Number:</strong>
//                 <span className="value">{dataView.imei_number}</span>
//               </p>
//               <p>
//                 <strong>Mobile Number:</strong>
//                 <span className="value">{dataView.mobile_number}</span>
//               </p>
//               <p>
//                 <strong>Model Name:</strong>
//                 <span className="value">{dataView.modal_name}</span>
//               </p>
//               {
//                 dataView.description&&
//                 <p>
//                 <strong>Description:</strong>
//                 <span className="value">{dataView.description}</span>
//               </p>
//               }
              
//               <p>
//                 <strong>Service Status:</strong>
//                 <Form.Select
//                   aria-label="Service Status Select"
//                   value={dataView.service_status}
//                   onChange={(e) =>
//                     setDataView({ ...dataView, service_status: e.target.value })
//                   }
//                 >
//                   <option value="0">Success</option>
//                   <option value="1">Active</option>
//                   <option value="2">Reject</option>
//                 </Form.Select>
//               </p>
//               <p>
//                 <strong>Placed Date:</strong>
//                 <span className="value">{dataView.placed_date}</span>
//               </p>
//             </div>

//             <footer className="modal-footer">
//               <button
//                 onClick={() => handleUpdateServiceStatus(dataView.id, dataView.service_status)}
//                 className="btn btn-primary"
//               >
//                 Update
//               </button>
//               <button
//                 onClick={closeModal}
//                 className="btn btn-secondary"
//               >
//                 Close
//               </button>
//             </footer>
//           </div>
//         </div>
//       )}
//     </div>
//   );
// }

// export default ServicePage;
import axios from "axios";
import { FaEdit, FaEye } from "react-icons/fa";
import React, { useEffect, useState } from "react";
import "../Service/ServicePage.css"
import { Form } from 'react-bootstrap';
import swal from "sweetalert";

function ServicePage() {
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [dataView, setDataView] = useState({});
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;

  const getService = async () => {
    try {
      const response = await axios.get(
        "https://mahalakshmimobiles.com/admin/admin_get_service.php"
      );
      setData(response.data.service);
      setFilteredData(response.data.service); // Initially show all services
    } catch (error) {
      console.error("Error fetching service data:", error);
    }
  };

  useEffect(() => {
    getService();
  }, []);

  const handleUpdateServiceStatus = async (id, status) => {
    try {
      const response = await axios.post(
        `https://mahalakshmimobiles.com/admin/admin_update_service.php?id=${id}&&status=${status}`
      );
      if (response.data.success) {
        swal({
          title: "Success!",
          text: response.data.message,
          icon: "success",
          button: {
            text: "OK",
            className: "swal-button--success", // Optional custom class for the button
          },
          timer: 3000, // Auto-close the alert after 3 seconds
        });

        getService(); // Refresh the service data
        closeModal();
      } else {
        swal({
          title: "Failed!",
          text: response.data.message || "Failed to update service status.",
          icon: "warning",
          button: {
            text: "OK",
            className: "swal-button--warning",
          },
        });
      }
    } catch (error) {
      swal({
        title: "Error!",
        text: error || "An unexpected error occurred.",
        icon: "error",
        button: {
          text: "OK",
          className: "swal-button--error",
        },
      });
    }
  };

  const handleView = async (id) => {
    try {
      const response = await axios.get(
        `https://mahalakshmimobiles.com/admin/admin_get_service.php?id=${id}`
      );
      const datares = response.data;
      setDataView(datares.service);
      setIsModalOpen(true);
    } catch (error) {
      console.error("Error fetching service details:", error);
    }
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setDataView({});
  };

  // Handle search input and filter the table based on the query
  const handleSearch = (e) => {
    const query = e.target.value;
    setSearchQuery(query);
    
    // Filter the data based on the search query
    const filtered = data.filter((service) => {
      return (
        service.complaint.toLowerCase().includes(query.toLowerCase()) ||
        service.imei_number.toLowerCase().includes(query.toLowerCase()) ||
        service.mobile_number.toLowerCase().includes(query.toLowerCase()) ||
        service.modal_name.toLowerCase().includes(query.toLowerCase()) ||
        (service.description && service.description.toLowerCase().includes(query.toLowerCase()))
      );
    });

    setFilteredData(filtered); // Update the filtered data to show
  };
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredData.slice(indexOfFirstItem, indexOfLastItem);

  const totalPages = Math.ceil(filteredData.length / itemsPerPage);

  const generatePaginationNumbers = () => {
    const pages = [];
    for (let i = 1; i <= totalPages; i++) {
      pages.push(i);
    }
    return pages;
  };

  return (
    <div className="main-container mt-3">
      <div className=" ">
        <div className="flex-container pt-5">
          <h3 className="head-fon-mv"> Service Page</h3>
          <div className="col-12 col-md-6 order-md-2 order-first">
            <nav
              aria-label="breadcrumb"
              className="breadcrumb-header float-start float-lg-end"
            >
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <a onClick={() => Navigate("/dashboard")}>Dashboard</a>
                </li>{" "}
                <li aria-current="page" className="breadcrumb-item active">
                  Service Page
                </li>
              </ol>
            </nav>
          </div>
        </div>

      {/* Search Bar - Positioned to the right */}
      <div className="search-bar-container" style={{ textAlign: "right", marginBottom: "20px" }}>
        <input
          type="text"
          placeholder="Search Services"
          value={searchQuery}
          onChange={handleSearch}
          className="form-control"
          style={{ width: "250px", display: "inline-block" }}
        />
      </div>

      <table className="table table-bordered">
          <thead>
            <tr>
              <th>ID</th>
              <th>Complaint</th>
              <th>IMEI Number</th>
              <th>Mobile Number</th>
              <th>Model Name</th>
              <th>Description</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {currentItems.map((service, index) => (
              <tr key={index}>
                <td data-label="ID">{service.id}</td>
                <td data-label="Complaint">{service.complaint}</td>
                <td data-label="IMEI Number">{service.imei_number}</td>
                <td data-label="Mobile Number">{service.mobile_number}</td>
                <td data-label="Model Name">{service.modal_name}</td>
                <td data-label="Description">{service.description || "-"}</td>
                <td data-label="Action">
                  <button className="btn-delete" onClick={() => handleView(service.id)}>
                    <FaEye />
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <div
          className="dataTables_paginate paging_simple_numbers p-2"
          id="dtBasicExample_paginate"
        >
          <ul className="pagination">
            <li
              className={`paginate_button page-item ${
                currentPage === 1 ? "disabled" : ""
              }`}
            >
              <button
                onClick={() => setCurrentPage((prevPage) => prevPage - 1)}
                className="page-link"
              >
                Previous
              </button>
            </li>
            {generatePaginationNumbers().map((page, index) => (
              <li
                key={index}
                className={`paginate_button page-item ${
                  page === currentPage ? "active" : ""
                }`}
              >
                <button
                  onClick={() => setCurrentPage(page)}
                  className="page-link"
                >
                  {page}
                </button>
              </li>
            ))}
            <li
              className={`paginate_button page-item ${
                currentPage === totalPages ? "disabled" : ""
              }`}
            >
              <button
                onClick={() => setCurrentPage((prevPage) => prevPage + 1)}
                className="page-link"
              >
                Next
              </button>
            </li>
          </ul>
        </div>

      {/* Modal */}
      {isModalOpen && (
        <div className="modal-container">
          <div className="modal-content">
            <header className="modal-header">
              <h2 style={{ color: 'black', fontSize: 30, textAlign: 'center' }}>Service Details</h2>
              <button
                type="button"
                aria-label="Close"
                className="close"
                onClick={closeModal}
              >
                &times;
              </button>
            </header>
            <div className="modal-body">
              <p>
                <strong>Complaint:</strong>
                <span className="value">{dataView.complaint}</span>
              </p>
              <p>
                <strong>IMEI Number:</strong>
                <span className="value">{dataView.imei_number}</span>
              </p>
              <p>
                <strong>Mobile Number:</strong>
                <span className="value">{dataView.mobile_number}</span>
              </p>
              <p>
                <strong>Model Name:</strong>
                <span className="value">{dataView.modal_name}</span>
              </p>
              {dataView.description && (
                <p>
                  <strong>Description:</strong>
                  <span className="value">{dataView.description}</span>
                </p>
              )}
              <p>
                <strong>Service Status:</strong>
                <Form.Select
                  aria-label="Service Status Select"
                  value={dataView.service_status}
                  onChange={(e) =>
                    setDataView({ ...dataView, service_status: e.target.value })
                  }
                >
                  <option value="0">Success</option>
                  <option value="1">Active</option>
                  <option value="2">Reject</option>
                </Form.Select>
              </p>
              <p>
                <strong>Placed Date:</strong>
                <span className="value">{dataView.placed_date}</span>
              </p>
            </div>

            <footer className="modal-footer">
              <button
                onClick={() => handleUpdateServiceStatus(dataView.id, dataView.service_status)}
                className="btn btn-primary"
              >
                Update
              </button>
              <button
                onClick={closeModal}
                className="btn btn-secondary"
              >
                Close
              </button>
            </footer>
          </div>
        </div>
      )}
    </div>
    </div>
  );
}

export default ServicePage;
