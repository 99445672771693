import React, { useState, useRef, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { FaSearch, FaTrash, FaPen, FaEye } from "react-icons/fa";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { useNavigate } from "react-router-dom";
import Lottie from "react-lottie";
import swal from "sweetalert";
import { faPenToSquare } from "@fortawesome/free-regular-svg-icons";
import animationData from "../Assets/Animation - 1716197548567.json";
import "./Role.css";
import { faSortUp, faSortDown } from "@fortawesome/free-solid-svg-icons";

function Role() {
  const Navigate = useNavigate();
  const [itemsPerPage, setItemsPerPage] = useState(20);
  const [showModal, setShowModal] = useState(false);
  const [filter, setFilter] = useState([]);
  const [loading, setLoading] = useState(false);
  const [editShowModal, setEditShowModal] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [showrole, setShowrole] = useState(false);
  const [totalItems, setTotalItems] = useState(0);
  const [newRole, setNewRole] = useState("");
  const roleInputRef = useRef(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [editRole, setEditRole] = useState({
    id: "",
    role: "",
  }); // State to hold edited role
  const [editRoleId, setEditRoleId] = useState(null); // State to hold edited role ID

  const handelShowrole = () => {
    setShowrole(true);
  };
  const handleShowModal = () => {
    setShowModal(true);
  };
  useEffect(() => {
    setLoading(true);
    fetchData()
      .then(() => setLoading(false))
      .catch(() => setLoading(false));
  }, [currentPage, itemsPerPage, searchQuery]); // Trigger fetch when currentPage or itemsPerPage changes
  useEffect(() => {
    if (showModal) {
      roleInputRef.current.focus();
    }
    fetchData();
  }, [showModal]);

  const fetchData = async () => {
    try {
      const response = await fetch(`https://mahalakshmimobiles.com/admin/role.php?`);
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const data = await response.json();
      setFilter(data); // Assuming data is an array of roles from the server
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setEditShowModal(false);
  };
  const handlePerPageChange = (e) => {
    const newItemsPerPage = parseInt(e.target.value);
    const totalPages = Math.ceil(filter.length / newItemsPerPage);

    const newCurrentPage = Math.min(currentPage, totalPages);

    setItemsPerPage(newItemsPerPage);
    setCurrentPage(newCurrentPage);
  };
  const filteredData = filter.filter((role) =>
    role.role.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const sortedFilter = [...filteredData].sort((a, b) => b.id - a.id);

  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;

  const currentPageData = sortedFilter.slice(startIndex, endIndex);
  const totalPages = Math.ceil(sortedFilter.length / itemsPerPage);

  const visiblePages = 3;

  const generatePaginationNumbers = () => {
    const paginationNumbers = [];
    let startPage = Math.max(currentPage - 1, 1);
    let endPage = Math.min(startPage + visiblePages - 1, totalPages);

    if (currentPage > Math.ceil(visiblePages / 2)) {
      paginationNumbers.push("...");
    }

    for (let i = startPage; i <= endPage; i++) {
      paginationNumbers.push(i);
    }

    if (endPage < totalPages - 1) {
      paginationNumbers.push("...");
      paginationNumbers.push(totalPages);
    }

    return paginationNumbers;
  };
  const handleUpdate = async (roleId) => {
    const permissions = {
      role: editRole,
    };

    try {
      const response = await fetch(
        `https://mahalakshmimobiles.com/admin/role.php?id=${roleId}`,
        {
          method: "PUT", // Assuming your backend supports PUT for update
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(permissions),
        }
      );

      if (!response.ok) {
        throw new Error("Failed to update role");
      }

      // Assuming the backend responds with success message
      const responseData = await response.json();
      console.log("Role updated successfully:", responseData);

      // Close modal and update data
      setEditShowModal(false);
      fetchData(); // Refresh data after update
    } catch (error) {
      console.error("Error updating role:", error);
      // Handle error (e.g., show error message to user)
    }
  };

  const handleDelete = async (id) => {
    try {
      const shouldDelete = await swal({
        title: "Are you sure?",
        text: "Once deleted, you will not be able to recover this role!",
        icon: "warning",
        buttons: ["Cancel", "Delete"],
        dangerMode: true,
      });

      if (shouldDelete) {
        const response = await fetch(
          `https://mahalakshmimobiles.com/admin/role.php?id=${id}`,
          {
            method: "DELETE",
          }
        );

        if (response.status === 200) {
          console.log("camera Resolution deleted successfully!");
          fetchData(); // Refresh the variants after deleting
        } else {
          console.error("Failed to delete variant");
        }
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };
  const handleEdit = (roleId, roleName) => {
    setEditRole({
      id: roleId,
      role: roleName,
    });
    setEditRoleId(roleId);
    setEditShowModal(true);
  };
  const handleInput = (e) => {
    const inputValue = e.target.value;
    setSearchQuery(inputValue);
  };

  const handleInsert = async () => {
    if (!newRole) {
      swal("warning", "Please enter required details", "warning");
      return;
    }

    const permissions = new FormData();
    permissions.append("role", newRole);
    permissions.append("permission", "");
    try {
      const response = await fetch(
        `https://mahalakshmimobiles.com/admin/role.php?`,
        {
          method: "POST",
          body: permissions,
        }
      );

      if (!response.ok) {
        throw new Error("Failed to add role");
      }

      // Assuming the backend responds with success message
      const responseData = await response.json();
      console.log("Role added successfully:", responseData);

      // Close modal and update data
      setShowModal(false);
      setNewRole(""); // Clear the new role input
      fetchData(); // Refresh data after insert
    } catch (error) {
      console.error("Error inserting role:", error);
      // Handle error (e.g., show error message to user)
    }
  };
  const [variant, setVariant] = useState([]);

  const handleSortByName = () => {
    const sortedBrands = [...currentPageData].sort((a, b) => {
      if (sortOrder === "asc") {
        return a.role.localeCompare(b.role);
      } else {
        return b.role.localeCompare(a.role);
      }
    });

    setVariant(sortedBrands);
    setSortOrder(sortOrder === "asc" ? "desc" : "asc");
  };
  const [sortOrder, setSortOrder] = useState("asc");

  useEffect(() => {
    setVariant(filter);
  }, [filter]);

  const [filteredCapacitie, setFilteredCapacities] = useState([]);
  useEffect(() => {
    const updatedFilteredCapacities = variant.filter((capacity) =>
      capacity.role.toLowerCase().includes(searchQuery.toLowerCase())
    );
    setFilteredCapacities(updatedFilteredCapacities);
  }, [variant, searchQuery]);
  return (
    <div className="main-container mt-3">
      <div className="flex-container pt-5">
        <h3 className="head-fon-mv">Add Role </h3>
        <div className="col-12 col-md-6 order-md-2 order-first">
          <nav
            aria-label="breadcrumb"
            className="breadcrumb-header float-start float-lg-end"
          >
            <ol className="breadcrumb">
              <li class="breadcrumb-item">
                <a onClick={() => Navigate("/dashboard")}>Dashboard</a>
              </li>{" "}
              <li aria-current="page" className="breadcrumb-item active">
                Add Role
              </li>
            </ol>
          </nav>
        </div>
      </div>

      <div className="card-cat">
        <div className="cat-left"></div>

        <div className="d-flex justify-content-between align-items-center">
          <div className="row">
            <div className="d-flex align-items-center">
              <label className="me-2">Show</label>
              <select
                style={{ height: "35px" }}
                className="form-select m-0 "
                value={itemsPerPage}
                onChange={handlePerPageChange}
              >
                <option value="20">20</option>
                <option value="40">40</option>
                <option value="60">60</option>
                <option value="80">80</option>
              </select>
              {/* <label>Entries</label> */}
            </div>
          </div>
          {/* <div className="search-left">
            <div className="searchbar">
              <input type="text" className="search" placeholder="Search" />
              <FaSearch className="search-icon" />
            </div>
          </div>

          <button className="add-category-button" onClick={handleShowModal}>
            Add Role
          </button> */}
          <div
            className="d-flex align-items-center ms-auto"
            style={{ padding: "5px" }}
          >
            <div className="searchbar" style={{ margin: "10px" }}>
              <input
                type="text"
                className="search"
                placeholder="Search"
                onChange={handleInput}
              />
              <FaSearch className="search-icon" />
            </div>
            <div>
              <button className="add-category-button" onClick={handleShowModal}>
                Add Role
              </button>
            </div>
          </div>
        </div>

        <div className="table-container text-end mt-3">
          {loading ? (
            <div>
              <Lottie
                height={250}
                width={250}
                options={{
                  loop: true,
                  animationData: require("../Assets/Animation - 1716197548567.json"),
                  autoplay: true,
                }}
              />
            </div>
          ) : (
            <table id="table-to-xls" className="text-center">
              <thead>
                <tr>
                  <th style={{ width: "4%" }}>S.No</th>
                  {/* <th style={{ textAlign: "center" }}>Variant Name</th> */}
                  <th
                    onClick={() => handleSortByName()}
                    style={{ cursor: "pointer", textAlign: "center" }}
                  >
                    Role
                    {sortOrder === "asc" ? (
                      <FontAwesomeIcon
                        icon={faSortUp}
                        style={{ marginLeft: "8px" }}
                      />
                    ) : (
                      <FontAwesomeIcon
                        icon={faSortDown}
                        style={{ marginLeft: "8px" }}
                      />
                    )}
                  </th>
                  <th style={{ textAlign: "center" }}>Action</th>
                </tr>
              </thead>

              <tbody>
                {filteredCapacitie.length > 0 ? (
                  filteredCapacitie.map((role, index) => (
                    <tr key={index}>
                      <td style={{ textAlign: "center" }}>
                        {startIndex + index + 1}
                      </td>
                      <td style={{ textAlign: "center" }}>{role.role}</td>
                      {role.role !== "admin" ? (
                        <td style={{ textAlign: "center" }}>
                          <button
                            className="btn-edit"
                            onClick={() => handleEdit(role.id, role.role)}
                          >
                            <FontAwesomeIcon icon={faPenToSquare} />
                          </button>

                          <button
                            className="btn-delete"
                            onClick={() => handleDelete(role.id)}
                          >
                            <FaTrash />
                          </button>
                          <button
                            className="btn-delete"
                            onClick={() => {
                              Navigate("/roleassign", {
                                state: {
                                  role_id: role.id,
                                  role: role.role,
                                },
                              });
                            }}
                          >
                            <FaEye />
                          </button>
                        </td>
                      ) : (
                        <td style={{ textAlign: "center" }}>
                          <button
                            className="btn-edit"
                            onClick={() => handleEdit(role.id, role.role)}
                          >
                            <FontAwesomeIcon icon={faPenToSquare} />
                          </button>
                        </td>
                      )}
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="3" style={{ textAlign: "center" }}>
                      No records found
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          )}
        </div>

        <Modal show={showModal} onHide={handleCloseModal}>
          <header class="modal-header">
            <h5 class="modal-title">Add Role</h5>
            <button
              type="button"
              aria-label="Close"
              class="close"
              onClick={handleCloseModal}
            >
              ×
            </button>
          </header>

          <Modal.Body>
            <label className="bold">Role</label>
            <input
              className="form-control"
              value={newRole}
              ref={roleInputRef}
              onChange={(e) => setNewRole(e.target.value)}
            ></input>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="success" onClick={handleInsert}>
              Save
            </Button>
            <Button variant="secondary" onClick={handleCloseModal}>
              Cancel
            </Button>
          </Modal.Footer>
        </Modal>

        <Modal show={editShowModal} onHide={() => setEditShowModal(false)}>
          <header class="modal-header">
            <h5 class="modal-title">Edit Role</h5>
            <button
              type="button"
              aria-label="Close"
              class="close"
              onClick={() => setEditShowModal(false)}
            >
              ×
            </button>
          </header>
          <Modal.Body>
            <label className="bold">Role</label>
            <input
              className="form-control"
              value={editRole.role}
              onChange={(e) =>
                setEditRole({ ...editRole, role: e.target.value })
              }
            ></input>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="success" onClick={() => handleUpdate(editRoleId)}>
              Update
            </Button>
            <Button variant="secondary" onClick={() => setEditShowModal(false)}>
              Cancel
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
      <div
        className="dataTables_paginate paging_simple_numbers p-2"
        id="dtBasicExample_paginate"
      >
        <ul className="pagination">
          <li
            className={`paginate_button page-item ${currentPage === 1 ? "disabled" : ""
              }`}
          >
            <button
              onClick={() =>
                setCurrentPage((prevPage) =>
                  prevPage > 1 ? prevPage - 1 : prevPage
                )
              }
              className="page-link"
            >
              Previous
            </button>
          </li>
          {generatePaginationNumbers().map((page, index) => (
            <li
              key={index}
              className={`paginate_button page-item ${page === currentPage ? "active" : ""
                }`}
            >
              <button
                onClick={() => setCurrentPage(page)}
                className="page-link"
              >
                {page}
              </button>
            </li>
          ))}
          <li
            className={`paginate_button page-item ${currentPage === totalPages ? "disabled" : ""
              }`}
          >
            <button
              onClick={() =>
                setCurrentPage((prevPage) =>
                  prevPage < totalPages ? prevPage + 1 : prevPage
                )
              }
              className="page-link"
            >
              Next
            </button>
          </li>
        </ul>
      </div>
    </div>
  );
}

export default Role;
