import React, { useEffect, useState, useRef } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import "./Addproduct.css";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import Lottie from "react-lottie";
import Swal from "sweetalert2";
import axios from "axios";
import swal from "sweetalert";
import "bootstrap/dist/css/bootstrap.min.css";
import Select from "react-select";
import { FaSearch, FaTrash, FaPen, FaCalendar } from "react-icons/fa";
function Addproduct() {
  const Navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const location = useLocation();
  const type = location.state?.product || "";
  const [isSaved, setIsSaved] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [id, setId] = useState(null);
  const navigate = useNavigate();
  useEffect(() => {
    if (location.state && location.state.product) {
      setId(location.state.product);
    } else {
      console.error("ID not found in location state");
    }
  }, [location.state]);
  const [brandload, setBrandload] = useState([]);
  const [unitload, setUnitLoad] = useState([]);
  const [options, setOptions] = useState([]);
  const [mrpprice, setMrpPrice] = useState("");
  const [selectedSubCategoryId, setSelectedSubCategoryId] = useState(0);
  const [discount, setDiscount] = useState("");
  const [madeinload, setmadeinload] = useState([]);
  const fileInputRef = useRef(null);
  const [categories, setCategories] = useState([]);
  const [productName, setProductName] = useState("");
  const [tax, setTax] = useState("");
  const [unit, setUnit] = useState("");
  const [productType, setProductType] = useState("");
  const [madeIn, setMadeIn] = useState("");
  const [tags, setTags] = useState("");
  const [brand, setBrand] = useState("");
  const [price, setPrice] = useState("");
  const [productStock, setProductStock] = useState("");
  const [warranty, setWarranty] = useState("");
  const [selectedCountry, setSelectedCountry] = useState("");
  const [productStatus, setProductStatus] = useState("Approved");
  const [isReturnable, setIsReturnable] = useState(true);
  const [isCancelable, setIsCancelable] = useState(true);
  const [isCODAllowed, setIsCODAllowed] = useState(true);
  const [totalAllowedQuantity, setTotalAllowedQuantity] = useState("");
  const [productInfo, setProductInfo] = useState({
    name: "",
    value: "",
  });
  const [description, setDescription] = useState("");
  const [productFeatures, setProductFeatures] = useState("");
  const [mainImage, setMainImage] = useState("");
  const [selectedCategory, setSelectedCategory] = useState("");
  const [selectedCategoryName, setSelectedCategoryName] = useState(null);
  const [variants, setVariants] = useState([]);
  const [variantValues, setVariantValues] = useState({});
  const [tableData, setTableData] = useState([]);
  const [taxOptions, setTaxOptions] = useState([]);
  const [showVariations, setShowVariations] = useState(false);
  const [selectedVariant, setSelectedVariant] = useState("");
  const [variantOptions, setVariantOptions] = useState([]);
  const [subCategories, setSubCategories] = useState([]);
  const [selectedSubCategory, setSelectedSubCategory] = useState("");
  const [variantType, setVariantType] = useState("");
  const [variantSize, setVariantSize] = useState("");
  const [variantImage, setVariantImage] = useState("");
  const [variantStock, setVariantStock] = useState("");
  const [variantStatus, setVariantStatus] = useState("");
  const [offerStatus, setOfferStatus] = useState("");
  const [discountedPrice, setDiscountedPrice] = useState("");
  const [instockText, setInstockText] = useState("");
  const [variantStar, setVariantStar] = useState("");
  const [reviewCount, setReviewCount] = useState("");
  const [createdAt, setCreatedAt] = useState("");
  const [productId, setProductId] = useState("");
  const [colour, setColour] = useState("");
  const [storage, setStorage] = useState("");
  const [product, setProduct] = useState(false);
  const [varientId, setVarientId] = useState("");
  const [varprice, setVarPrice] = useState("");
  const [varientImages, setVarientImages] = useState([]);
  const [croppedImage, setCroppedImage] = useState([]);
  const [parent, setParent] = useState(""); // state for selected parent category
  const [CategoriesAll, setCategoriesAll] = useState([]);
  const [childCategories, setChildCategories] = useState([]);
  const handleSubmit = (e) => {
    e.preventDefault();
    const formData = {
      variantType,
      variantSize,
      variantImage,
      variantStock,
      variantStatus,
      offerStatus,
      discountedPrice,
      price,
      instockText,
      variantStar,
      reviewCount,
      createdAt,
    };
  };
  const [productId1, setProductId1] = useState(null);
  const handleImageUpload = (e) => {
    setVarientImages(e.target.files);
  };
  const handleRadioChange = (status) => {
    setProductStatus(status);
  };
  const [errorMessage, setErrorMessage] = useState("");
  const handleFileChange = (e) => {
    const files = Array.from(e.target.files);
    setCroppedImage(files);
  };
  useEffect(() => {
    Brandget();
    Unitget();
    Madeinget();
  }, [id]);
  const handleVariantChange = (variantName, selectedId) => {
    const selectedOption = variantOptions[variantName]?.find(
      (option) => option.id === selectedId
    );
    const selectedValue = selectedOption ? selectedOption[variantName] : null;
    if (variantName === "colour") {
      setColour(selectedValue);
    } else if (variantName === "size") {
      setVariantSize(selectedValue);
    }
    setSelectedVariant((prevState) => ({
      ...prevState,
      [variantName]: selectedId,
    }));
  };
  const Unitget = async () => {
    const data = await fetch(
      "https://mahalakshmimobiles.com/admin/addunit.php?"
    );
    const response = await data.json();
    setUnitLoad(response);
  };
  const Brandget = async () => {
    const data = await fetch(
      "https://mahalakshmimobiles.com/admin/brand.php?status=1"
    );
    const response = await data.json();
    setBrandload(response);
  };
  const Madeinget = async () => {
    const data = await fetch(
      "https://restcountries.com/v3.1/independent?status=true"
    );
    const response = await data.json();
    setmadeinload(response);
  };
  useEffect(() => {
    fetch("https://mahalakshmimobiles.com/admin/addtax.php?status=1")
      .then((response) => response.json())
      .then((data) => {
        setTaxOptions(data);
      })
      .catch((error) => {
        console.error("Error fetching tax options:", error);
      });
  }, []);

  useEffect(() => {
    fetchCatData();
  }, []);
  useEffect(() => {
    if (selectedCategory) {
      const fetchAllAttributes = async () => {
        try {
          const response = await fetch(`https://mahalakshmimobiles.com/admin/get_product_specification.php?id=${selectedCategory}`);
          const data = await response.json();
          setCategoriesAll(data);
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      };
      fetchAllAttributes();
    }
  }, [selectedCategory]);
  const fetchCatData = async () => {
    try {
      const response = await fetch(
        "https://mahalakshmimobiles.com/admin/categoriesmaster.php"
      );
      const data = await response.json();
      setCategories(data);
      console.log("categoriesmaster", data);

      setSelectedVariant(data);
    } catch (error) {
      console.error("Error", error);
    }
  };
  useEffect(() => {
    if (parent) {
      const selectedParentCategory = categories.find(category => category.id === parent);
      setChildCategories(selectedParentCategory?.menu || []);
    }
  }, [parent, categories]);
  const handleCategoryChange = (e) => {
    try {
      if (!e || !e.target) {
        throw new Error("Event object or its target is undefined");
      }
      const categoryId = e.target.value;
      setSelectedCategory(categoryId);
      const selectedCategory = categories.find(
        (category) => category.id === categoryId
      );
      if (selectedCategory) {
        setSelectedCategoryName(selectedCategory.id);
        fetch(
          `https://mahalakshmimobiles.com/admin/categories.php?parent_category=${categoryId}`
        )
          .then((response) => response.json())
          .then((data) => {
            setSubCategories(data);
          })
          .catch((error) => {
            console.error("Error fetching sub-categories:", error);
          });
        const categoryVariants = selectedCategory.variants.split(",");
        setVariants(categoryVariants || []);
        setVariantValues({});
        categoryVariants.forEach((variant) => {
          const [cl, sz] = variant.split(",");
          fetch(`https://mahalakshmimobiles.com/admin/${cl}_master.php`)
            .then((response) => response.json())
            .then((data) => {
              setVariantOptions((prevState) => ({
                ...prevState,
                [cl]: data,
              }));
            })
            .catch((error) => {
              console.error(`Error fetching ${cl} options:`, error);
            });

          fetch(`https://mahalakshmimobiles.com/admin/${sz}_master.php`)
            .then((response) => response.json())
            .then((data) => {
              setVariantOptions((prevState) => ({
                ...prevState,
                [sz]: data,
              }));
            })
            .catch((error) => {
              console.error(`Error fetching ${sz} options:`, error);
            });
        });
      } else {
        console.error("Selected category not found.");
      }
    } catch (error) {
      console.error("Error in handleCategoryChange:", error);
    }
  };
  const handleRadioChangeCan = (e, setIsCancelable, setResolutionDetails) => {
    const { value } = e.target;
    setIsCancelable(value === "Yes");
  };

  const handleSubCategoryChange = (selectedValue) => {
    setSelectedSubCategoryId(selectedValue);
    fetch(`https://mahalakshmimobiles.com/admin/admin_add_product.php?category_id=${selectedCategory}&&subcategory_id=${selectedValue}`)
      .then((res) => {
        if (!res.ok) {
          throw new Error("Failed to fetch product data");
        }
        return res.json();
      })
      .then((data) => {
        if (data.status === 1 && data.product_id) {
          setProductId1(data.product_id);
        } else {
          console.error("Product ID not found in response");
        }
      })
      .catch((error) => {
        console.error("Error fetching product data:", error);
      });
  };

  const handleDescriptionChange = (content) => {
    setDescription(content);
  };
  const handleProductFeaturesChange = (content, delta, source, editor) => {
    setProductFeatures(content);
  };
  const handleRadioChangeCod = (e) => {
    const codValue = e.target.value === "Yes";
    setIsCODAllowed(codValue);
  };
  const handleRadioChangeRe = (e, setIsReturnable) => {
    const { value } = e.target;
    setIsReturnable(value === "Yes");
  };
  function generateRandomProductCode(productName) {
    const randomNumbers = Math.floor(Math.random() * 1000);
    const productCode = `${productName.toUpperCase()}${randomNumbers}`;
    return productCode;
  }
  const [selectedOptions, setSelectedOptions] = useState({}); 

  // Object to manage selected options per category
  const allValues = Object.values(selectedOptions) // Get arrays of selected values for each category
    .flat() // Flatten into a single array of values
    .join(","); // Join into a comma-separated string

  console.log("allValues", allValues);

  const handleproductSpecific = (selectedshortby, categoryId) => {
    const selectedValues = selectedshortby.map((option) => option.value); // Get selected IDs
    setSelectedOptions((prevState) => ({
      ...prevState,
      [categoryId]: selectedValues, // Update the options for the specific category
    }));
  };

  




  const productCode = generateRandomProductCode(productName);
  const handleInsertProduct = () => {
    if (
      !productName ||
      !productType ||
      !selectedCategory ||
      !selectedCountry ||
      !discount ||
      !price ||
      !unit ||
      !tags ||
      !croppedImage ||
      !productFeatures ||
      !description ||
      !selectedSubCategoryId ||
      !warranty ||
      !brand||
      !productStock
    ) {
      swal("warning", "Please enter required fields ", "warning");
      return;
    }
    setIsLoading(true);
    const formData = new FormData();
    formData.append("product_id", productId1);
    formData.append("category_id", selectedCategory);
    formData.append("subcategory_id", selectedSubCategoryId);
    formData.append("product_type", productType);
    formData.append("product_name", productName);
    formData.append("product_code", "PRO1234");
    formData.append("product_stock", productStock);
    formData.append("closeing_stock", 0);
    formData.append("warranty", warranty);
    formData.append("tags", tags);
    formData.append("pro_price", price);
    formData.append("discount", discount);
    formData.append("mrpprice", mrpprice);
    formData.append("brand", brand);
    formData.append("description", description);
    formData.append("made_in", selectedCountry);
    formData.append("tax", tax);
    formData.append("product_information", "UKO");
    formData.append("product_features", productFeatures);
    formData.append("returnable", isReturnable ? "Yes" : "No");
    formData.append("cancelable", isCancelable ? "Yes" : "No");
    formData.append("unit", unit);
    formData.append("product_status", productStatus);
    formData.append("cod", isCODAllowed ? "Yes" : "No");
    formData.append("offer_status", 1);
    formData.append("offer_type", 1);
    formData.append("offer_value", 1);
    formData.append("short_by", 1);
    formData.append("product_specification", allValues);
    formData.append("product_code", productCode);
    // Array.from(croppedImage).forEach((imageFile, index) => {
    //   formData.append(`image[]${index}`, imageFile);
    // });

    croppedImage.forEach((imageFile) => {
      formData.append("image[]", imageFile);
    });

    fetch("https://mahalakshmimobiles.com/admin/admin_add_product.php", {
      method: "POST",
      body: formData,
    })
      .then((res) => {
        if (res.ok) {
          return res.json();
        } else {
          throw new Error("Failed to insert");
        }
      })
      .then((data) => {
        // const lastInsertedId = data.last_id;
        swal({
          title: "Success!",
          text: `Product inserted successfully${productId1}`,
          icon: "success",
          button: {
            text: "OK",
            className: "swal-button--success", // Optional custom class for the button
          },
          timer: 3000, // Auto-close the alert after 3 seconds
        });
        setIsSaved(true);
        setProductId(productId1);
        setProduct(true);
        stockInsertproduct(productId1);
      })
      .catch((error) => {
        console.log("An error occurred while sending data: " + error.message);
      })
      .finally(() => {
        setIsLoading(false); // Re-enable the button after saving finishes
      });
  };
  const handleCountryChange = (event) => {
    setSelectedCountry(event.target.value);
  };
  const handleVariantInsert = async () => {
    try {
      if (
        !varprice ||
        !varientImages.length ||
        !varientImages ||
        !colour ||
        // !variantSize ||
        !variantStock ||
        !storage
      ) {
        swal("Warning", "Please enter required fields", "warning");
        return;
      }
      const totalPreviousVariantStock = tableData.reduce((acc, variant) => {
        return acc + parseInt(variant.variantStock || 0, 10);
      }, 0);
      const newTotalStock =
        totalPreviousVariantStock + parseInt(variantStock, 10);
      console.log(" newTotalStock", newTotalStock);
      if (Number(newTotalStock) > Number(productStock)) {
        swal(
          `The total stock for this variant is already ${totalPreviousVariantStock}, while the total product stock is ${productStock}. You  have ${Number(productStock) - Number(totalPreviousVariantStock) > 0
            ? Number(productStock) - Number(totalPreviousVariantStock)
            : "no"
          } stock left to add!`
        );
        return; // Prevent form submission
      }
      const newRow = {
        variantStock,
        varprice,
        storage,
        images: [],
      };
      variants.forEach((variant) => {
        const selectedOption = variantOptions[variant]?.find(
          (option) => option.id === selectedVariant[variant]
        );
        newRow[variant] = selectedOption ? selectedOption[variant] : "";
      });
      Array.from(varientImages).forEach((imageFile) => {
        const imageUrl = URL.createObjectURL(imageFile);
        newRow.images.push(imageUrl);
      });
      const formData = new FormData();
      formData.append("product_id", productId);
      formData.append("category_id", selectedCategory);
      formData.append("variant_type", variantType || "defaultType");
      formData.append("variant_size", variantSize || "3");
      Array.from(varientImages).forEach((imageFile, index) => {
        formData.append(`variant_image_${index}`, imageFile);
      });
      formData.append("variant_stock", variantStock);
      formData.append("variant_status", variantStatus || "1");
      formData.append("offer_status", offerStatus || "No Offer");
      formData.append("discount_price", discountedPrice || 0);
      formData.append("colour", colour);
      formData.append("storage", storage || "24GB");
      formData.append("price", varprice);
      formData.append("instock_text", instockText || "In Stock");
      formData.append("variant_star", variantStar || 0);
      formData.append("review_count", reviewCount || 0);
      const response = await fetch(
        "https://mahalakshmimobiles.com/admin/variant.php",
        {
          method: "POST",
          body: formData,
        }
      );
      const responseData = await response.text();
      const data = JSON.parse(responseData);
      if (!response.ok) {
        throw new Error(
          `Failed to insert variant: ${response.status} - ${response.statusText}`
        );
      }
      if (data.status === "success") {
        const newVarientId = data.last_id;
        setVarientId(newVarientId);
        setTableData((prevData) => [
          ...prevData,
          {
            ...newRow,
            varientId: newVarientId,
          },
        ]);
        setVariantStock("");
        setVarPrice("");
        setStorage("");
        setVarientImages([]);
        setSelectedVariant({});
        const fileInput = fileInputRef.current;
        if (fileInput) {
          fileInput.value = "";
        }
        await stockInsert(newVarientId);
      } else {
        console.error("Failed to add variant:", data.message);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };
  const handleInsert = async () => {
    try {
      await handleVariantInsert();
    } catch (error) {
      console.error("Error:", error);
    }
  };
  const stockInsert = async (newVarientId) => {
    const formData = new FormData();
    formData.append("product_id", productId);
    formData.append("product_name", productName);
    formData.append("variant_id", newVarientId);
    formData.append("variant_stock", variantStock);
    formData.append("price", varprice);
    try {
      const response = await fetch(
        "https://mahalakshmimobiles.com/admin/stock.php",
        {
          method: "POST",
          body: formData,
        }
      );
      if (!response.ok) {
        throw new Error(
          `Failed to insert stock: ${response.status} - ${response.statusText}`
        );
      }
      const data = await response.json();
      if (data.status === "success") {
      } else {
        console.error("Failed to insert stock:", data.message);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };
  const stockInsertproduct = async (lastInsertedId) => {
    const formData = new FormData();
    formData.append("product_id", lastInsertedId);
    formData.append("product_name", productName);
    formData.append("variant_id", "");
    formData.append("variant_stock", productStock);
    formData.append("price", price);
    try {
      const response = await fetch(
        "https://mahalakshmimobiles.com/admin/stock.php",
        {
          method: "POST",
          body: formData,
        }
      );
      if (!response.ok) {
        throw new Error(
          `Failed to insert stock: ${response.status} - ${response.statusText}`
        );
      }
      const data = await response.json();
      if (data.status === "success") {
      } else {
        console.error("Failed to insert stock:", data.message);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };
  useEffect(() => {
    if (type !== null) {
      setCroppedImage("");
    }
  }, [type]);
  const handleAllDetails = () => {
    setProductName("");
    setTags("");
    setPrice("");
    setBrand("");
    setDescription("");
    setMadeIn("");
    setTax("");
    setProductStock("");
    setWarranty("");
    setMainImage(null);
    setIsReturnable(true);
    setIsCancelable(true);
    setIsCODAllowed(true);
    setProductFeatures("");
    setCroppedImage(null);
    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }
    const fileInput = document.querySelector('input[type="file"]');
    if (fileInput) {
      fileInput.value = "";
    }
    setCroppedImage("");
    setTags("");
    setUnit("");
    setProductType("");
    setSelectedCountry("");
    setSelectedCategory("");
    setSelectedSubCategory("");
    setBrand(null);
    setProductStock("");
    setWarranty("");
    setProductStatus("");
    setMainImage("");
    setTableData([]);
    setTotalAllowedQuantity("");
    setSubCategories("");
    setProduct(false);
    setProductInfo({
      name: "",
      value: "",
    });
    Navigate("/manageproduct");
  };
  const handleDelete = async (categoryId) => {
    try {
      const shouldDelete = await swal({
        title: "Are you sure?",
        text: "Once deleted, you will not be able to recover this variant!",
        icon: "warning",
        buttons: ["Cancel", "Delete"],
        dangerMode: true,
      });
      if (shouldDelete) {
        const response = await fetch(
          `https://mahalakshmimobiles.com/admin/variant.php?id=${categoryId}`,
          {
            method: "DELETE",
          }
        );
        if (response.ok) {
          setTableData((prevData) =>
            prevData.filter((row) => row.varientId !== categoryId)
          );
          swal("Deleted!", "variant has been deleted.", "success");
        } else {
          console.error("Failed to delete category");
        }
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };
  return (
    <div className="main-container mt-3">
      {loading ? (
        <div>
          <Lottie
            height={250}
            width={250}
            options={{
              loop: true,
              animationData: require("../Assets/Animation - 1716197548567.json"),
              autoplay: true,
            }}
          />
        </div>
      ) : (
        <div className=" ">
          <div className="flex-container pt-5">
            {type !== "" ? (
              <h3 className="head-fon-mv">Edit Product</h3>
            ) : (
              <h3 className="head-fon-mv">Create Product</h3>
            )}

            <div class="col-12 col-md-6 order-md-2 order-first">
              <nav
                aria-label="breadcrumb"
                class="breadcrumb-header float-start float-lg-end"
              >
                <ol class="breadcrumb">
                  <li class="breadcrumb-item">
                    <a onClick={() => Navigate("/dashboard")}>Dashboard</a>
                  </li>{" "}
                  <li class="breadcrumb-item">
                    <a onClick={() => Navigate("/manageproduct")}>
                      Manage Products
                    </a>
                  </li>{" "}
                  <li aria-current="page" class="breadcrumb-item active">
                    Create Product
                  </li>
                </ol>
              </nav>
            </div>
          </div>
          <div className="card ">
            <div className="cat-left">
            </div>
            <div className="form-container">
              <div className="form-column">
                <label className="bold">
                  Product Name <i className="text-danger">*</i>
                </label>
                <input
                  className="form-control"
                  placeholder="Enter Product Name"
                  value={productName || ""}
                  onChange={(e) => {
                    const { value } = e.target;
                    if (/^[a-zA-Z0-9\s'-]*$/.test(value)) {
                      setProductName(value);
                    }
                  }}
                />
                <label className="bold">Tax</label>
                <select
                  className="form-select"
                  value={tax || ""}
                  onChange={(e) => {
                    const { value } = e.target;
                    setTax(value);
                  }}
                >
                  <option value="">Select Tax</option>
                  {taxOptions.map((option) => (
                    <option key={option.id} value={option.percentage}>
                      {option.percentage}
                    </option>
                  ))}
                </select>
              </div>
              <div className="form-column">
                <label className="bold">
                  Product Type <i className="text-danger">*</i>
                </label>
                <input
                  className="form-control"
                  placeholder="Enter Product Type"
                  value={productType || ""}
                  onChange={(e) => {
                    const { value } = e.target;
                    setProductType(value);
                  }}
                />
                <label className="bold">
                  Made In <i className="text-danger">*</i>
                </label>
                <select
                  className="form-select"
                  value={selectedCountry || ""}
                  onChange={handleCountryChange}
                >
                  <option value="">Select Country</option>
                  {madeinload &&
                    madeinload.map(
                      (madein) =>
                        madein &&
                        madein.name &&
                        madein.name.common && (
                          <option key={madein.id} value={madein.name.common}>
                            {madein.name.common}
                          </option>
                        )
                    )}
                </select>
              </div>
            </div>
            <div className="form-container">
              <div className="form-column">
                <label className="bold">
                  Tags<i className="text-danger">*</i>
                </label>
                <input
                  className="form-control"
                  placeholder="Enter Tags"
                  value={tags || ""}
                  onChange={(e) => {
                    const { value } = e.target;
                    setTags(value);
                  }}
                />
                <label className="bold">
                  MRP Price<i className="text-danger">*</i>
                </label>
                <input
                  className="form-control"
                  placeholder="Enter MRP Price"
                  value={mrpprice || ""}
                  onChange={(e) => {
                    const { value } = e.target;
                    setMrpPrice(value);
                  }}
                />
                <label className="bold">
                  Brand<i className="text-danger">*</i>
                </label>
                <select
                  className="form-select"
                  value={brand || ""}
                  onChange={(e) => {
                    const { value } = e.target;
                    const newValue = value === "" ? 0 : value;
                    setBrand(newValue);
                  }}
                >
                  <option value="">Select Brand Name</option>
                  {brandload &&
                    brandload.map((brand) => (
                      <option key={brand.id} value={brand.id}>
                        {brand.brand_name}
                      </option>
                    ))}
                </select>
              </div>
              <div className="form-column">
                <label className="bold">
                  Discount<i className="text-danger">*</i>
                </label>
                <select
                  className="form-select"
                  placeholder="Enter Discount"
                  value={discount || ""}
                  onChange={(e) => {
                    const { value } = e.target;
                    setDiscount(value);
                  }}
                >
                  <option value="" disabled>
                    Select Discount Type
                  </option>
                  <option value="0">Amount</option>
                  <option value="1">Percentage</option>
                </select>
                <label className="bold">
                  Price <i className="text-danger">*</i>
                </label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Enter Price "
                  value={price || ""}
                  onChange={(e) => {
                    const value = e.target.value;
                    if (/^[\d,]*$/.test(value)) {
                      setPrice(value);
                    }
                  }}
                />
                <label className="bold">
                  Category <i className="text-danger">*</i>
                </label>
                <select
                  className="form-select"
                  value={selectedCategory || ""}
                  onChange={(e) => handleCategoryChange(e)}
                >
                  <option value="">Select Category Name</option>
                  {categories &&
                    categories.map((category) => (
                      <option key={category.id} value={category.id}>
                        {category.category_name}
                      </option>
                    ))}
                </select>
              </div>
            </div>
            <div className="form-new" style={{ display: "flex", gap: "10px" }}>
              <div style={{ flex: 1 }}>
                <label className="bold">
                  Sub Category <i className="text-danger">*</i>
                </label>
                <select
                  className="form-select"
                  value={
                    selectedSubCategoryId ||
                    ""
                  }
                  onChange={(e) => {
                    const selectedValue = e.target.value;
                    handleSubCategoryChange(selectedValue);
                  }}
                >
                  <option value="">Select Sub Category</option>
                  {subCategories &&
                    subCategories.map((subCategory) => (
                      <option key={subCategory.id} value={subCategory.id}>
                        {subCategory.category_name}
                      </option>
                    ))}
                </select>
              </div>
              <div style={{ flex: 1 }}>
                <label className="bold">
                  Unit <i className="text-danger">*</i>
                </label>
                <select
                  className="form-select"
                  value={unit || ""}
                  onChange={(e) => {
                    const selectedValue = e.target.value;
                    setUnit(selectedValue);
                  }}
                >
                  <option value="">Select Unit</option>
                  {unitload &&
                    unitload.map((unitItem) => (
                      <option key={unitItem.id} value={unitItem.unit_name}>
                        {unitItem.unit_name}
                      </option>
                    ))}
                </select>
              </div>
              <div style={{ flex: 1 }} className="m-2">
                <>
                  <label className="bold">
                    Opening Stock <i className="text-danger">*</i>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter Product Stock"
                    value={productStock}
                    onChange={(e) => {
                      const inputVal = e.target.value;
                      const filteredValue = inputVal.replace(/\D/g, "");
                      setProductStock(filteredValue);
                    }}
                    readOnly={isSaved}
                  />
                </>

              </div>
              <div style={{ flex: 1 }} className="m-2">
                <>
                  <label className="bold">
                    Warranty <i className="text-danger">*</i>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter Warranty"
                    value={warranty}
                    onChange={(e) => {
                      const inputVal = e.target.value;
                      const filteredValue = inputVal.replace(
                        /[^a-zA-Z0-9 ]/g,
                        ""
                      );
                      setWarranty(filteredValue);
                    }}
                  />
                </>
              </div>
            </div>
            <div className="form-container">
              <div className="form-column">
                <div className="input-group d-flex justify-content-between">
                  {/* Is Returnable */}
                  <label className="bold">
                    Is Returnable?
                    <div role="radiogroup" className="btn-group-toggle btn-group">
                      <label
                        className={`btn btn-outline-primary ${isReturnable ? "active" : ""}`}
                      >
                        <input
                          type="radio"
                          value="Yes"
                          checked={isReturnable}
                          onChange={(e) => handleRadioChangeRe(e, setIsReturnable)}
                        />
                        <span>Yes</span>
                      </label>
                      <label
                        className={`btn btn-outline-primary ${!isReturnable ? "active" : ""}`}
                      >
                        <input
                          type="radio"
                          value="No"
                          checked={!isReturnable}
                          onChange={(e) => handleRadioChangeRe(e, setIsReturnable)}
                        />
                        <span>No</span>
                      </label>
                    </div>
                  </label>

                  {/* Is Cancelable */}
                  <label className="bold">
                    Is Cancelable?
                    <div role="radiogroup" className="btn-group-toggle btn-group">
                      <label
                        className={`btn btn-outline-primary ${isCancelable ? "active" : ""}`}
                      >
                        <input
                          type="radio"
                          value="Yes"
                          checked={isCancelable}
                          onChange={(e) => handleRadioChangeCan(e, setIsCancelable)}
                        />
                        <span>Yes</span>
                      </label>
                      <label
                        className={`btn btn-outline-primary ${!isCancelable ? "active" : ""}`}
                      >
                        <input
                          type="radio"
                          value="No"
                          checked={!isCancelable}
                          onChange={(e) => handleRadioChangeCan(e, setIsCancelable)}
                        />
                        <span>No</span>
                      </label>
                    </div>
                  </label>

                  {/* Is COD Allowed */}
                  <label className="bold">
                    Is COD Allowed?
                    <div role="radiogroup" className="btn-group-toggle btn-group">
                      <label
                        className={`btn btn-outline-primary ${isCODAllowed ? "active" : ""}`}
                      >
                        <input
                          type="radio"
                          value="Yes"
                          checked={isCODAllowed}
                          onChange={(e) => handleRadioChangeCod(e, setIsCODAllowed)}
                        />
                        <span>Yes</span>
                      </label>
                      <label
                        className={`btn btn-outline-primary ${!isCODAllowed ? "active" : ""}`}
                      >
                        <input
                          type="radio"
                          value="No"
                          checked={!isCODAllowed}
                          onChange={(e) => handleRadioChangeCod(e, setIsCODAllowed)}
                        />
                        <span>No</span>
                      </label>
                    </div>
                  </label>

                  {/* Product Status */}
                  <label className="bold">
                    Product Status
                    <div role="radiogroup" className="btn-group-toggle btn-group">
                      <label
                        className={`btn btn-outline-primary ${productStatus === "Approved" ? "active" : ""
                          }`}
                      >
                        <input
                          type="radio"
                          value="Approved"
                          checked={productStatus === "Approved"}
                          onChange={() => handleRadioChange("Approved")}
                        />
                        <span>Approved</span>
                      </label>
                      <label
                        className={`btn btn-outline-primary ${productStatus === "Not-Approved" ? "active" : ""
                          }`}
                      >
                        <input
                          type="radio"
                          value="Not-Approved"
                          checked={productStatus === "Not-Approved"}
                          onChange={() => handleRadioChange("Not-Approved")}
                        />
                        <span>Not Approved</span>
                      </label>
                    </div>
                  </label>
                </div>
              </div>
            </div>

            {selectedCategory ? (
              <div className="card">
                <div className="card-body">
                  <div className="row">
                    {Array.isArray(CategoriesAll) && CategoriesAll.length > 0 ? (
                      CategoriesAll.map((category) => (
                        <div key={category.id} className="col-md-4 d-flex flex-column">
                          <label className="bold">{category?.master_name}</label>
                          <Select
                            id={`categorySelect-${category.id}`}
                            className="form-control"
                            isMulti
                            onChange={(selectedshortby) =>
                              handleproductSpecific(selectedshortby, category.id)
                            }
                            placeholder="Select Subcategory By"
                            options={category.menu?.map((childCategory) => ({
                              value: childCategory.id,
                              label: childCategory.menu_name,
                            }))}
                            value={selectedOptions[category.id]?.map((childId) => ({
                              value: childId,
                              label: category.menu.find(
                                (child) => child.id === childId
                              )?.menu_name,
                            })) || []} // Set selected options based on state
                          />
                        </div>
                      ))
                    ) : (
                      <p>No categories available</p>
                    )}
                  </div>
                </div>
              </div>
            ) : (
              <p>No category selected</p>
            )}

            <div>
              <label className="bold">
                Description <i className="text-danger">*</i>
              </label>
              <ReactQuill
                theme="snow"
                value={description || ""}
                onChange={handleDescriptionChange}
              />
            </div>
            <div>
              <label className="bold">
                Product Features<i className="text-danger">*</i>
              </label>
              <ReactQuill
                theme="snow"
                value={
                  productFeatures || ""
                }
                onChange={handleProductFeaturesChange}
              />
            </div>
            <div>
              <label className="bold">
                Main Image <i className="text-danger">*</i>
              </label>
              <p>Please choose a square image less 140KB.</p>
              <label className="mb-4">
                {croppedImage &&
                  croppedImage.map((file, index) => {
                    const imageUrl = URL.createObjectURL(file);
                    return (
                      <img
                        key={index}
                        src={imageUrl}
                        alt={`Selected ${index + 1}`}
                        className="selected-image"
                      />
                    );
                  })}
                <input
                  multiple
                  type="file"
                  className="form-control"
                  onChange={(e) => handleFileChange(e)}
                />
                {errorMessage && <p style={{ color: "red" }}>{errorMessage}</p>}
              </label>
            </div>
            <div className="save-bt ">
              <button
                className="btn btn-danger "

                style={{ marginRight: "10px", width: "90px" }}
                onClick={() => navigate("/manageproduct")}
              >
                Cancel
              </button>
              <button
                onClick={handleInsertProduct}
                disabled={isLoading}
                className="btn btn-success "
                style={{ width: "90px", float: "left" }}
              >
                {isLoading
                  ? id
                    ? "Updating..."
                    : "Saving..."
                  : id
                    ? "Update"
                    : "Save"}
              </button>
            </div>
          </div>
          <div>
            {selectedCategory && product && (
              <div>
                <div className="form-container2">
                  <form onSubmit={handleSubmit}>
                    <div>
                      {variants.map((variant, index) => (
                        <div key={index} style={{ marginBottom: "10px" }}>
                          <label>
                            {variant} <i className="text-danger">*</i>
                          </label>
                          <select
                            className="form-select"
                            onChange={(e) =>
                              handleVariantChange(variant, e.target.value)
                            }
                            value={selectedVariant[variant] || ""}
                          >
                            <option value="">Select {variant}</option>
                            {variantOptions[variant]?.map(
                              (option, optionIndex) => (
                                <option key={optionIndex} value={option.id}>
                                  {option.hasOwnProperty(variant)
                                    ? option[variant]
                                    : ""}
                                </option>
                              )
                            )}
                          </select>
                        </div>
                      ))}
                      <label className="form-label">
                        Variant Stock: <i className="text-danger ">*</i>
                        <input
                          className="form-control"
                          placeholder="Variant Stock"
                          value={variantStock}
                          onChange={(e) => {
                            const value = e.target.value;
                            if (/^[\d,]*$/.test(value)) {
                              setVariantStock(e.target.value);
                            }
                          }}
                        />
                      </label>
                      <label className="form-label m-3">
                        Price: <i className="text-danger ">*</i>
                        <input
                          className="form-control"
                          placeholder="Price"
                          value={varprice}
                          onChange={(e) => {
                            const value = e.target.value;
                            if (/^[\d,]*$/.test(value)) {
                              setVarPrice(value);
                            }
                          }}
                        />
                      </label>
                      <label className="form-label m-3">
                        RAM: <i className="text-danger ">*</i>
                        <input
                          className="form-control"
                          placeholder="RAM"
                          value={storage}
                          onChange={(e) => {
                            const value = e.target.value;
                            if (/^[a-zA-Z\d,]*$/.test(value)) {
                              setStorage(value);
                            }
                          }}
                        />
                      </label>
                      <label>
                        Images: <i className="text-danger">*</i>
                        <input
                          ref={fileInputRef}
                          multiple
                          type="file"
                          className="form-control"
                          onChange={(e) => handleImageUpload(e)}
                        />
                      </label>
                      <button
                        onClick={handleInsert}
                        style={{
                          padding: "5px 10px",
                          backgroundColor: "#007bff",
                          color: "#fff",
                          margin: "3px",
                          border: "none",
                          borderRadius: "3px",
                          cursor: "pointer",
                        }}
                      >
                        Add
                      </button>
                    </div>
                  </form>
                </div>
                <div>
                  <h2>Table Data</h2>
                  <table border="1">
                    <thead>
                      <tr>
                        {variants.map((variant, index) => (
                          <th key={index}>{variant}</th>
                        ))}
                        <th>Variant Stock</th>
                        <th>Price</th>
                        <th>RAM</th>
                        <th>images</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {tableData.map((rowData, index) => (
                        <tr key={index}>
                          {variants.map((variant, index) => (
                            <td key={index}>{rowData[variant]}</td>
                          ))}
                          <td>{rowData.variantStock}</td>
                          <td>{rowData.varprice}</td>
                          <td>{rowData.storage}</td>
                          <td>
                            {rowData.images.map((imageUrl, idx) => (
                              <img
                                key={idx}
                                src={imageUrl}
                                alt={`Image ${idx}`}
                                style={{
                                  width: "100px",
                                  height: "100px",
                                  objectFit: "cover",
                                }}
                              />
                            ))}
                          </td>
                          <td>
                            <button
                              className="btn-edit"
                              onClick={() => handleDelete(rowData.varientId)}
                            >
                              <FaTrash />
                            </button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
                <button
                  onClick={handleAllDetails}
                  className="btn btn-success m-2"
                  style={{ width: "170px", float: "right" }}
                >
                  Save all varients
                </button>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
}

export default Addproduct;
