import React, { useState, useEffect, useRef } from 'react';
import { Modal, Button } from 'react-bootstrap';
import axios from 'axios';
import { useLocation } from 'react-router-dom';
import Lottie from "react-lottie";
import { useNavigate } from "react-router-dom";
export default function Highlight_Master() {
    const [showModal, setShowModal] = useState(false);
    const location = useLocation();
    const { id } = location.state || {};
    const Navigate = useNavigate();
    const [parentMaster, setParentMaster] = useState('');
    const [masterName, setMasterName] = useState('');
    const [masters, setMasters] = useState([]);
    const [editIndex, setEditIndex] = useState(null);
    const [editMasterId, setEditMasterId] = useState(null);
    const [loading, setLoading] = useState(false);
    const parentMasterInputRef = useRef(null);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const fixedCategoryId = '5';
    const [searchQuery, setSearchQuery] = useState('');
    const [filteredData, setFilteredData] = useState([]); // Sample data, replace with actual data
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [dataView, setDataView] = useState({});
    const handleOpenModal = () => setShowModal(true);
    const handleCloseModal = () => {
        setShowModal(false);

    };
    const handleSearch = (e) => {
        setSearchQuery(e.target.value);
        // Update filteredData based on the search query
    };

    const handleView = (id) => {
        // Fetch and display data for the selected service
        const selectedData = {}; // Replace with actual data fetching logic
        setDataView(selectedData);
        setIsModalOpen(true);
    };

    const handleInsert = () => {
        // setIsModalOpen(false);
    };

    const handleUpdateServiceStatus = (id, status) => {
        // Update service status logic
    };

    return (
        <div className="main-container mt-3">
            <h3 className="head-fon-mv">Highlight Masters</h3>

            <Button variant="primary" onClick={handleOpenModal} className="add-category-button" style={{ width: 150 }}>
                Add Master
            </Button>

            <Modal show={showModal} onHide={handleCloseModal}>
                <Modal.Header closeButton>
                    <Modal.Title>{editIndex !== null ? 'Edit Master' : 'Add Master'}</Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <div>
                    <label className="bold mt-1">
                            Highlight Master Name <i className="text-danger">*</i>
                        </label>
                        <input
                            className="form-control"
                            placeholder="Enter  Highlight Master Name"
                            value={masterName}
                            onChange={(e) => setMasterName(e.target.value)}
                        />
                       

                        
                    </div>
                </Modal.Body>

                <Modal.Footer>
                    <Button variant="success" onClick={handleInsert} disabled={isSubmitting} >
                        {editIndex !== null ? 'Update' : 'Save'}
                    </Button>
                    <Button variant="secondary" onClick={handleCloseModal}>
                        Cancel
                    </Button>
                </Modal.Footer>
            </Modal>
            <div>


                {loading ? (
                    <div className="d-flex justify-content-center mt-4">
                        <Lottie
                            height={250}
                            width={250}
                            options={{
                                loop: true,
                                animationData: require("../Assets/Animation - 1716197548567.json"),
                                autoplay: true,
                            }}
                        />
                    </div>
                ) : (
                    <div className="mt-4">
                        <h5 className="mb-3">Added Masters</h5>
                        {masters.length === 0 ? (
                            <p className="text-center text-muted"> Currently no master for this category.</p>
                        ) : (
                            <div className="row">
                                {/* {masters.map((master, index) => (
                            <div key={index} className="col-12 col-sm-6 col-md-4 col-lg-3 mb-4">
                                <div className="p-3 border rounded shadow-sm bg-white text-center">
                                    <p className="text-muted">{master.master_name}</p>
                                    <div className="d-flex justify-content-center mt-2">
                                        <button className="btn btn-outline-success btn-sm mx-1" onClick={() => handleOpen(master)}>Open</button>
                                        <button className="btn btn-outline-primary btn-sm mx-1" onClick={() => handleEdit(index)}>
                                            Edit
                                        </button>
                                        <button className="btn btn-outline-danger btn-sm mx-1" onClick={() => handleDelete(index)}>
                                            Delete
                                        </button>
                                    </div>
                                </div>
                            </div>
                        ))} */}
                            </div>
                        )}
                    </div>
                )}

            </div>
        </div>
    );
}
