import React, { useEffect, useState, useRef } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import Lottie from "react-lottie";
import Swal from "sweetalert2";
import axios from "axios";
import swal from "sweetalert";
import "bootstrap/dist/css/bootstrap.min.css";
import Select from "react-select";
import { FaSearch, FaTrash, FaPen, FaCalendar } from "react-icons/fa";
function EditProduct() {
  const Navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const location = useLocation();
  const [isLoading, setIsLoading] = useState(false);
  const [id, setId] = useState(null);
  const [selectedImages, setSelectedImages] = useState("");
  const navigate = useNavigate();
  const [selectedOptions, setSelectedOptions] = useState({});
  const [productData, setProductData] = useState({});
  console.log("productData", productData);
  const [selectedSubCategoryId, setSelectedSubCategoryId] = useState(0);
  console.log("selectedSubCategoryId", selectedSubCategoryId);
  const [productName, setProductName] = useState("");
  const [productType, setProductType] = useState("");
  const [taxs, settaxs] = useState("");
  const [madeIn, setMadeIn] = useState("");
  const [tags, setTags] = useState("");
  const [brand, setBrand] = useState("");
  const [price, setPrice] = useState("");
  const [productStock, setProductStock] = useState("");
  const [warranty, setWarranty] = useState("");
  const [description, setDescription] = useState("");
  const [productFeatures, setProductFeatures] = useState("");
  const [unit, setUnit] = useState("");
  const [mrpprice, setMrpPrice] = useState("");
  const [discount, setDiscount] = useState("");
  const [isReturnable, setIsReturnable] = useState(true);
  const [isCancelable, setIsCancelable] = useState(true);
  const [isCODAllowed, setIsCODAllowed] = useState(true);
  const [selectedCountry, setSelectedCountry] = useState("");
  const [productId, setproductId] = useState(null);
  useEffect(() => {
    if (location.state && location.state.product) {
      setId(location.state.product);
    } else {
      console.error("ID not found in location state");
    }
  }, [location.state]);
  useEffect(() => {
    if (productData) {
      setProductName(productData.product_name || "")
      setProductType(productData.product_type || "")
      settaxs(productData.tax || "")
      setBrand(productData.brand || "")
      setPrice(productData.pro_price || "")
      setProductStock(productData.product_stock || "")
      setDescription(productData.description || "")
      setProductFeatures(productData.product_features || "")
      setMadeIn(productData.made_in || "")
      setTags(productData?.tags || "")
      setUnit(productData.unit || "")
      setMrpPrice(productData.mrpprice || "")
      setWarranty(productData.warranty || "")
      setDiscount(productData.discount || "")
      setIsCODAllowed(productData.cod || "")
      setIsCancelable(productData.cancelable || "")
      setIsReturnable(productData.returnable || "")
      setProductStatus(productData.product_status || "")
      setSelectedCountry(productData.made_in || "")
      setproductId(productData.id || "")
    }
  }, [productData]);
  const [brandload, setBrandload] = useState([]);
  const [unitload, setUnitLoad] = useState([]);
  const [madeinload, setmadeinload] = useState([]);
  const [categories, setCategories] = useState([]);
  const [productStatus, setProductStatus] = useState("Approved");
  const [selectedCategory, setSelectedCategory] = useState("");
  console.log("selectedCategory", selectedCategory);
  const [taxOptions, setTaxOptions] = useState([]);;
  const [subCategories, setSubCategories] = useState([]);
  console.log("subCategories", subCategories);
  const handleRadioChange = (status) => {
    setProductStatus(status);
  };
  const [errorMessage, setErrorMessage] = useState("");
  const handleFileChange = (e) => {
    const files = Array.from(e.target.files); // Convert FileList to an array
    setSelectedImages(files);
  };
  const Brandget = async () => {
    const data = await fetch(
      "https://mahalakshmimobiles.com/admin/brand.php?status=1"
    );
    const response = await data.json();
    setBrandload(response);
  };
  const Unitget = async () => {
    const data = await fetch(
      "https://mahalakshmimobiles.com/admin/addunit.php?"
    );
    const response = await data.json();
    setUnitLoad(response);
  }
  useEffect(() => {
    Madeinget()
    Brandget();
    Unitget();
    fetchCatData();
    if (id) {
      handleEdit();
    }
  }, [id]);
  const handleEdit = async () => {
    setLoading(true);
    const fetchData = async () => {
      try {
        const response = await fetch(
          `https://mahalakshmimobiles.com/admin/admin_get_product.php?id=${id}`
        );
        const resolutions = await response.json();
        console.log("ee", resolutions);

        setProductData(resolutions.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    const delayLoading = new Promise((resolve) => setTimeout(resolve, 3000));
    await Promise.all([fetchData(), delayLoading]);
    setLoading(false);
  };
  useEffect(() => {
    fetch("https://mahalakshmimobiles.com/admin/addtax.php?status=1")
      .then((response) => response.json())
      .then((data) => {
        setTaxOptions(data);
      })
      .catch((error) => {
        console.error("Error fetching tax options:", error);
      });
  }, []);
  const fetchCatData = async () => {
    try {
      const response = await fetch(
        "https://mahalakshmimobiles.com/admin/categoriesmaster.php"
      );
      const data = await response.json();
      setCategories(data);
    } catch (error) {
      console.error("Error", error);
    }
  };
  const [productId1, setProductId1] = useState(null);
  function generateRandomProductCode(productName) {
    const randomNumbers = Math.floor(Math.random() * 1000);
    const productCode = `${productName.toUpperCase()}${randomNumbers}`;
    return productCode;
  };
  const productCode = generateRandomProductCode(productName);
  const handleSubCategoryChange = (selectedValue) => {
    setSelectedSubCategoryId(selectedValue);
    fetch(`https://mahalakshmimobiles.com/admin/admin_add_product.php?category_id=${productData?.category}&&subcategory_id=${productData?.category_id}`)
      .then((res) => {
        if (!res.ok) {
          throw new Error("Failed to fetch product data");
        }
        return res.json();
      })
      .then((data) => {
        if (data.status === 1 && data.product_id) {
          setProductId1(data.product_id);
        } else {
          console.error("Product ID not found in response");
        }
      })
      .catch((error) => {
        console.error("Error fetching product data:", error);
      });
  };
  const allValues = Object.values(selectedOptions)
    .flat()
    .map(item => item.value)
    .join(",");

  const handleupdate = async () => {
    try {
      const url = `https://mahalakshmimobiles.com/admin/admin_update_product.php`;
      const payload = {
        product_id: productId || "",
        category_id: selectedCategory || "",
        subcategory_id: selectedSubCategoryId || "",
        product_type: productType || "",
        product_name: productName || "",
        product_code: productCode || "",
        product_stock: productStock || "0",
        closeing_stock: 0,
        warranty: warranty || "",
        tags: tags || "",
        pro_price: price || "",
        discount: discount || "",
        mrpprice: mrpprice || "",
        brand: brand || "",
        description: description || "",
        made_in: selectedCountry || "",
        tax: taxs || "",
        product_information: "ok",
        product_features: productFeatures || "",
        returnable: isReturnable,
        cancelable: isCancelable,
        unit: unit || "",
        product_status: productStatus || "",
        cod: isCODAllowed,
        offer_status: 1,
        offer_type: 1,
        offer_value: 12,
        short_by: 1,
        product_specification: allValues,
      };
      const formData = new FormData();
      Object.keys(payload).forEach((key) => {
        formData.append(key, payload[key]);
      });
      if (selectedImages && selectedImages.length > 0) {
        selectedImages.forEach((imageFile, index) => {
          formData.append(`image[${index}]`, imageFile);
        });
      } else {
        formData.append("current_images", productData?.product_image || "");
      }
      console.log("FormData values:");
      for (let pair of formData.entries()) {
        console.log(`${pair[0]}: ${pair[1]}`);
      }
      const response = await fetch(url, {
        method: "POST",
        body: formData,
      });
      const responseText = await response.text();
      const data = JSON.parse(responseText);
      console.log("Product updated successfully:", data);
      if (response.ok) {
        Navigate("/manageproduct");
        swal({
          title: "Success!",
          text: `Product updated successfully`,
          icon: "success",
          button: {
            text: "OK",
            className: "swal-button--success",
          },
          timer: 3000,
        });
        fetchCatData();
      } else {
        const errorResponse = await response.json();
        console.error("Failed to update product:", errorResponse.message);
      }
    } catch (error) {
      console.error("Error updating product:", error.message);
    }
  };
  const handleCategoryChange = (e) => {
    try {
      if (!e || !e.target) {
        throw new Error("Event object or its target is undefined");
      }
      const categoryId = e.target.value;
      setSelectedCategory(categoryId);
      const selectedCategory = categories.find(
        (category) => category.id === categoryId
      );
      if (selectedCategory) {
        fetch(
          `https://mahalakshmimobiles.com/admin/categories.php?parent_category=${categoryId}`
        )
          .then((response) => response.json())
          .then((data) => {
            setSubCategories(data);
          })
          .catch((error) => {
            console.error("Error fetching sub-categories:", error);
          });
      } else {
        console.error("Selected category not found.");
      }
    } catch (error) {
      console.error("Error in handleCategoryChange:", error);
    }
  };
  const handleCountryChange = (event) => {
    setSelectedCountry(event.target.value);
  };
  const Madeinget = async () => {
    const data = await fetch(
      "https://restcountries.com/v3.1/independent?status=true"
    );
    const response = await data.json();
    setmadeinload(response);
  };
  const handleDescriptionChange = (content) => {
    setDescription(content);
  };
  const handleProductFeaturesChange = (content, delta, source, editor) => {
    setProductFeatures(content);
  };
  useEffect(() => {
    if (Array.isArray(productData?.specification)) {
      const initialSelectedOptions = productData.specification.reduce((acc, category) => {
        acc[category.spec_id] = category.menu.map((menu) => ({
          value: menu.id,
          label: menu.menu_name,
        }));
        return acc;
      }, {});
      setSelectedOptions(initialSelectedOptions);
    }
  }, [productData]);

  const handleSelectChange = (specId, options) => {
    setSelectedOptions((prevOptions) => ({
      ...prevOptions,
      [specId]: options,
    }));
    console.log(`Selected options for spec_id ${specId}:`, options);
  };
  return (
    <div className="main-container mt-3">
      {loading ? (
        <div>
          <Lottie
            height={250}
            width={250}
            options={{
              loop: true,
              animationData: require("../../Assets/Animation - 1716197548567.json"),
              autoplay: true,
            }}
          />
        </div>
      ) : (
        <div className=" ">
          <div className="flex-container pt-5">
            <h3 className="head-fon-mv">Edit Product</h3>
            <div class="col-12 col-md-6 order-md-2 order-first">
              <nav
                aria-label="breadcrumb"
                class="breadcrumb-header float-start float-lg-end"
              >
                <ol class="breadcrumb">
                  <li class="breadcrumb-item">
                    <a onClick={() => Navigate("/dashboard")}>Dashboard</a>
                  </li>{" "}
                  <li class="breadcrumb-item">
                    <a onClick={() => Navigate("/manageproduct")}>
                      Manage Products
                    </a>
                  </li>{" "}
                  <li aria-current="page" class="breadcrumb-item active">
                    Edit Product
                  </li>
                </ol>
              </nav>
            </div>
          </div>
          <div className="card ">
            <div className="cat-left">
              {/* {type !== "" ? <h4>Edit Product</h4> : <h4>Create Product</h4>} */}
            </div>
            <div className="form-container">
              <div className="form-column">
                <label className="bold">
                  Product Name <i className="text-danger">*</i>
                </label>
                <input
                  className="form-control"
                  placeholder="Enter Product Name"
                  value={productName}
                  onChange={(e) => {
                    const { value } = e.target;
                    setProductName(value);
                  }}
                />

                <label className="bold">Tax</label>
                <select
                  className="form-select"
                  value={taxs}
                  onChange={(e) => {
                    const { value } = e.target;
                    settaxs(value);
                  }}

                >
                  <option value="">Select Tax</option>
                  {taxOptions.map((option) => (
                    <option key={option.id} value={option.percentage}>
                      {option.percentage}
                    </option>
                  ))}
                </select>
              </div>
              <div className="form-column">
                <label className="bold">
                  Product Type <i className="text-danger">*</i>
                </label>
                <input
                  className="form-control"
                  placeholder="Enter Product Type"
                  value={productType || ""}
                  onChange={(e) => {
                    const { value } = e.target;
                    setProductType(value);
                  }}
                />
                {/* <select className="form-select" /> */}
                <label className="bold">
                  Made In <i className="text-danger">*</i>
                </label>
                <select
                  className="form-select"
                  value={selectedCountry || ""}
                  onChange={handleCountryChange}
                >
                  <option value="">Select Country</option>
                  {madeinload &&
                    madeinload.map(
                      (madein) =>
                        madein &&
                        madein.name &&
                        madein.name.common && (
                          <option key={madein.id} value={madein.name.common}>
                            {madein.name.common}
                          </option>
                        )
                    )}
                </select>
              </div>
            </div>
            <div className="form-container">
              <div className="form-column">
                <label className="bold">
                  Tags<i className="text-danger">*</i>
                </label>
                <input
                  className="form-control"
                  placeholder="Enter Tags"
                  value={tags || ""}
                  onChange={(e) => {
                    const { value } = e.target;
                    setTags(value);
                  }}
                />
                <label className="bold">
                  MRP Price<i className="text-danger">*</i>
                </label>
                <input
                  className="form-control"
                  placeholder="Enter MRP Price"
                  value={mrpprice || ""}
                  onChange={(e) => {
                    const { value } = e.target;

                    setMrpPrice(value);
                  }}
                />
                <label className="bold">
                  Brand<i className="text-danger">*</i>
                </label>
                <select
                  className="form-select"
                  value={brand || ""}
                  onChange={(e) => {
                    const { value } = e.target;
                    const newValue = value === "" ? 0 : value;
                    setBrand(newValue);
                  }}
                >
                  <option value="">Select Brand Name</option>
                  {brandload &&
                    brandload.map((brand) => (
                      <option key={brand.id} value={brand.id}>
                        {brand.brand_name}
                      </option>
                    ))}
                </select>
              </div>
              <div className="form-column">
                <label className="bold">
                  Discount<i className="text-danger">*</i>
                </label>
                <select
                  className="form-select"
                  placeholder="Enter Discount"
                  value={discount || ""}
                  onChange={(e) => {
                    const { value } = e.target;
                    setDiscount(value);
                  }}
                >
                  <option value="" disabled>
                    Select Discount Type
                  </option>
                  <option value="Amount">Amount</option>
                  <option value="Percentage">Percentage</option>
                </select>
                <label className="bold">
                  Price <i className="text-danger">*</i>
                </label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Enter Price "
                  value={price || ""}
                  onChange={(e) => {
                    const value = e.target.value;
                    if (/^[\d,]*$/.test(value)) {
                      setPrice(value);
                    }

                  }}
                />

                <label className="bold">
                  Category <i className="text-danger">*</i>
                </label>
                <select
                  className="form-select"
                  value={productData?.category || ""}
                  onChange={(e) => {
                    const selectedCategoryId = e.target.value;
                    handleCategoryChange(e);
                    setProductData((prev) => ({
                      ...prev,
                      category: selectedCategoryId,
                      category_name: selectedCategory?.category_name || "",
                    }));
                  }}
                >
                  <option value="">Select Category Name</option>
                  {categories.map((category) => (
                    <option key={category.id} value={category.id}>
                      {category.category_name}
                    </option>
                  ))}
                </select>

              </div>
            </div>
            <div className="form-new" style={{ display: "flex", gap: "10px" }}>
              <div style={{ flex: 1 }}>
                <label className="bold">
                  Sub Category <i className="text-danger">*</i>
                </label>
                <select
                  className="form-select"
                  value={selectedSubCategoryId || ""}
                  onChange={(e) => {
                    const selectedValue = e.target.value;
                    handleSubCategoryChange(selectedValue);
                    setSelectedSubCategoryId(selectedValue);
                  }}
                >
                  <option value="">Select Sub Category</option>
                  {subCategories?.map((subCategory) => (
                    <option key={subCategory.id} value={subCategory.id}>
                      {subCategory.category_name}
                    </option>
                  ))}
                </select>
              </div>

              <div style={{ flex: 1 }}>
                <label className="bold">
                  Unit <i className="text-danger">*</i>
                </label>
                <select
                  className="form-select"
                  value={unit || ""}
                  onChange={(e) => {
                    const selectedValue = e.target.value;
                    setUnit(selectedValue); // Update the state with the selected value
                  }}
                >
                  <option value="">Select Unit</option>
                  {unitload &&
                    unitload.map((unitItem) => (
                      <option key={unitItem.id} value={unitItem.unit_name}>
                        {unitItem.unit_name}
                      </option>
                    ))}
                </select>
              </div>

              <div style={{ flex: 1 }} className="m-2">
                <label className="bold">
                  Opening Stock <i className="text-danger">*</i>
                </label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Enter Product Stock"
                  value={productStock || ""}
                  readOnly
                />
              </div>
              <div style={{ flex: 1 }} className="m-2">

                <label className="bold">
                  Warranty <i className="text-danger">*</i>
                </label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Enter Warranty"
                  value={warranty || ""}
                  onChange={(e) => {
                    const inputVal = e.target.value;
                    setWarranty(inputVal)
                  }}
                />
              </div>
            </div>
            <div className="form-container">
              <div className="form-column">
                <div className="input-group d-flex justify-content-between">
                  <label className="bold">
                    Is Returnable?
                    <div
                      role="radiogroup"
                      className="btn-group-toggle btn-group bv-no-focus-ring align-items-center"
                    >
                      <label
                        className={`btn btn-outline-primary ${isReturnable === "Yes" ? "active"
                          : ""
                          }`}
                      >
                        <input
                          type="radio"
                          value="Yes"
                          checked={isReturnable === "Yes"}
                          onChange={(e) => {
                            setIsReturnable(e.target.value)
                          }}
                        />
                        <span>Yes</span>
                      </label>
                      <label
                        className={`btn btn-outline-primary ${isReturnable === "No" ? "active"
                          : ""
                          }`}
                      >
                        <input
                          type="radio"
                          value="No"
                          checked={isReturnable === "No"}
                          onChange={(e) => {
                            setIsReturnable(e.target.value)
                          }}
                        />
                        <span>No</span>
                      </label>
                    </div>
                  </label>

                  <label className="bold">
                    Is Cancelable?
                    <div
                      role="radiogroup"
                      className="btn-group-toggle btn-group bv-no-focus-ring"
                    >
                      <label
                        className={`btn btn-outline-primary ${isCancelable === "Yes" ?
                          "active"
                          : ""
                          }`}
                      >
                        <input
                          type="radio"
                          value="Yes"
                          checked={isCancelable === "Yes"}
                          onChange={(e) => {
                            setIsCancelable(e.target.value)


                          }}
                        />
                        <span>Yes</span>
                      </label>
                      <label
                        className={`btn btn-outline-primary ${isCancelable === "No" ?
                          "active"
                          : ""
                          }`}
                      >
                        <input
                          type="radio"
                          value="No"
                          checked={isCancelable === "No"}
                          onChange={(e) => {
                            setIsCancelable(e.target.value)
                          }}
                        />
                        <span>No</span>
                      </label>
                    </div>
                  </label>
                  <label className="bold">
                    Is COD Allowed?
                    <div role="radiogroup" className="btn-group-toggle btn-group bv-no-focus-ring">
                      <label
                        className={`btn btn-outline-primary ${isCODAllowed === "Yes" ? "active" : ""}`}
                      >
                        <input
                          type="radio"
                          value="Yes"
                          checked={isCODAllowed === "Yes"}
                          onChange={(e) => setIsCODAllowed(e.target.value)}
                        />
                        <span>Yes</span>
                      </label>
                      <label
                        className={`btn btn-outline-primary ${isCODAllowed === "No" ? "active" : ""}`}
                      >
                        <input
                          type="radio"
                          value="No"
                          checked={isCODAllowed === "No"}
                          onChange={(e) => setIsCODAllowed(e.target.value)}
                        />
                        <span>No</span>
                      </label>
                    </div>
                  </label>


                  <label className="bold">
                    Product Status
                    <div
                      role="radiogroup"
                      className="btn-group-toggle btn-group bv-no-focus-ring"
                    >
                      {/* Approved Radio Button */}
                      <label
                        className={`btn btn-outline-primary ${productStatus === "Approved"
                          ? "active"
                          : ""
                          }`}
                      >
                        <input
                          type="radio"
                          value="Approved"
                          checked={
                            productStatus === "Approved"
                          }
                          onChange={() => handleRadioChange("Approved")}
                        />
                        <span>Approved</span>
                      </label>

                      {/* Not Approved Radio Button */}
                      <label
                        className={`btn btn-outline-primary ${productStatus === "Not-Approved"
                          ? "active"
                          : ""
                          }`}
                      >
                        <input
                          type="radio"
                          value="Not-Approved"
                          checked={
                            productStatus === "Not-Approved"
                          }
                          onChange={() => handleRadioChange("Not-Approved")}
                        />
                        <span>Not Approved</span>
                      </label>
                    </div>
                  </label>
                </div>
                <div className="card">
                  <div className="card-body">
                    <div className="row">
                      {Array.isArray(productData?.specification) && productData.specification.length > 0 ? (
                        productData.specification.map((category) => (
                          <div key={category.spec_id} className="col-md-4 d-flex flex-column">
                            <label className="bold">{category.master_name}</label>
                            <Select
                              id={`categorySelect-${category.spec_id}`}
                              className="form-control"
                              isMulti
                              value={selectedOptions[category.spec_id]} // Handle undefined state
                              options={category.menu.map((menu) => ({
                                value: menu.id,
                                label: menu.menu_name,
                              }))}
                              onChange={(options) => handleSelectChange(category.spec_id, options)}
                            />
                          </div>
                        ))
                      ) : (
                        <p>No categories available</p>
                      )}


                    </div>
                  </div>
                </div>

              </div>
            </div>
            <div>
              <label className="bold">
                Description <i className="text-danger">*</i>
              </label>
              <ReactQuill
                theme="snow"
                value={description || ""}
                onChange={handleDescriptionChange}
              />
            </div>
            <div>
              <label className="bold">
                Product Features<i className="text-danger">*</i>
              </label>
              <ReactQuill
                theme="snow"
                value={
                  productFeatures || ""
                }
                onChange={handleProductFeaturesChange}
              />
            </div>
            <div>
              <label className="bold">
                Main Image <i className="text-danger">*</i>
              </label>
              <p>Please choose a square image less 140KB.</p>
              <label className="mb-4">
                {productData?.product_image &&
                  (() => {
                    try {
                      const images = JSON.parse(
                        productData?.product_image
                      );
                      if (images.length > 0) {
                        return images.map((image, imgIndex) => (
                          <img
                            key={imgIndex}
                            src={`https://mahalakshmimobiles.com/admin/image/product/${image}`}
                            alt={`Variant ${imgIndex + 1}`}
                            className="variant-image"
                          />
                        ));
                      }
                    } catch (error) {
                      // console.error("Invalid JSON for variant_image:", error);
                      return <p>The Selected images goes below.</p>;
                    }
                  })()}
                <div className="selected-images">
                  {selectedImages &&
                    selectedImages.map((file, index) => {
                      const imageUrl = URL.createObjectURL(file);
                      return (
                        <img
                          key={index}
                          src={imageUrl}
                          alt={`Selected ${index + 1}`}
                          className="selected-image"
                        />
                      );
                    })}
                </div>
                <input
                  className="form-control mb-2"
                  type="file"
                  name="file2"
                  id="myFile2"
                  multiple
                  accept=".jpg, .jpeg, .png"
                  onChange={handleFileChange}
                />

                {errorMessage && <p style={{ color: "red" }}>{errorMessage}</p>}
              </label>
            </div>

            <div className="save-bt ">
              <button
                className="btn btn-danger "
                style={{ marginRight: "10px", width: "90px" }}
                onClick={() => navigate("/manageproduct")}
              >
                Cancel
              </button>
              <button
                onClick={handleupdate}
                disabled={isLoading}
                className="btn btn-success "
                style={{ width: "90px", float: "left" }}
              >
                {isLoading
                  ? id
                    ? "Updating..."
                    : ""
                  : id ?
                    "Update" : ""}
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default EditProduct;
