import swal from "sweetalert";
import React, { useState, useEffect } from 'react';
import { Modal, Button } from 'react-bootstrap';
import axios from 'axios';
import Lottie from "react-lottie";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPenToSquare } from "@fortawesome/free-regular-svg-icons";
import { faSortUp, faSortDown } from "@fortawesome/free-solid-svg-icons";
import { FaSearch, FaTrash, FaPen } from "react-icons/fa";
import { faEye } from "@fortawesome/free-solid-svg-icons";
function Variant_Master() {
    const [showModal, setShowModal] = useState(false);
    const [showModalEdit, setShowModalEdit] = useState(false);
    const [variantName, setVariantName] = useState('');
    const [variants, setVariants] = useState([]);
    console.log("variants", variants);
    const [filteredVariants, setFilteredVariants] = useState([]);
    const [searchQuery, setSearchQuery] = useState('');
    const [isSubmitting, setIsSubmitting] = useState(false);
    const fixedCategoryId = '5'; // Adjust if needed
    const [selectedCategories, setSelectedCategories] = useState([]);
    const [selectedCategoriesedit, setSelectedCategoriesedit] = useState([]);
    console.log("selectedCategories", selectedCategories);
    const [categories1, setCategories1] = useState([]);
    console.log("categories1", categories1);
    const [variantsEdit, setVariantsEdit] = useState(null);
    console.log("variantsEdit", variantsEdit);
    const [categoriesName, setCategoriesName] = useState([]);
    console.log("categoriesName", categoriesName);
    const handleOpenModal = () => setShowModal(true);
    const [loading, setLoading] = useState(true);
    const handleCloseModal = () => {
        setShowModal(false);
        resetForm();
        setSelectedCategories([]);
        // setCategories1([]);

    };

    const fetchVariants = () => {
        axios.get('https://mahalakshmimobiles.com/admin/admin_get_variant_category.php')
            .then(response => {
                // const filteredData = response.data.filter(item => item.category_id === categoryId);
                setVariants(response.data);
                setFilteredVariants(response.data);
                setLoading(false);
            })
            .catch(error => console.error('Error fetching data:', error));
    };

    useEffect(() => {
        fetchVariants();
    }, []);

    const resetForm = () => {
        setVariantName('');
    };

    const handleSearchChange = (e) => {
        const query = e.target.value.toLowerCase();
        setSearchQuery(query);
        const filtered = variants.filter(variant => variant.master_name.toLowerCase().includes(query));
        setFilteredVariants(filtered);
    };
    const handleInsert = () => {
        if (!variantName) {
            swal("Error", "Variant Name cannot be empty.", "error");
            return;
        }

        if (selectedCategories.length === 0) {
            swal("Error", "Please select at least one category.", "error");
            return;
        }

        setIsSubmitting(true);
        const categoriesParam = selectedCategories.join(","); // Join category IDs with commas
        const apiUrl = `https://mahalakshmimobiles.com/admin/admin_add_variant.php?cat_id=${categoriesParam}&variant_name=${encodeURIComponent(variantName)}`;
        console.log('first', apiUrl)
        axios
            .get(apiUrl)
            .then((response) => {
                if (response.data.success) {
                    fetchVariants(fixedCategoryId); // Fetch updated variants
                    handleCloseModal(); // Close the modal
                    swal("Added!", "Variant has been added successfully.", "success");
                } else {
                    swal("Error", response.data.message || "Failed to add variant.", "error");
                }
            })
            .catch((error) => {
                console.error("Error adding variant:", error);
                swal("Error", "Something went wrong. Please try again.", "error");
            })
            .finally(() => {
                setIsSubmitting(false);
            });
    };


    const handleCategoryChange = (categoryId) => {
        setSelectedCategories((prevSelected) =>
            prevSelected.includes(categoryId)
                ? prevSelected.filter((id) => id !== categoryId)
                : [...prevSelected, categoryId]
        );
    };

    const handleCategoryChangeedit = (categoryId) => {
        setSelectedCategoriesedit((prevSelected) =>
            prevSelected.includes(categoryId)
                ? prevSelected.filter((id) => id !== categoryId)
                : [...prevSelected, categoryId]
        );
    };

    useEffect(() => {
        if (variantsEdit) {
            const categoryArray = variantsEdit.flatMap((item) => item.category_id.split(','));
            setSelectedCategoriesedit(categoryArray);
            const categoryNames = variantsEdit.map((item) => item.variant_name);
            setVariantName(categoryNames[0]);
        }
    }, [variantsEdit]);


    const handleRemoveCategory = (categoryId) => {
        setSelectedCategories((prev) => prev.filter((id) => id !== categoryId));
    };

    const handleRemoveCategoryedit = (categoryId) => {
        setSelectedCategoriesedit((prev) => prev.filter((id) => id !== categoryId));
    };
    useEffect(() => {
        axios.get("https://mahalakshmimobiles.com/admin/get_category.php")
            .then(response => {
                const data = response.data;


                if (data.success) {
                    setCategories1(Object.values(data).filter(item => typeof item === "object"));
                }
            })
            .catch(error => console.error("Error fetching categories:", error));
    }, []);
    const handleDelete = (id) => {
        fetch('https://mahalakshmimobiles.com/admin/admin_get_variant_category.php', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            },
            body: new URLSearchParams({
                action: 'delete',
                id: id
            })
        })
            .then(response => response.json())
            .then((data) => {
                // console.log("Response:", data);
                if (data.success) {
                    fetchVariants();
                    swal("Deleted!", "Variant deleted successfully.", "success");
                } else {
                    alert(data.error || "Failed to delete variant.");
                }
            })
            .catch((error) => {
                swal("Deleted!", "Failed to delete variant. Please try again", "Failed");
            });
    };

    const handleEdit = (id) => {
        axios.get(`https://mahalakshmimobiles.com/admin/admin_get_variant_category.php?id=${id}`)
            .then(response => {
                const filteredData = response.data.filter(item => item.id === id);
                setVariantsEdit(filteredData);
                setShowModalEdit(true)
                const updatedVariants = filteredData.map((item) => {
                    const categoryIds = item.category_id.split(',').map(String);
                    console.log("categoryIds", categoryIds);

                    const matchingCategories = categories1.filter((value) =>
                        categoryIds.includes(value.id)
                    );

                    console.log("matchingCategories", matchingCategories);

                    const category_names = matchingCategories.length > 0
                        ? matchingCategories.map(cat => cat.category_name).join(', ')
                        : 'Unknown Category';

                    return {
                        ...item,
                        category_name: category_names
                    };
                });

                setCategoriesName(updatedVariants);
            })
            .catch(error => console.error('Error fetching data:', error));
    };







    const handleupdate = () => {
        // Send the updated data to the backend
        fetch('https://mahalakshmimobiles.com/admin/admin_get_variant_category.php', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            },
            body: new URLSearchParams({
                action: 'update',
                id: variantsEdit[0].id, 
                variant_name: variantName, // The updated variant name
                category_id: selectedCategoriesedit.join(",") // Sending the selected category IDs as a comma-separated string
            })
        })
            .then(response => response.json())
            .then((data) => {
                if (data.success) {
                    fetchVariants();
                    setShowModalEdit(false) // Fetch updated variant list
                    swal("Updated!", "Variant updated successfully.", "success"); // Show success alert
                } else {
                    alert(data.error || "Failed to update variant.");
                }
            })
            .catch((error) => {
                swal("Error!", "Failed to update variant. Please try again.", "error");
            });
    }
    
    const handleCloseModalEdit = () => {
        setShowModalEdit(false);
        resetForm();

    };
    return (
        <div className="main-container mt-3">
            <h3 className="head-fon-mv">Variant Masters</h3>

            <Button variant="primary" onClick={handleOpenModal} className="add-category-button" style={{ width: 150 }}>
                Add Variant
            </Button>
            <input
                type="text"
                className="form-control w-25 mt-3"
                placeholder="Search Variants"
                value={searchQuery}
                onChange={handleSearchChange}
            />
            <Modal show={showModal} onHide={handleCloseModal}>
                <Modal.Header closeButton>
                    <Modal.Title>Add Variant</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div>
                        <label className="bold">Select Categories</label>
                        <div className="dropdown" style={{ position: "relative" }}>
                            <button
                                className="btn btn-outline-secondary"
                                type="button"
                                data-bs-toggle="dropdown"
                                style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                    padding: "10px",
                                    fontSize: "14px",
                                    borderRadius: "8px",
                                }}
                            >
                                {selectedCategories.length > 0
                                    ? `${selectedCategories.length} category(s) selected`
                                    : "Select Categories"}
                                <span className="dropdown-icon">&#9660;</span>
                            </button>
                            <ul
                                className="dropdown-menu p-2 shadow"
                                style={{
                                    maxHeight: "200px",
                                    overflowY: "auto",
                                    width: "100%",
                                    borderRadius: "5px",
                                    padding: "10px",
                                    paddingLeft: "10px",
                                }}
                            >
                                {categories1.map((category) => (
                                    <li
                                        key={category.id}
                                        className="form-check d-flex align-items-center"
                                        style={{
                                            padding: "15px",
                                            paddingLeft: "50px",
                                            borderBottom: "1px solid #f1f1f1",
                                        }}
                                    >
                                        <input
                                            className="form-check-input me-2"
                                            type="checkbox"
                                            value={category.id}
                                            id={`category-${category.id}`}
                                            checked={selectedCategories.includes(category.id)}
                                            onChange={() => handleCategoryChange(category.id)}
                                            style={{
                                                width: "25px",
                                                height: "25px",
                                                marginRight: "10px",
                                            }}
                                        />
                                        <label
                                            className="form-check-label"
                                            htmlFor={`category-${category.id}`}
                                            style={{ marginBottom: 0, flex: 1 }}
                                        >
                                            {category.category_name}
                                        </label>
                                    </li>
                                ))}
                            </ul>
                        </div>
                        <label className="bold mt-3">Selected Categories</label>
                        <div className="input-group">
                            <div
                                style={{
                                    display: "flex",
                                    flexWrap: "wrap",
                                    gap: "8px",
                                    padding: "8px",
                                    borderRadius: "8px",
                                    backgroundColor: "#f7f7f7",
                                    alignItems: "center",
                                }}
                            >
                                {selectedCategories.map((id) => {
                                    const category = categories1.find((cat) => cat.id === id);
                                    return (
                                        <div
                                            key={id}
                                            style={{
                                                display: "flex",
                                                alignItems: "center",
                                                backgroundColor: "#e0e0e0",
                                                borderRadius: "6px",
                                                fontSize: "14px",
                                                lineHeight: "1",
                                                maxWidth: "150px",
                                                overflow: "hidden",
                                                textOverflow: "ellipsis",
                                                whiteSpace: "nowrap",
                                            }}
                                        >
                                            <span
                                                style={{
                                                    // overflow: "hidden",
                                                    textOverflow: "ellipsis",
                                                    // whiteSpace: "nowrap",
                                                    marginRight: "3px",
                                                }}
                                            >
                                                {category?.category_name}
                                            </span>
                                            <button
                                                type="button"
                                                onClick={() => handleRemoveCategory(id)}
                                                style={{
                                                    border: "none",
                                                    background: "transparent",
                                                    cursor: "pointer",
                                                    color: "red",
                                                    fontSize: "16px",
                                                    display: "flex",
                                                    alignItems: "center",
                                                    marginBottom: 10
                                                }}
                                            >
                                                &times;
                                            </button>
                                        </div>
                                    );
                                })}
                                <input
                                    type="text"
                                    placeholder="Add category..."
                                    style={{
                                        flex: "1",
                                        border: "none",
                                        outline: "none",
                                        background: "transparent",
                                        fontSize: "14px",
                                        color: "#333",
                                        padding: "6px",
                                        minWidth: "120px",
                                    }}
                                />
                            </div>
                        </div>


                        <label className="bold mt-3">Variant Master Name</label>
                        <input
                            className="form-control"
                            placeholder="Enter Variant Master Name"
                            value={variantName}
                            onChange={(e) => setVariantName(e.target.value)}
                        />
                    </div>
                </Modal.Body>

                <Modal.Footer>
                    <Button variant="success" onClick={handleInsert} disabled={isSubmitting}>
                        Save
                    </Button>
                    <Button variant="secondary" onClick={handleCloseModal}>
                        Cancel
                    </Button>
                </Modal.Footer>
            </Modal>
            <Modal show={showModalEdit} onHide={handleCloseModalEdit}>
                <Modal.Header closeButton>
                    <Modal.Title>Edit Variant</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div>
                        <label className="bold">Select Categories</label>
                        <div className="dropdown" style={{ position: "relative" }}>
                            <button
                                className="btn btn-outline-secondary"
                                type="button"
                                data-bs-toggle="dropdown"
                                // aria-expanded="false"
                                style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                    padding: "10px",
                                    fontSize: "14px", // Matching font size
                                    borderRadius: "8px", // To match the input field
                                }}
                            >
                                {selectedCategoriesedit.length > 0
                                    ? `${selectedCategoriesedit.length} category(s) selected`
                                    : "Select Categories"}
                                <span className="dropdown-icon">&#9660;</span>
                            </button>
                            <ul
                                className="dropdown-menu p-2 shadow"
                                style={{
                                    maxHeight: "200px",
                                    overflowY: "auto",
                                    width: "100%",
                                    borderRadius: "5px",
                                    padding: "10px",
                                    paddingLeft: "10px",
                                }}
                            >
                                {categories1.map((category) => (
                                    <li
                                        key={category.id}
                                        className="form-check d-flex align-items-center"
                                        style={{
                                            padding: "15px",
                                            paddingLeft: "50px",
                                            borderBottom: "1px solid #f1f1f1",
                                        }}
                                    >
                                        <input
                                            className="form-check-input me-2"
                                            type="checkbox"
                                            value={category.id}
                                            id={`category-${category.id}`}
                                            checked={selectedCategoriesedit.includes(category.id)}
                                            onChange={() => handleCategoryChangeedit(category.id)}
                                            style={{
                                                width: "25px",
                                                height: "25px",
                                                marginRight: "10px",
                                            }}
                                        />
                                        <label
                                            className="form-check-label"
                                            htmlFor={`category-${category.id}`}
                                            style={{ marginBottom: 0, flex: 1 }}
                                        >
                                            {category.category_name}
                                        </label>
                                    </li>
                                ))}
                            </ul>
                        </div>
                        <label className="bold mt-3">Selected Categories</label>
                        <div className="input-group">
                            <div
                                style={{
                                    display: "flex",
                                    flexWrap: "wrap",
                                    gap: "8px",
                                    padding: "8px",
                                    borderRadius: "8px",
                                    backgroundColor: "#f7f7f7",
                                    alignItems: "center",
                                }}
                            >
                                {selectedCategoriesedit.map((id) => {
                                    const category = categories1.find((cat) => cat.id === id);
                                    return (
                                        <div
                                            key={id}
                                            style={{
                                                display: "flex",
                                                alignItems: "center",
                                                backgroundColor: "#e0e0e0",
                                                borderRadius: "6px",
                                                fontSize: "14px",
                                                lineHeight: "1",
                                                maxWidth: "150px",
                                                overflow: "hidden",
                                                textOverflow: "ellipsis",
                                                whiteSpace: "nowrap",
                                            }}
                                        >
                                            <span
                                                style={{
                                                    // overflow: "hidden",
                                                    textOverflow: "ellipsis",
                                                    // whiteSpace: "nowrap",
                                                    marginRight: "3px",
                                                }}
                                            >
                                                {category?.category_name}
                                            </span>
                                            <button
                                                type="button"
                                                onClick={() => handleRemoveCategoryedit(id)}
                                                style={{
                                                    border: "none",
                                                    background: "transparent",
                                                    cursor: "pointer",
                                                    color: "red",
                                                    fontSize: "16px",
                                                    display: "flex",
                                                    alignItems: "center",
                                                    marginBottom: 10
                                                }}
                                            >
                                                &times;
                                            </button>
                                        </div>
                                    );
                                })}
                                <input
                                    type="text"
                                    placeholder="Add category..."
                                    style={{
                                        flex: "1",
                                        border: "none",
                                        outline: "none",
                                        background: "transparent",
                                        fontSize: "14px",
                                        color: "#333",
                                        padding: "6px",
                                        minWidth: "120px",
                                    }}
                                />
                            </div>
                        </div>


                        <label className="bold mt-3">Variant Master Name</label>
                        <input
                            className="form-control"
                            placeholder="Enter Variant Master Name"
                            value={variantName}
                            onChange={(e) => setVariantName(e.target.value)}
                        />
                    </div>
                </Modal.Body>

                <Modal.Footer>
                    <Button variant="success" onClick={handleupdate} disabled={isSubmitting}>
                        update
                    </Button>
                    <Button variant="secondary" onClick={handleCloseModalEdit}>
                        Cancel
                    </Button>
                </Modal.Footer>
            </Modal>
            {/* <div className="mt-4">
                <h5 className="mb-3">Added Variants</h5>
                {filteredVariants.length === 0 ? (
                    <p className="text-center text-muted">Currently no variant for this category.</p>
                ) : (
                    <div className="row">
                        {filteredVariants.map((variant, index) => (
                            <div key={index} className="col-12 col-sm-6 col-md-4 col-lg-3 mb-4">
                                <div className="p-3 border rounded shadow-sm bg-white text-center">
                                    <p className="text-muted">{variant.variant_name}</p>
                                    <div className="d-flex justify-content-center mt-2">
                                        <button className="btn btn-outline-primary btn-sm mx-1" onClick={() => handleEdit(variant.id)}>
                                            Edit
                                        </button>
                                        <button className="btn btn-outline-danger btn-sm mx-1" onClick={() => handleDelete(variant.id)}>
                                            Delete
                                        </button>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                )}
            </div> */}


            <div>


{loading ? (
    <div className="d-flex justify-content-center mt-4">
        <Lottie
            height={250}
            width={250}
            options={{
                loop: true,
                animationData: require("../Assets/Animation - 1716197548567.json"),
                autoplay: true,
            }}
        />
    </div>
) : (
    // <div className="mt-4">
    //     <h5 className="mb-3">Added Masters</h5>
    //     {filteredMasters.length === 0 ? (
    //         <p className="text-center text-muted"> Currently no master for this category.</p>
    //     ) : (
    //         <div className="row">
    //             {filteredMasters.map((master, index) => (
    //                 <div key={index} className="col-12 col-sm-6 col-md-4 col-lg-3 mb-4">
    //                     <div className="p-3 border rounded shadow-sm bg-white text-center">
    //                         <p className="text-muted">{master.master_name}</p>
    //                         <div className="d-flex justify-content-center mt-2">
    //                             <button className="btn btn-outline-success btn-sm mx-1" onClick={() => handleOpen(master)}>Open</button>
    //                             <button className="btn btn-outline-primary btn-sm mx-1" onClick={() => handleEdit(index)}>
    //                                 Edit
    //                             </button>
    //                             <button className="btn btn-outline-danger btn-sm mx-1" onClick={() => handleDelete(index)}>
    //                                 Delete
    //                             </button>
    //                         </div>
    //                     </div>
    //                 </div>
    //             ))}
    //         </div>
    //     )}
    // </div>
    <div className="mt-4">
         <h5 className="mb-3">Added Variants</h5>
      <table id="table-to-xls" className="text-center">
      <thead>
        <tr>
          <th style={{ width: "4%" }}>S.No</th>
          {/* <th style={{ textAlign: "center" }}>Colour</th> */}
          <th
            // onClick={() => handleSortByName()}
            style={{ cursor: "pointer", textAlign: "center" }}
          >
            Added variants
            {/* {sortOrder === "asc" ? (
              <FontAwesomeIcon
                icon={faSortUp}
                style={{ marginLeft: "8px" }}
              />
            ) : (
              <FontAwesomeIcon
                icon={faSortDown}
                style={{ marginLeft: "8px" }}
              />
            )} */}
          </th>
          <th style={{ textAlign: "center" }}>Action</th>
          {/* <th>Category Name</th> */}
          {/* {colourview.edit === true || colourview.delete === true ? (
            <th style={{ textAlign: "center" }}>Action</th>
          ) : (
            ""
          )} */}
        </tr>
      </thead>
      <tbody>
        {filteredVariants.length > 0 ? (
        filteredVariants.map((colour, index) => (
            <tr key={colour.id}>
              <td style={{ textAlign: "center" }}>
                { index + 1}
              </td>
              <td style={{ textAlign: "center" }}>{colour.variant_name}</td>
              {/* <td>{getCategoryLabels(colour.category_id)}</td> */}
              {(
                <td style={{ textAlign: "center" }}>
                    {/* { (
                    <button
                      className="btn-edit"
                      onClick={() => handleOpen(colour)}
                    >
                      <FontAwesomeIcon  icon={faEye} />
                    </button>
                  )} */}
                  { (
                    <button
                      className="btn-edit"
                      onClick={() => handleEdit(colour.id)}
                    >
                      <FontAwesomeIcon icon={faPenToSquare} />
                    </button>
                  )}
                  { (
                    <button
                      className="btn-delete"
                      onClick={() => handleDelete(colour.id)}
                    >
                      <FaTrash />
                    </button>
                  )}
                </td>
              )}
            </tr>
          ))
        ) : (
          <tr>
            <td
            //   colSpan={colourview.edit || colourview.delete ? 3 : 2}
              style={{ textAlign: "center" }}
            >
              No records found
            </td>
          </tr>
        )}
      </tbody>
    </table>
    </div>
)}

</div>
        </div>
    );
}

export default Variant_Master;
